var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":_vm.title,"returnPage":""}}),_c('page-card',{attrs:{"divider-on":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"xs":"6","sm":"4","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"warning","disabled":_vm.loading},on:{"click":_vm.cancel}},[_vm._v(" CANCELAR ")])],1),_c('v-col',{attrs:{"xs":"6","sm":"4","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"success","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" SALVAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"form",staticClass:"mt-6",attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.operators,"label":"Operador (Opcional)","placeholder":"Digite para buscar","filled":"","dense":""},model:{value:(_vm.policy.userId),callback:function ($$v) {_vm.$set(_vm.policy, "userId", $$v)},expression:"policy.userId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"label":"Dias em Atraso para Negociação","type":"number","filled":"","dense":""},model:{value:(_vm.policy.delay),callback:function ($$v) {_vm.$set(_vm.policy, "delay", $$v)},expression:"policy.delay"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"suffix":_vm.suffix['maximumDiscount'] ? '%' : '',"label":"Desconto Máximo","type":"number","filled":"","dense":""},on:{"focus":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'maximumDiscount',
                  _vm.policy.maximumDiscount
                )},"blur":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'maximumDiscount',
                  _vm.policy.maximumDiscount
                )}},model:{value:(_vm.policy.maximumDiscount),callback:function ($$v) {_vm.$set(_vm.policy, "maximumDiscount", $$v)},expression:"policy.maximumDiscount"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"suffix":_vm.suffix['standardInterest'] ? '%' : '',"label":"Juros Padrão","type":"number","filled":"","dense":""},on:{"focus":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'standardInterest',
                  _vm.policy.standardInterest
                )},"blur":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'standardInterest',
                  _vm.policy.standardInterest
                )}},model:{value:(_vm.policy.standardInterest),callback:function ($$v) {_vm.$set(_vm.policy, "standardInterest", $$v)},expression:"policy.standardInterest"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"suffix":_vm.suffix['minimumInterest'] ? '%' : '',"label":"Juros Mínimos","type":"number","filled":"","dense":""},on:{"focus":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'minimumInterest',
                  _vm.policy.minimumInterest
                )},"blur":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'minimumInterest',
                  _vm.policy.minimumInterest
                )}},model:{value:(_vm.policy.minimumInterest),callback:function ($$v) {_vm.$set(_vm.policy, "minimumInterest", $$v)},expression:"policy.minimumInterest"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"suffix":_vm.suffix['standardDeadline'] ? 'Dias' : '',"label":"Prazo de Negociação Padrão","type":"number","filled":"","dense":""},on:{"focus":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'standardDeadline',
                  _vm.policy.standardDeadline
                )},"blur":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'standardDeadline',
                  _vm.policy.standardDeadline
                )}},model:{value:(_vm.policy.standardDeadline),callback:function ($$v) {_vm.$set(_vm.policy, "standardDeadline", $$v)},expression:"policy.standardDeadline"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"suffix":_vm.suffix['minimumDeadline'] ? 'Dias' : '',"label":"Prazo de Negociação Mínimo","type":"number","filled":"","dense":""},on:{"focus":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'minimumDeadline',
                  _vm.policy.minimumDeadline
                )},"blur":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'minimumDeadline',
                  _vm.policy.minimumDeadline
                )}},model:{value:(_vm.policy.minimumDeadline),callback:function ($$v) {_vm.$set(_vm.policy, "minimumDeadline", $$v)},expression:"policy.minimumDeadline"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required, _vm.rule.requiredNumber],"suffix":_vm.suffix['maximumDeadline'] ? 'Dias' : '',"label":"Prazo de Negociação Máximo","type":"number","filled":"","dense":""},on:{"focus":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'maximumDeadline',
                  _vm.policy.maximumDeadline
                )},"blur":function($event){return _vm.toggleSuffixOf(
                  $event,
                  'maximumDeadline',
                  _vm.policy.maximumDeadline
                )}},model:{value:(_vm.policy.maximumDeadline),callback:function ($$v) {_vm.$set(_vm.policy, "maximumDeadline", $$v)},expression:"policy.maximumDeadline"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.periodicities,"label":"Periocidade das Parcelas","deletable-chips":"","small-chips":"","filled":"","dense":""},model:{value:(_vm.policy.periodicityInstallments),callback:function ($$v) {_vm.$set(_vm.policy, "periodicityInstallments", $$v)},expression:"policy.periodicityInstallments"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rule.required],"label":"Quantidade Máxima de Parcelas","type":"number","filled":"","dense":""},model:{value:(_vm.policy.amountInstallments),callback:function ($$v) {_vm.$set(_vm.policy, "amountInstallments", $$v)},expression:"policy.amountInstallments"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between px-3"},[_c('label',{staticClass:"subtitle-1 text--primary cursor-pointer",attrs:{"for":"diff"}},[_vm._v(" Parcelas com Valores Diferentes ")]),_c('v-switch',{attrs:{"id":"diff"},model:{value:(_vm.policy.differentInstallments),callback:function ($$v) {_vm.$set(_vm.policy, "differentInstallments", $$v)},expression:"policy.differentInstallments"}})],1)])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }