import AccountsReceivableListFeeTypeEnum from '@/views/preNegotiation/enums/AccountsReceivableListFeeTypeEnum';

export default class PreNegotiation {
  constructor(
    public expirationDate: string,
    public isSelectionAll: boolean,
    public quantity: number,
    public periodicity: string,
    public customPeriodicity: number|null,
    public totalFeeType: AccountsReceivableListFeeTypeEnum|undefined,
    public totalFeeValue: number|undefined,
  ) {}

  static make(data : any): PreNegotiation {
    return new PreNegotiation(
      data.expiration_date,
      data.is_selection_all,
      data.quantity,
      data.periodicity,
      data.custom_periodicity,
      data.total_fee_type ?? undefined,
      data.total_fee_value ?? undefined,
    );
  }
}
