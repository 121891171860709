









































































































































import {
 Vue, Component, Prop, Emit, Watch, Ref,
} from 'vue-property-decorator';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import QrCodeReader from '@/components/defaults/QRCodeReader.vue';
import { VForm } from '@/types/VForm';
import KeyType from '@/domain/enums/PixKeyType';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';

interface Data {
  type: number;
  key: string;
  keyType: KeyType | string;
  qrcode: string;
  bank: string;
  agency: string;
  agencyDigit: string;
  accountNumber: string;
  accountDigit: string;
  accountType: string;
}

@Component({
  components: {
    QrCodeReader,
  },
})
export default class PaymentRemittancesActionEditPix extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Object as () => PaymentRemittance,
  })
  readonly item!: PaymentRemittance;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  })
  groupConfig!: CompanyGroupConfig;

  @Watch('item', { immediate: true })
  changedOpen(item: any /* PaymentRemittance */) {
    if (item) {
      this.data = {
        type: item.pixType ?? 0,
        key: item.pixKey ?? '',
        keyType: '',
        qrcode: item.pix_qrcode ?? '',
        bank: item.supplier_bank ?? '',
        agency: item.supplier_agency ?? '',
        agencyDigit: item.supplier_agency_digit ?? '',
        accountNumber: item.supplier_account_number ?? '',
        accountDigit: item.supplier_account_digit ?? '',
        accountType: item.supplier_account_type ?? '',
      };

      this.getKeyType(item.pixKey ?? '');
    }
  }

  @Emit()
  close(reload: boolean = false) {
    return reload;
  }

  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();

  readonly required = (value: string | number) => !!value || 'Campo obrigatório!';

  pixOptions: Array<ISelectOptions<number>> = [
    {
      text: 'Chave Pix',
      value: 1,
    },
    {
      text: 'QR Code',
      value: 2,
    },
    {
      text: 'Dados Bancários',
      value: 3,
    },
  ];

  accountTypes: Array<ISelectOptions<string>> = [
    {
      text: 'Conta Corrente',
      value: '1',
    },
    {
      text: 'Conta Poupança',
      value: '2',
    },
  ];

  data: Data = {
    type: 0,
    key: '',
    keyType: '',
    qrcode: '',
    bank: '',
    agency: '',
    agencyDigit: '',
    accountNumber: '',
    accountDigit: '',
    accountType: '',
  };

  getKeyType(pix: string): KeyType | string {
    let type: string = '';

    const key = pix
      .replaceAll(' ', '')
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('/', '')
      .replaceAll('(', '')
      .replaceAll(')', '');

    const isEmail = key.includes('@');
    const isPhone = key.charAt(0) === '+';
    const number = new RegExp('^[0-9]+$', 'g');

    if (this.data.type === 1 || this.data.type === 2) {
      if (isPhone) {
        type = 'PHONE';
        this.data.keyType = KeyType.PHONE;
      }

      if (isEmail) {
        type = 'EMAIL';
        this.data.keyType = KeyType.EMAIL;
      }

      if (!isPhone && number.test(key)) {
        if (key.length >= 6 && key.length <= 11) {
          type = 'CPF';
          this.data.keyType = KeyType.CPF;
        } else if (key.length >= 12 && key.length <= 14) {
          type = 'CNPJ';
          this.data.keyType = KeyType.CNPJ;
        }
      }

      if (key.length >= 15 && !isPhone && !isEmail) {
        type = 'RANDOM';
        this.data.keyType = KeyType.RANDOM;
      }
    } else {
      this.data.keyType = KeyType.BANK_DATA;
    }

    return type;
  }

  get mask() {
    let mask: string | null = '';

    const type = this.getKeyType(this.data.key);

    switch (type) {
      case 'PHONE':
        mask = '+## (##) # ####-####';
        break;

      case 'CPF':
        mask = '###.###.###-###';
        break;

      case 'CNPJ':
        mask = '##.###.###/####-##';
        break;

      case 'RANDOM': {
        if (this.data.key.includes('.') || this.data.key.includes('/')) {
          this.data.key = this.data.key
            .replaceAll(' ', '')
            .replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('/', '')
            .replaceAll('(', '')
            .replaceAll(')', '');
        }

        mask = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX';
        break;
      }

      default:
        break;
    }

    return mask;
  }

  get labelKeyType() {
    switch (this.data.keyType) {
      case KeyType.PHONE:
        return 'Telefone';
      case KeyType.CPF || KeyType.CNPJ:
        return this.keyTypeIsCPFOrCNPJ(this.data.key);
      case KeyType.EMAIL:
        return 'E-mail';
      case KeyType.RANDOM:
        return 'Aleatória';
      default:
        return '';
    }
  }

  validadePix() {
    const key = this.data.key
      .replaceAll(' ', '')
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('/', '')
      .replaceAll('(', '')
      .replaceAll(')', '');

    const number = new RegExp('^[0-9]+$', 'g');

    switch (this.data.keyType) {
      case KeyType.PHONE:
        if (
          key.length !== 14
          || key.charAt(0) !== '+'
          || !number.test(key.substring(1))
        ) {
          this.$notification.warn('Telefone inválido!');
          return false;
        }
        break;
      case KeyType.CPF || KeyType.CNPJ: {
        const type = this.keyTypeIsCPFOrCNPJ(key);

        if (type === 'CPF' && key.length !== 11) {
          this.$notification.warn('CPF inválido!');
          return false;
        }

        if (type === 'CNPJ' && key.length !== 14) {
          this.$notification.warn('CNPJ inválido!');
          return false;
        }

        break;
      }
      case KeyType.EMAIL:
        if (!this.data.key.includes('@') || !this.data.key.includes('.')) {
          this.$notification.warn('E-mail inválido!');
          return false;
        }
        break;
      default:
        break;
    }

    return true;
  }

  save(): void {
    const formValidate = this.form.validate();
    const validadePix = this.data.type === 1 ? this.validadePix() : true;

    if (formValidate && validadePix) {
      if (this.item.origin !== 'inncash') {
        this.handleUpdatePix(this.data, this.item);
      } else {
        this.handleUpdatePixInInvoice(this.data, this.item);
      }
    }
  }

  reset(): void {
    this.data = {
      type: 0,
      key: '',
      keyType: '',
      qrcode: '',
      bank: '',
      agency: '',
      agencyDigit: '',
      accountNumber: '',
      accountDigit: '',
      accountType: '',
    };

    this.form.resetValidation();
  }

  async handleUpdatePix(data: Data, item: PaymentRemittance): Promise<void> {
    try {
      this.$dialog.stopLoading();

      const { id: groupId, use_ncc: NCC } = this.groupConfig;
      const companyId = item.company_id;
      const companyIdFromSupplier = item.supplier_company_id;

      const useNCC = Boolean(NCC) && item.type === 'NCC';
      const isPixKey = data.type === 1;
      const isQRCode = data.type === 2;
      const isBankData = data.type === 3;

      if (isPixKey) {
        const key = this.fomatKeyToSend(data.key, data.keyType);

        const responseSupplier = await this.paymentRemittanceRepository.updateERP(
            groupId,
            companyIdFromSupplier,
            {
              id: item.supplier_id_customer,
              data: {
                type: useNCC ? 'customer' : 'provider',
                key,
                key_type: data.keyType,
              },
              event: 'pix_key',
            },
          );

        if (responseSupplier.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        const responseAccount = await this.paymentRemittanceRepository
        .updateERP(groupId, companyId, {
            id: item.id_customer,
            data: {
              type: useNCC ? 'account_receivable' : 'account_payable',
              key,
              key_type: data.keyType,
            },
            event: 'pix_key',
          });

        if (responseAccount.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        await this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'SA2',
            id: item.supplier_id,
            field: 'A2_ZCHVPIX',
            value: key,
          },
        );

        await this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'SA2',
            id: item.supplier_id,
            field: 'A2_ZTIPPIX',
            value: data.keyType,
          },
        );

        await this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'SE2',
            id: item.id,
            field: 'E2_ZCHVPIX',
            value: key,
          },
        );
      }

      if (isQRCode) {
        const response = await this.paymentRemittanceRepository.updateERP(
          groupId,
          companyId,
          {
            id: item.id_customer,
            data: {
              type: useNCC ? 'account_receivable' : 'account_payable',
              key: data.qrcode,
            },
            event: 'qrcode_key',
          },
        );

        if (response.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        await this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'SE2',
            id: item.id_customer,
            field: 'E2_ZCHVPIX',
            value: data.qrcode,
          },
        );
      }

      if (isBankData) {
        const formatData: Record<string, string> = {
          type: useNCC ? 'customer' : 'provider',
          bank: data.bank,
          agency: data.agency,
          account_number: data.accountNumber,
          account_type: data.accountType,
          key_type: data.keyType,
        };

        if (this.groupConfig.use_digag) {
          formatData.agency_digit = data.agencyDigit;
        }

        if (this.groupConfig.use_digcta) {
          formatData.account_digit = data.accountDigit;
        }

        const response = await this.paymentRemittanceRepository.updateERP(
          groupId,
          companyId,
          {
            id: item.supplier_id_customer,
            data: formatData,
            event: 'bank_data',
          },
        );

        if (response.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        const supplierFields = [
          {
            field: 'A2_ZTIPPIX',
            value: KeyType.BANK_DATA,
          },
          {
            field: 'A2_BANCO',
            value: data.bank,
          },
          {
            field: 'A2_AGENCIA',
            value: data.agency,
          },
          {
            field: 'A2_NUMCON',
            value: data.accountNumber,
          },
          {
            field: 'A2_TIPCTA',
            value: data.accountType,
          },
        ];

        if (this.groupConfig.use_digag) {
          supplierFields.push({
            field: 'A2_DVAGE',
            value: data.agencyDigit,
          });
        }

        if (this.groupConfig.use_digcta) {
          supplierFields.push({
            field: 'A2_DVCTA',
            value: data.accountDigit,
          });
        }

        await Promise.all(
          supplierFields.map((fields) => this.paymentRemittanceRepository.updateInternal(
              'updateImportTable',
              {
                identify_values: 'SA2',
                id: item.supplier_id,
                ...fields,
              },
            )),
        );
      }

      this.close(true);
      this.$notification.success('PIX alterado com sucesso!');
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  async handleInternalUpdate(data: Data, item: PaymentRemittance) {
    const isPixKey = data.type === 1;
    const isQRCode = data.type === 2;
    const isBankData = data.type === 3;
    if (isPixKey) {
      const key = this.fomatKeyToSend(data.key, data.keyType);

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SA2',
          id: item.supplier_id,
          field: 'A2_ZCHVPIX',
          value: key,
        },
      );

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SA2',
          id: item.supplier_id,
          field: 'A2_ZTIPPIX',
          value: data.keyType,
        },
      );

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SE2',
          id: item.id,
          field: 'E2_ZCHVPIX',
          value: key,
        },
      );
    }

    if (isQRCode) {
      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SE2',
          id: item.id_customer,
          field: 'E2_ZCHVPIX',
          value: data.qrcode,
        },
      );
    }

    if (isBankData) {
      const supplierFields = [
        {
          field: 'A2_ZTIPPIX',
          value: KeyType.BANK_DATA,
        },
        {
          field: 'A2_BANCO',
          value: data.bank,
        },
        {
          field: 'A2_AGENCIA',
          value: data.agency,
        },
        {
          field: 'A2_NUMCON',
          value: data.accountNumber,
        },
        {
          field: 'A2_TIPCTA',
          value: data.accountType,
        },
      ];

      if (this.groupConfig.use_digag) {
        supplierFields.push({
          field: 'A2_DVAGE',
          value: data.agencyDigit,
        });
      }

      if (this.groupConfig.use_digcta) {
        supplierFields.push({
          field: 'A2_DVCTA',
          value: data.accountDigit,
        });
      }

      await Promise.all(
        supplierFields.map((fields) => this.paymentRemittanceRepository.updateInternal('updateImportTable', {
            identify_values: 'SA2',
            id: item.supplier_id,
            ...fields,
          })),
      );
    }
  }
  async handleUpdatePixInInvoice(data: Data, item: PaymentRemittance) {
    try {
      this.$dialog.startLoading();
      const { id: groupId } = this.groupConfig;
      const companyId = item.company_id;
      const isPixKey = data.type === 1;
      const isQRCode = data.type === 2;
      const isBankData = data.type === 3;

      let pixData = {};

      if (isPixKey) {
        const key = this.fomatKeyToSend(data.key, data.keyType);

        pixData = {
          pix_key: key,
          key_type: data.keyType,
          type: 'pix',
        };
      }

      if (isQRCode) {
        pixData = {
          pix_key: data.qrcode,
          key_type: null,
          type: 'qrcode',
        };
      }

      if (isBankData) {
        pixData = {
          invoice_carrier: data.bank,
          invoice_deposit_agency: data.agency,
          invoice_account: data.accountNumber,
          account_type: data.accountType,
          key_type: KeyType.BANK_DATA,
          type: 'bank_data',
        };
      }

      await this.paymentRemittanceRepository.changeInvoice(
        groupId,
        companyId,
        item.id_customer,
        pixData,
      );

      await this.handleInternalUpdate(data, item);
      this.$dialog.stopLoading();
      this.close(true);
      this.$notification.success('PIX alterado com sucesso!');
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  fomatKeyToSend(key: string, type: KeyType | string): string {
    if (
      type === KeyType.PHONE
      || type === KeyType.CPF
      || type === KeyType.CNPJ
    ) {
      return key
        .replaceAll(' ', '')
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '')
        .replaceAll('(', '')
        .replaceAll(')', '');
    }

    return key;
  }

  keyTypeIsCPFOrCNPJ(key: string): 'CPF' | 'CNPJ' {
    return key
      .replaceAll(' ', '')
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('/', '')
      .replaceAll('(', '')
      .replaceAll(')', '').length > 11
      ? 'CNPJ'
      : 'CPF';
  }
}
