

























































































































































import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { toCurrency } from '@/utils/';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import ColumnToShow from '@/types/ColumnToShow';
import LooseObjectType from '@/types/LooseObjectType';

import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';

import IPaymentRemittances from '@/domain/interfaces/IPaymentRemittances';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import Status from '@/domain/enums/PaymentRemittancesStatusType';
import Action from '@/domain/enums/PaymentRemittancesActionType';

import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';

import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';

import { CloseActionData, OpenActionData, SummaryActionData } from '../utils/interfaces';

import Buttons from './Buttons.vue';
import List from './List.vue';
import AddComment from './actions/addComment.vue';
import Approve from './actions/approve.vue';
import ChangeMethod from './actions/changeMethod.vue';
import Disapprove from './actions/disapprove.vue';
import Edit from './actions/edit.vue';
import Remove from './actions/remove.vue';
import RemoveInvoice from './actions/removeInvoice.vue';
import ViewDetails from './actions/viewDetails.vue';
import ViewInvoice from './actions/viewInvoice.vue';
import GenerateInvoice from './actions/generateInvoice.vue';
import GenerateRemittance from './actions/generateRemittance.vue';
import generatePDF from '../utils/generatePDF';
import {
  isStatusToApproveOrReprove,
  isStatusToGenerateRemittance,
  makeBalance,
  statusToApproveOrReprove,
} from '../utils';

@Component({
  components: {
    Buttons,
    'custom-list': List,
    AddComment,
    Approve,
    ChangeMethod,
    Disapprove,
    Edit,
    Remove,
    RemoveInvoice,
    ViewDetails,
    ViewInvoice,
    GenerateInvoice,
    GenerateRemittance,
    DynamicColumnMenu,
  },
})
export default class PaymentRemittancesLists extends Vue {
  @Prop({
    type: Object as () => IPaymentRemittances,
    required: true,
  }) data!: IPaymentRemittances;

  @Prop({
    type: Object as () => Record<string, Array<PaymentRemittance>>,
    required: true,
  }) list!: Record<string, Array<PaymentRemittance>>;

  @Prop({
    type: Object as () => CompanyGroupConfig,
    required: true,
  }) groupConfig!: CompanyGroupConfig;

  @Prop({
    type: Object as () => SummaryActionData,
  }) summaryAction!: SummaryActionData;

  @Prop({
    type: Array as () => Array<ColumnToShow>,
  }) columns!: Array<ColumnToShow>;

  @Emit()
  reload(): boolean {
    return true;
  }

  @Emit('set-columns')
  setColumns(headers: Array<IVDataTableHeader>) {
   return this.columns.map((column) => {
      const columnToShow = headers.find(({ value }) => value === column.value);
      let willShowColumn = true;

      if (columnToShow !== undefined) {
        willShowColumn = columnToShow.show ?? false;
      }

      return {
        ...column,
        show: willShowColumn,
      };
    });
  }

  @Watch('summaryAction', { deep: true })
  onChangeSummaryAction(value: SummaryActionData) {
    const { type, items } = value;

    if (items.length) {
      this.action({ type, items });
    }
  }

  @Watch('headersToToggle')
  public onChangeHeadersToToggle(): void {
    this.setColumns(this.headersToToggle);
  }

  public readonly groupFilterParametersEnum = GroupFilterParametersEnum;

  readonly ActionType = Action;
  readonly formatCurrency = toCurrency;
  readonly makeBalance = makeBalance;

  readonly paymentRemittanceRepository = new PaymentRemittanceRepository();

  selecteds: Record<string, Array<PaymentRemittance>> = {};

  showGenerate: boolean = false;
  showApprove: boolean = false;
  showInvoice: boolean = false;

  public headerKeys: LooseObjectType<string> = {
    title: 'data_table_payment_remittances_show_column_title',
    due_date: 'data_table_payment_remittances_show_due_date',
    ported: 'data_table_payment_remittances_show_column_ported',
    form_of_payment_description: 'data_table_payment_remittances_show_column_form_of_payment_description',
    data_of_payment: 'data_table_payment_remittances_show_column_data_of_payment',
    user: 'data_table_payment_remittances_show_column_user',
    fees: 'data_table_payment_remittances_show_column_fees',
    discount: 'data_table_payment_remittances_show_column_discount',
    increase: 'data_table_payment_remittances_show_column_increase',
    decrease: 'data_table_payment_remittances_show_column_decrease',
    total: 'data_table_payment_remittances_show_column_total',
    sent: 'data_table_payment_remittances_show_column_sent',
    approved: 'data_table_payment_remittances_show_column_approved',
    status: 'data_table_payment_remittances_show_column_status',
  }
  open: Record<Action, boolean> = {} as Record<Action, boolean>;

  items: Array<PaymentRemittance> = [];

  allItemsSelected: { total: number, value: number } = {
    total: 0,
    value: 0,
  };

  public headersToToggle: Array<IVDataTableHeader> = [
    {
      text: 'Título',
      value: 'title',
      show: true,
      align: 'start',
    },
    {
      text: 'Data de Vencimento',
      value: 'due_date',
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Portador',
      value: 'ported',
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Forma de Pagamento',
      value: 'form_of_payment_description',
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Dado de Pagamento',
      value: 'data_of_payment',
      show: false,
      align: 'center',
      sortable: false,
    },
    {
      text: 'Usuário Gerador da Remessa',
      value: 'user', // não possui
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Juro',
      value: 'fees', // não possui
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Desconto',
      value: 'discount', // não possui
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Acréscimo',
      value: 'increase',
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Decréscimo',
      value: 'decrease',
      show: false,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Valor',
      value: 'total',
      show: true,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Valor Enviado',
      value: 'sent', // não possui
      show: true,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Valor Aprovado',
      value: 'approved', // não possui
      show: true,
      align: 'center',
      sortable: true,
    },
    {
      text: 'Status',
      value: 'status', // não possui
      show: true,
      align: 'center',
      sortable: true,
    },
  ];

  get allItems(): { total: number, value: number } {
    return Object
      .keys(this.list)
      .reduce(
        (prev, key) => ({
          total: prev.total + this.list[key].length,
          value: prev.value + this.list[key]
            .reduce((itemPrev, item) => itemPrev + this.makeBalance(item), 0),
        }),
        {
          total: 0,
          value: 0,
        },
      );
  }

  get allItemsSent(): { total: number, value: number } {
    return Object
      .keys(this.list)
      .reduce(
        (prev, key) => {
          const items = this.list[key]
            .filter((select) => isStatusToApproveOrReprove(select.status));

          return {
            total: prev.total + items.length,
            value: prev.value + items
              .reduce((itemPrev, item) => itemPrev + this.makeBalance(item), 0),
          };
        },
        {
          total: 0,
          value: 0,
        },
      );
  }

  get allItemsApprove(): { total: number, value: number } {
    return Object
      .keys(this.list)
      .reduce(
        (prev, key) => {
          const items = this.list[key]
            .filter((select) => select.status === Status.APPROVED);

          return {
            total: prev.total + items.length,
            value: prev.value + items
              .reduce((itemPrev, item) => itemPrev + this.makeBalance(item), 0),
          };
        },
        {
          total: 0,
          value: 0,
        },
      );
  }

  public get headers(): IVDataTableHeader[] {
    const filteredHeaders = this.headersToToggle.filter((h) => {
      if (h.value === 'approved' && !this.hasPermissionToApproveOrReprove) {
        return false;
      }

      return h.show;
    });

    return [
      {
        text: '',
        value: 'data-table-select',
        sortable: false,
        align: 'center',
      },
      ...filteredHeaders,
      {
        text: '',
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  }

  created() {
    this.setHeaders();
  }

  async setHeaders(): Promise<void> {
    this.headersToToggle = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.headersToToggle, this.columns);
  }

  get hasPermissionToApproveOrReprove(): boolean {
    return Boolean(this.$session.get('user_access-approve_or_reprove_remittance'));
  }

  allSameStatus(status: Array<Status>) {
    const show = Object
      .keys(this.selecteds)
        .map((key) => !!this.selecteds[key].length && this.selecteds[key]
          .every((item: PaymentRemittance) => status.includes(item.status)));

    return show.every((value) => value === true);
  }

  canGenerateRemittanceOrInvoice(): boolean {
    const titles = Object
      .keys(this.selecteds)
      .flatMap((key) => this.selecteds[key]
        .filter(
          (title) => (
            // title.released_remittance_payble
            !title.cnab_remi_generated_id
            && isStatusToGenerateRemittance(title.status)
          ),
        ));

    return !!titles.length;
  }

  handleSelecteds(selecteds: Array<PaymentRemittance>, index: string) {
    this.selecteds[index] = selecteds;

    this.showApprove = Boolean(this.$session.get('user_access-approve_or_reprove_remittance'))
      && this.allSameStatus(statusToApproveOrReprove);

    this.showGenerate = this.canGenerateRemittanceOrInvoice();
    this.showInvoice = Boolean(this.$session.get('user_access-generate_invoice'))
      && this.showGenerate;

    this.allItemsSelected = Object
      .keys(this.selecteds)
      .reduce(
        (prev, key) => ({
          total: prev.total + this.selecteds[key].length,
          value: prev.value + this.selecteds[key]
            .reduce((itemPrev, item) => itemPrev + this.makeBalance(item), 0),
        }),
        {
          total: 0,
          value: 0,
        },
      );
  }

  actionToAllSelecteds(action: Action): void {
    const titles = Object
      .keys(this.selecteds)
      .flatMap((key) => this.selecteds[key]);

    this.action({ type: action, items: titles });
  }

  action(data: OpenActionData): void {
    const { type, items } = data;

    if (type !== Action.GENERATE_REPORT && !items.length) {
      this.$notification.warn('Nenhum título selecionado!');
      return;
    }

    const sameStatus = items.every((item) => item.status === items[0].status);
    const someRemi = items.some((item) => item.cnab_remi_generated_id);

    switch (type) {
      case Action.GENERATE_REMITTANCE:
        if (!sameStatus) {
          this.$notification.warn('Títulos com diferentes status foram selecionados.');
          return;
        }

        if (someRemi) {
          this.$notification.warn('Títulos com número de borderô cadastrados foram selecionados.');
          return;
        }

        break;
      case Action.GENERATE_INVOICE:
        if (!sameStatus) {
          this.$notification.warn('Títulos com diferentes status foram selecionados.');
          return;
        }

        if (someRemi) {
          this.$notification.warn('Títulos com número de borderô cadastrados foram selecionados.');
          return;
        }

        break;
      case Action.REMOVE:
        if (!items[0].cnab_remi_generated_id) {
          this.$notification.warn('Título em borderô pelo ERP, não pode ser removido pelo inncash.');
          return;
        }
        break;
      case Action.REMOVE_INVOICE:
        if (items[0].origin !== 'inncash' || items[0].type !== 'FT') {
          this.$notification.warn('Essa fatura não pode ser removido pelo InnCash!');
          return;
        }
        break;
      case Action.VIEW_INVOICE:
        if (items[0].origin !== 'inncash' || items[0].type !== 'FT') {
          this.$notification.warn('Fatura inválida para visualização!');
          return;
        }
        break;
      case Action.GENERATE_REPORT: {
        const showHeaders = this.headers
          .filter((header) => header.show && (header.value !== 'sent' && header.value !== 'approved'));

        generatePDF(this.list, showHeaders, this.data, this.groupConfig);
        return;
      }
      default:
        break;
    }

    this.open[type] = true;
    this.items = items;
  }

  close({ type, reload }: CloseActionData): void {
    this.handleSelecteds([], '');
    this.items = [];
    this.open[type] = false;

    if (reload) {
      this.selecteds = {};
      this.reload();
    }
  }
}
