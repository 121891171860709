



















































  import {
    Vue,
    Component,
    Prop,
    Emit,
    Watch,
  } from 'vue-property-decorator';
  import boletoValidator from '@mrmgomes/boleto-utils';
  import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';

  interface Data {
    barcode: string,
    document?: string,
    name?: string,
  }

  @Component
  export default class PaymentRemittancesActionEditBarcode extends Vue {
    @Prop({
      type: Object as () => PaymentRemittance,
    }) readonly item!: PaymentRemittance;

    @Watch('item', { immediate: true })
    changedOpen() {
      if (this.item) {
        if (this.barcode) {
          this.digitableLine = boletoValidator.codBarras2LinhaDigitavel(this.barcode, true) || '';
        }

        this.barcodeInput = this.barcode;

        this.document = this.item.supplier_z_document;
        this.name = this.item.supplier_z_name;

        this.isOther = this.item.form_of_payment === '008';
      }
    }

    @Emit()
    close(reload: boolean = false) {
      return reload;
    }

    readonly paymentRemittanceRepository: PaymentRemittanceRepository =
      new PaymentRemittanceRepository();

    barcodeInput: string = '';
    digitableLine: string = '';
    name: string = '';
    document: string = '';

    isOther: boolean = false;

    get group() {
      return this.$session.get('company_group_id');
    }

    get useNCC() {
      return this.item
        && this.item.type === 'NCC'
        && Boolean(this.$session.get('use_ncc'));
    }

    get barcode(): string {
      return this.item.barcode;
    }

    set barcode(value: string) {
      this.item.barcode = value;
    }

    get documentMask(): string {
      let mask: string = '##################';

      if (!this.document) return mask;

      const value = this.justNumbers(this.document);

      if (value.length <= 11) {
        mask = '###.###.###-##';
      } else if (value.length >= 12) {
        mask = '##.###.###/####-##';
      }

      return mask;
    }

    required = (value: string | number) => !!value || 'Campo obrigatório!';
    requiredMinDocument = (value: string) => {
      const { length } = this.justNumbers(value);
      return (length === 11 || length === 14) || 'Valor inválido!';
    };

    justNumbers(value: string): string {
      return value.replace(/[^\d]/g, '');
    }

    selectCode(e: any): void {
      const barcodeText = document.querySelector(`#${e.target.id}`) as HTMLInputElement;
      barcodeText.select();
    }

    async clipboardCopy(value: string): Promise<void> {
      try {
        await navigator.clipboard.writeText(value);
        this.$notification.success('Código copiado com sucesso!');
      } catch (error) {
        console.error(error);
      }
    }

    save(): void {
      const value = this.barcodeInput;

      if (value) {
//        const barcodeIsValid = boletoValidator
//          .validarBoleto(value).sucesso || false;

          this.handleUpdateBarcode();
//        if (!barcodeIsValid) {
//          this.$notification.warn('Código de barras inválido!');
//        }
      }
    }

    digitableLineChanged(value: string): void {
      if (value) {
        this.barcodeInput = '';

        const response = boletoValidator
          .validarBoleto(value);

        if (response.sucesso) {
          this.barcodeInput = response.codigoBarras;
        } else {
          this.$notification.warn(response.mensagem);
        }
      }
    }

    barcodeInputChanged(value: string): void {
      value = value.replace(/[^0-9]/g, '');

      if (value) {
        this.digitableLine = '';

        if (value.length >= 44) {
          const response = boletoValidator.validarBoleto(value);

          if (response.sucesso) {
            this.digitableLine = response.linhaDigitavel;
          } else {
            this.$notification.warn(response.mensagem);
          }
        }
      }
    }

    reset(): void {
      this.digitableLine = '';
      this.barcodeInput = '';
      this.document = '';
      this.name = '';
    }

    async handleUpdateBarcode(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const data: Data = {
          barcode: this.barcodeInput,
          document: this.isOther ? this.justNumbers(this.document) : undefined,
          name: this.isOther ? this.name : undefined,
        };

        const success = this.item.origin === 'inncash'
          ? await this.updateInvoice(data)
          : await this.updateTitle(data);

        if (success) {
          this.barcode = this.barcodeInput;
          this.close(true);

          this.$notification.success('Código de barras alterado com sucesso!');
        } else {
          throw new Error('Ocorreu um erro na alteração do código de barras!');
        }
      } catch (error: any) {
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
        this.$dialog.stopLoading();
      }
    }

    async updateInvoice(data: Data): Promise<boolean> {
      const params = {
        field: {
          invoice_barcode: data.barcode,
        },
        where: {
          branch: this.item.branch,
          provider_code: this.item.supplier_code_number,
          provider_store: this.item.supplier_store_number,
          invoice_number: this.item.number,
        },
      };

      await this.paymentRemittanceRepository.updateInvoice(params);

      if (data.document && data.name) {
        await this.updateSupplierWithDifferentHolder(data);

        const paramsInternalUpdate = [
          {
            identify_values: 'fornecedor',
            id: this.item.supplier_id,
            field: 'A2_ZCGC',
            value: data.document,
          },
          {
            identify_values: 'fornecedor',
            id: this.item.supplier_id,
            field: 'A2_ZNOME',
            value: data.name,
          },
        ];

        await Promise.all(
          paramsInternalUpdate
            .map((paramsInteranl) => this.paymentRemittanceRepository
              .updateInternal('updateImportTable', paramsInteranl)),
        );
      }

      return true;
    }

    async updateTitle(data: Data): Promise<boolean> {
      const params = {
        id: this.item.id_customer,
        data: {
          barcode: data.barcode,
          type: this.useNCC
            ? 'account_receivable'
            : 'account_payable',
        },
        event: 'barcode',
      };

      const response = await this.paymentRemittanceRepository
        .updateERP(this.group, this.item.company_id, params);

      if (response.error) throw new Error('Ocorreu um erro na alteração do código de barras no título!');

      const paramsInternalUpdate = [
        {
          identify_values: 'CP',
          id: this.item.id,
          field: 'E2_CODBAR',
          value: data.barcode,
        },
      ];

      if (data.document && data.name) {
        await this.updateSupplierWithDifferentHolder(data);

        paramsInternalUpdate.push(
          {
            identify_values: 'fornecedor',
            id: this.item.supplier_id,
            field: 'A2_ZCGC',
            value: data.document,
          },
          {
            identify_values: 'fornecedor',
            id: this.item.supplier_id,
            field: 'A2_ZNOME',
            value: data.name,
          },
        );
      }

      await Promise.all(
        paramsInternalUpdate
          .map((paramsInternal) => this.paymentRemittanceRepository
            .updateInternal('updateImportTable', paramsInternal)),
      );

      return true;
    }

    async updateSupplierWithDifferentHolder(data: Data) {
      const paramsSupplier = {
        id: this.item.supplier_id_customer,
        data: {
          general_code: data.document,
          provider_name: data.name,
          type: this.useNCC
            ? 'customer'
            : 'provider',
        },
        event: 'supplier_with_different_holder',
      };

      const response = await this.paymentRemittanceRepository
        .updateERP(this.group, this.item.supplier_company_id, paramsSupplier);

      if (response.error) throw new Error('Ocorreu um erro na alteração dos dados do fornecedor!');
    }
  }
