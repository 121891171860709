<template>
  <div>
    <div
      id="params"
      class="m-portlet m-portlet--mobile"
      v-show="!showPixUpdate"
    >
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 class="m-portlet__head-text col-md-4">Parâmetros</h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
          <button
            id="btn-generate-remittance"
            type="button"
            v-on:click="load()"
            class="btn m-btn--air btn-success"
          >
            Visualizar
          </button>
          <v-btn icon depressed class="ml-4" @click="toggleParams()">
            <v-icon
              x-large
              class="text--disabled chevron"
              :class="!paramsVisibility ? 'toggle-chevron' : ''"
              >mdi-chevron-up</v-icon
            >
          </v-btn>
        </div>
      </div>
      <div class="m-portlet__body" v-show="paramsVisibility">
        <div
          class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
        >
          <div class="row align-items-center">
            <v-col cols="12" lg="4" md="5" sm="12" xs="12">
              <date-select
                :value="date"
                label="Data"
                id="date"
                @change="date = $event"
              />
            </v-col>
            <v-col cols="12" lg="8" md="7" sm="12" xs="12">
              <div class="form-group">
                <label>Empresa</label>
                <select
                  class="form-control select2 hide"
                  field-name="Empresa"
                  id="company_id"
                  name="company_id"
                  style="width: 100%"
                >
                  <option value="">Selecione...</option>
                  <option
                    v-for="company in companys"
                    :value="company.id"
                    v-bind:key="company.id"
                  >
                    {{ company.name }}
                  </option>
                </select>
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
                <label>Forma de Pagamento</label>
                <select
                  class="form-control select2 hide"
                  field-name="Forma de Pagamento"
                  id="paymentMethod"
                  name="paymentMethod"
                  style="width: 100%"
                  multiple="multiple"
                >
                  <option
                    v-for="payment in listPayment"
                    :value="payment.code"
                    v-bind:key="payment.code"
                  >
                    {{ payment.description }}
                  </option>
                </select>
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
                <label>Dados de Pagamento</label>
                <select
                  class="form-control select2 hide"
                  field-name="Dados de Pagamento"
                  id="dataPayment"
                  name="dataPayment"
                  style="width: 100%"
                >
                  <option
                    v-for="status in listDataPayment"
                    :value="status.id"
                    v-bind:key="status.id"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
                <label>Portador</label>
                <select
                  class="form-control select2 hide"
                  field-name="Portador"
                  id="portador"
                  name="portador"
                  style="width: 100%"
                  multiple="multiple"
                >
                  <option
                    v-for="bank in banks"
                    :value="bank.code"
                    :key="bank.code"
                  >
                    {{
                      bank.code != "Em Branco"
                        ? bank.code + " - " + bank.name
                        : bank.name
                    }}
                  </option>
                </select>
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <div class="form-group">
                <label>Banco do Fornecedor</label>
                <select
                  class="form-control select2 hide"
                  field-name="Forma de Pagamento"
                  id="supplierBank"
                  name="supplierBank"
                  style="width: 100%"
                >
                  <option value="ALL">Todos</option>
                  <option
                    v-for="bank in banks"
                    :value="bank.code"
                    :key="bank.code"
                  >
                    {{
                      bank.code != "Em Branco"
                        ? bank.code + " - " + bank.name
                        : bank.name
                    }}
                  </option>
                </select>
              </div>
            </v-col>
          </div>
        </div>
      </div>
    </div>

    <template v-if="listRemittancePayableFiltered">
      <div
        id="listRemittancePayable"
        v-show="!showPixUpdate"
        class="m-portlet m-portlet--mobile"
      >
        <div class="m-portlet__head">
          <div class="m-portlet__head-tools">
            <button
              id="btn-generate"
              type="button"
              v-on:click="generateCnabAll()"
              class="btn m-btn--air btn-success mr-3"
            >
              Gerar Remessa
            </button>
            <span
              v-if="
                companyGroupId == 16 ||
                companyGroupId == 12 ||
                companyGroupId == 8 ||
                companyGroupId == 17 ||
                companyGroupId == 6 ||
                companyGroupId == 15 ||
                companyGroupId == 10 ||
                companyGroupId == 8 ||
                companyGroupId == 18 ||
                companyGroupId == 19 ||
                companyGroupId == 20 ||
                companyGroupId == 11 ||
                companyGroupId == 22 ||
                companyGroupId == 9 ||
                companyGroupId == 14 ||
                companyGroupId == 23 ||
                companyGroupId == 2 ||
                companyGroupId == 7
              "
            >
              <button
                type="button"
                v-if="canGenerateAInvoice()"
                v-on:click="prepareTitle()"
                class="btn m-btn--air btn-success mr-3"
              >
                Gerar fatura
              </button>
            </span>
          </div>
        </div>
        <div class="m-portlet__body" style="font-size: 14px">
          <div
            v-for="remittance_payable in listRemittancePayableFiltered"
            v-bind:key="remittance_payable.code"
            class="d-flex flex-column"
          >
            <div
              class="row my-0 mx-n4 levels level-main header-advancepayments"
              style="
                padding-top: 6px;
                padding-bottom: 6px;
                background-color: #498cc5;
                color: white;
                font-weight: bold;
                font-size: 16px;
              "
            >
              <div
                class="col-sm-4 description"
                style="padding-right: 5px; padding-left: 5px"
              >
                <div class="m-checkbox-inline">
                  <label
                    class="m-checkbox m-checkbox--success"
                    style="margin-bottom: 0px; color: white"
                  >
                    <input
                      type="checkbox"
                      v-model="remittance_payable.checked"
                      @click="checkAllGroup(remittance_payable)"
                      class="check-box-header"
                    />
                    <strong>{{ remittance_payable.description }}</strong>
                    <span
                      style="height: 15px; width: 15px; background-color: white"
                    ></span>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-1 description"
                style="padding-right: 0; padding-left: 0; text-align: right"
              >
                <strong>{{ getTotal(remittance_payable.elements) }}</strong>
              </div>
              <div
                class="col-sm-1 description"
                style="padding-right: 0; padding-left: 0; text-align: center"
              >
                <strong>Portador</strong>
              </div>
              <div
                class="col-sm-5 description"
                style="
                  padding-right: 20px;
                  padding-left: 20px;
                  text-align: left;
                "
              >
                <strong>{{ getTitleGroup(remittance_payable) }}</strong>
              </div>
              <div class="col-sm-1 pr-2" style="text-align: right">
                <div class="dropdown">
                  <i
                    class="fa fa-bars dropdown-menu-icon ml-n1"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                  ></i>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item"
                      href=""
                      style="padding: 0.3rem 1.2rem"
                      @click="generateCnabPaymentMethod(remittance_payable)"
                      data-toggle="modal"
                      >Gerar Remessa</a
                    >
                    <div
                      v-if="
                        companyGroupId == 16 ||
                        companyGroupId == 12 ||
                        companyGroupId == 8
                      "
                    >
                      <a
                        class="dropdown-item"
                        href=""
                        style="padding: 0.3rem 1.2rem"
                        v-if="canGenerateAInvoice()"
                        v-on:click="prepareTitle()"
                        data-toggle="modal"
                        >Gerar Fatura</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="checkbox-area"
              v-for="(cp, index) in remittance_payable.elements"
              :key="cp.id"
              :class="[
                index % 2 == 0
                  ? 'row levels level-one mt-0 mx-n4 py-1'
                  : 'mt-0 mx-n4 py-1 row levels level-one zebra',
              ]"
            >
              <div
                class="col-sm-4"
                style="padding-right: 5px; padding-left: 5px !important"
              >
                <div
                  class="m-checkbox-inline"
                  v-popover:tooltip="
                    'Razão Social: ' +
                    cp.A2_NOME +
                    formatCgc(cp.A2_TIPO, cp.A2_CGC)
                  "
                >
                  <label
                    class="m-checkbox m-checkbox--success remittance-checkbox-label"
                    v-if="!cp.E2_NUMBOR"
                  >
                    <input
                      type="checkbox"
                      v-model="cp.LIBERADO_REMESSA_PAGAR"
                      v-on:change="checkIten(cp, remittance_payable, $event)"
                    />
                    {{ getTextTitulo(cp) }}
                    <span style="height: 15px; width: 15px"></span>
                  </label>
                  <label
                    class="m-checkbox m-checkbox--disabled remittance-checkbox-label-disabled"
                    v-if="cp.E2_NUMBOR"
                  >
                    <input
                      type="checkbox"
                      :checked="cp.E2_NUMBOR"
                      :disabled="cp.E2_NUMBOR"
                    />
                    {{ getTextTitulo(cp) }}
                    <span style="height: 15px; width: 15px"></span>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-1"
                style="padding-right: 0px; padding-left: 0px; text-align: right"
              >
                <span>{{ formatNumber(cp.TOTAL) }}</span>
              </div>
              <div
                class="col-sm-1"
                style="
                  padding-right: 0px;
                  padding-left: 0px;
                  text-align: center;
                "
              >
                <span>{{ cp.E2_PORTADO }}</span>
              </div>
              <div
                class="col-sm-5"
                style="
                  padding-right: 20px;
                  padding-left: 20px;
                  text-align: left;
                "
              >
                <span>{{ getTextBank(remittance_payable, cp) }}</span>
              </div>
              <div class="col-sm-1" style="text-align: right">
                <div class="dropdown">
                  <i
                    class="fa fa-bars dropdown-menu-icon"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    style="
                      float: right;
                      cursor: pointer;
                      padding-right: 2px;
                      margin-left: 5px;
                      color: #575962;
                    "
                  ></i>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item"
                      style="padding: 0.3rem 1.2rem; cursor: pointer"
                      @click="updatePayment(cp)"
                      >Alterar Forma Pagto.</a
                    >
                    <a
                      class="dropdown-item"
                      style="padding: 0.3rem 1.2rem; cursor: pointer"
                      v-if="
                        [1, 2, 3, 5, 6, 7, 10, 11].includes(
                          cp.payment_method.type
                        )
                      "
                      @click="edit(remittance_payable, cp)"
                      >Editar</a
                    >
                    <a
                      class="dropdown-item"
                      style="padding: 0.3rem 1.2rem; cursor: pointer"
                      @click="openView(cp)"
                      >Visualizar</a
                    >
                    <a
                      class="dropdown-item"
                      v-if="cp.E2_NUMBOR"
                      style="padding: 0.3rem 1.2rem; cursor: pointer"
                      @click="excludeFromRemittance(cp)"
                      >Remover do borderô</a
                    >
                    <a
                      class="dropdown-item"
                      v-if="
                        cp.E2_TIPO === 'FT' &&
                        cp.origin === 'inncash' &&
                        !cp.E2_NUMBOR &&
                        !cp.dda_record
                      "
                      style="padding: 0.3rem 1.2rem; cursor: pointer"
                      @click="deleteInvoice(cp)"
                      >Excluir Fatura</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row levels level-main header-advancepayments mx-n4"
            style="
              padding-top: 6px;
              padding-bottom: 6px;
              background-color: #498cc5;
              color: white;
              font-weight: bold;
              font-size: 16;
            "
          >
            <div
              class="col-sm-4 description"
              style="padding-right: 5px; padding-left: 5px"
            >
              Total Geral ({{ getTotalRecordsGeral() }} títulos)
            </div>
            <div
              class="col-sm-1 description"
              style="padding-right: 0; padding-left: 0; text-align: right"
            >
              <strong>{{ getTotalGeral() }}</strong>
            </div>
          </div>
          <div
            class="row levels level-main header-advancepayments mx-n4"
            style="
              padding-top: 6px;
              padding-bottom: 6px;
              background-color: #498cc5;
              color: white;
              font-weight: bold;
              font-size: 16px;
            "
          >
            <div
              class="col-sm-4 description"
              style="padding-right: 5px; padding-left: 5px"
            >
              Total Selecionado ({{ getTotalRecordsSelecionado() }} títulos)
            </div>
            <div
              class="col-sm-1 description"
              style="padding-right: 0; padding-left: 0; text-align: right"
            >
              <strong>{{ getTotalSelecionado() }}</strong>
            </div>
          </div>
        </div>
      </div>

      <view-information ref="ViewInformation"></view-information>

      <generate-cnab ref="GenerateCnab" @success="load"></generate-cnab>

      <update-information ref="UpdateInformation"></update-information>

      <provider
        v-if="showProviderSelect"
        :showProviderSelect="showProviderSelect"
        :item="paymentMethodsSelected"
        :company="companyId"
        @save="generateInvoice"
        @close="showProviderSelect = false"
      />
      <pix-update
        v-if="showPixUpdate"
        :companyId="companyId"
        :item="selectedItem"
        @close="onClosePixModal"
      />

      <tooltip />
    </template>
  </div>
</template>

<script>
import _ from "lodash";

import ViewInformation from "../../components/view-information.vue";
import UpdateInformation from "../../components/update-information.vue";
import GenerateCnab from "../../components/generate-cnab.vue";
import DateSelect from "../../../src/components/fields/DateSelect.vue";
import ArtificialInvoices from "@/services/artificial-invoices.service";
import AccountType from "@/domain/enums/AccountTypeEnum";
import RemittanceRepository from "@/repositories/RemittanceRepository";
import Swal from "sweetalert2";
import {
  validateImporting,
  validatePaymentRemittance,
  ValidationError,
} from "@/utils/processes_validation.js";
import PixUpdate from "./PixUpdate.vue";
import Provider from "./Provider.vue";

export default {
  components: {
    "view-information": ViewInformation,
    "generate-cnab": GenerateCnab,
    "update-information": UpdateInformation,
    DateSelect,
    PixUpdate,
    Provider,
  },
  data() {
    return {
      paramsVisibility: true,
      companys: [],
      listPayment: [],
      listDataPayment: [],
      banks: [],
      cnabRemittancesConfig: [],
      listRemittancePayable: null,
      dataDescriptions: [],
      totalSaldoDiff: 0,
      totalSelected: 0,
      countSelected: 0,
      typeCnab: "",
      paymentMethodsSelected: {},
      company_group: {},
      date: null,
      transferType: [],

      messageError: "",
      remittanceLimit: 0,
      showPixUpdate: false,
      companyId: null,
      selectedItem: null,
      scrollTo: 0,

      showProviderSelect: false,
    };
  },
  computed: {
    listRemittancePayableFiltered() {
      if (!this.listRemittancePayable) return null;

      const array = this.listRemittancePayable.filter(
        (item) => item && item.elements.length > 0
      );

      array.forEach((remittancePayable) => {
        if (
          (!remittancePayable && !remittancePayable.elements) ||
          !remittancePayable.code
        )
          return;

        remittancePayable.elements = _.orderBy(
          remittancePayable.elements,
          ["A2_NREDUZ", "E2_NUM", "E2_PARCELA"],
          ["asc", "asc", "asc"]
        );
      });

      if (!array.length) return null;

      return array;
    },
  },
  mounted() {
    this.companyGroupId = this.$session.get("company_group_id");
    blockPage("Carregando os dados...");

    numeral.language("pt-br");

    $("#company_id").select2({ placeholder: "Selecione uma Empresa" });

    $("#paymentMethod").select2({ placeholder: "Deixe em branco para todas" });

    $("#dataPayment").select2();

    $("#portador").select2({ placeholder: "Deixe em branco para todos" });

    $("#supplierBank").select2({ placeholder: "Deixe em branco para todos" });

    this.loadCompanys();
    this.loadCompanyGroup();
  },
  methods: {
    toggleParams() {
      this.paramsVisibility = !this.paramsVisibility;
    },
    hiddenParams() {
      this.paramsVisibility = false;
    },
    loadCompanys() {
      blockPage("Carregando os dados...");
      this.$http
        .get(`${this.$store.state.API_BASE}company/listAll`, {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            this.companys = result.body.data;

            this.loadBanks();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    loadPaymentMethod(payment_method) {
      this.$http
        .get(
          `${this.$store.state.API_BASE}paymentmethod/listAllByCompany/${$(
            "#company_id"
          ).val()}`,
          { headers: { token: this.$session.get("token") } }
        )
        .then(
          (result) => {
            this.listPayment =
              result.body.data && result.body && result.body.data
                ? result.body.data
                : [];

            this.listPayment.push({
              code: "''",
              description: "Em Branco",
            });

            $("#paymentMethod").select2({
              placeholder: "Deixe em branco para todas",
            });

            if (payment_method) {
              setTimeout(() => {
                $("#paymentMethod").val(payment_method);
                $("#paymentMethod").trigger("change");
              }, 0);
            }
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    loadBanks() {
      this.$http
        .get(
          `${this.$store.state.API_BASE}bank/listAll/${this.$session.get(
            "company_group_id"
          )}`,
          {
            headers: { token: this.$session.get("token") },
          }
        )
        .then(
          (result) => {
            this.banks =
              result.body.data && result.body && result.body.data
                ? result.body.data
                : [];

            this.banks.unshift({
              code: "Em Branco",
              name: "Em Branco",
            });

            this.loadParameter();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    loadParameter() {
      this.listDataPayment = [
        { id: 0, name: "Ambos" },
        { id: 1, name: "Preenchidos" },
        { id: 2, name: "Em branco" },
      ];

      this.$http
        .get(
          `${this.$store.state.API_BASE}fieldparameter/getRemittancePayable`,
          {
            headers: { token: this.$session.get("token") },
          }
        )
        .then(
          (result) => {
            let date = result.body.data.date || moment().format("DD/MM/YYYY");
            this.date = date;

            if (result.body.data.company_id) {
              $("#company_id").val(result.body.data.company_id);
              $("#company_id").trigger("change");

              if (result.body.data.payment_method) {
                this.loadPaymentMethod(result.body.data.payment_method);
              }
            }

            if (result.body.data.bank) {
              $("#portador").val(result.body.data.bank);
              $("#portador").trigger("change");
            }

            if (result.body.data.data_payment) {
              $("#dataPayment").val(result.body.data.data_payment);
              $("#dataPayment").trigger("change");
            }

            $("#company_id").change((e) => {
              this.loadPaymentMethod();
            });

            unblockPage();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    load() {
      const self = this;

      const companiesId = Array.of($("#company_id").val());

      checkImportBeforeSearch(self, companiesId)
        .then(validateImporting)
        .then((data) => validatePaymentRemittance(data, self.date, self.date))
        .then(() => self.loadRemittance())
        .catch((reason) => {
          if (reason instanceof ValidationError) {
            showNotification("Atenção", reason.message, "warning");
          } else {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
          }
          unblockPage();
        });
    },
    loadRemittance() {
      const param = {
        date: this.date,
        company_id: $("#company_id").val(),
        payment_method:
          $("#paymentMethod").val().length > 0
            ? $("#paymentMethod").val()
            : "ALL",
        bank: $("#portador").val().length > 0 ? $("#portador").val() : null,
        supplierBank:
          $("#supplierBank").val().length > 0 ? $("#supplierBank").val() : null,
        data_payment: $("#dataPayment").val(),
      };

      if ($.trim(param.date) == "") {
        showNotification("Atenção", "A data deve ser informada", "warning");
        return false;
      }

      if (!param.company_id) {
        showNotification("Atenção", "A empresa deve ser informada", "warning");
        return false;
      }

      blockPage("Carregando os dados...");

      this.listRemittancePayable = null;

      this.$http
        .post(`${this.$store.state.API_BASE}remittancepayable/listAll`, param, {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            unblockPage();
            if (result.data && result.data.error) {
              showNotification("Atenção", result.message, "danger");
            } else if (result.data && result.data.total_records > 0) {
              this.remittanceLimit = result.data.remittanceLimit;
              this.listRemittancePayable = result.data.data;
              this.transferType = result.data.transfer_type;
              this.showPixUpdate = false;
              if (!this.listRemittancePayableFiltered) {
                showNotification("Atenção", "Sem dados para listar", "warning");
              } else {
                if (this.$session.get("user_config_autotoggle_paramns")) {
                  this.hiddenParams();
                }
              }
            } else {
              showNotification("Atenção", "Sem dados para listar", "warning");
            }
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    getTextTitulo(cp) {
      let formatted = "";

      formatted += cp.A2_NREDUZ;
      formatted += cp.E2_FILIAL ? ` - ${cp.E2_FILIAL}` : "";
      formatted += cp.E2_PREFIXO ? ` - ${cp.E2_PREFIXO}` : "";
      formatted += ` - ${cp.E2_NUM}`;
      formatted += cp.E2_PARCELA ? ` - ${cp.E2_PARCELA}` : "";
      formatted += cp.E2_TIPO ? ` - ${cp.E2_TIPO}` : "";

      return formatted;
    },
    formatNumber(value) {
      value = value || 0;
      return numeral(value).format("0,0.00");
    },
    getTotal(cp) {
      return this.formatNumber(_.sumBy(cp, (c) => parseFloat(c.TOTAL)));
    },
    getTitleGroup(group) {
      let text = "";
      if (group.type == 1 || group.type == 10 || group.type == 11) {
        text = "Código de Barras";
      }
      if (this.transferType.includes(group.type)) {
        text = "Dados Bancários";
      }
      return text;
    },
    /*
     *	gettextBank
     *	cria o texto de dados bancários da tabela dinamicamente
     *	usado unicamente interno a remittancepayable e repetido no codigo
     *	@param remittance_payable - divisor de categorias e elementos
     *	@param cp - dados do atual objeto a ser trabalhado
     */
    getTextBank(remittance_payable, cp) {
      let use_ncc = this.$session.get("use_ncc");
      let text = "";

      use_ncc = use_ncc === 1 && cp.E2_TIPO === "NCC";

      if (
        remittance_payable.type == 1 ||
        remittance_payable.type == 10 ||
        remittance_payable.type == 11
      ) {
        text = cp.E2_CODBAR;
      }
      if (this.transferType.includes(remittance_payable.type)) {
        if (!cp.show_alternative_bank_data) {
          if (cp[cp.banco] && cp[cp.agencia] && cp[cp.conta]) {
            text = `Bco: ${cp[cp.banco]}`;
            text += " - ";

            if (this.company_group.use_digag == 1) {
              text += `Ag: ${cp[cp.agencia]}${
                cp[cp.digag] ? `/${cp[cp.digag]}` : ""
              }`;
            } else {
              text += `Ag: ${cp[cp.agencia]}`;
            }

            text += " - ";
            if (this.company_group.use_digcta == 1) {
              text += `CC: ${cp[cp.conta]}${
                cp[cp.digcta] ? `/${cp[cp.digcta]}` : ""
              }`;
            } else {
              text += `CC: ${cp[cp.conta]}`;
            }

            if (
              remittance_payable.type === 5 ||
              remittance_payable.type === 6
            ) {
              text += this.formatCgc(cp.A2_TIPO, cp.A2_ZCGC);
              text += cp.A2_ZCGC ? ` - Nome: ${cp.A2_ZNOME}` : "";
            } else {
              text += this.formatCgc(cp.A2_TIPO, cp.A2_CGC);
            }
          }
        } else {
          text = `Bco: ${cp.a2_zsbanco}`;
          text += " - ";

          if (this.company_group.use_digag == 1) {
            text += `Ag: ${cp.a2_zsagenc}${
              cp.a2_zsdvage ? `/${cp.a2_zsdvage}` : ""
            }`;
          } else {
            text += `Ag: ${cp.a2_zsagenc}`;
          }

          text += " - ";
          if (this.company_group.use_digcta == 1) {
            text += `CC: ${cp.a2_zsnumco}${
              cp.a2_zsdvcta ? `/${cp.a2_zsdvcta}` : ""
            }`;
          } else {
            text += `CC: ${cp.a2_zsnumco}`;
          }

          if (remittance_payable.type === 5 || remittance_payable.type === 6) {
            text += this.formatCgc(cp.A2_TIPO, cp.a2_zscnpj);
            text += cp.a2_zscnpj ? ` - Nome: ${cp.a2_zsrazao}` : "";
          } else {
            text += this.formatCgc(cp.A2_TIPO, cp.a2_zscnpj);
          }
        }
      }

      return text;
    },
    formatCgc(A2_TIPO, A2_CGC) {
      let text = "";

      if (A2_CGC && A2_CGC.length === 14) {
        text += A2_CGC ? ` - CNPJ: ${this.formatCnpj(A2_CGC)}` : "";
      } else {
        text += A2_CGC ? ` - CPF: ${this.formatCpf(A2_CGC)}` : "";
      }

      return text;
    },
    formatCnpj(value) {
      value = value || "";
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
    formatCpf(value) {
      value = value || "";
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    showLimitAlert(remittanceLimit) {
      Swal.fire({
        title: "Limite máximo alcançado! ",
        html: `</br>A quantidade estabelecida pelo ERP é de ${remittanceLimit} itens por remessa`,
        width: "800px",
        confirmButtonText: "Ok",
        allowEscapeKey: true,
        confirmButtonColor: "rgb(52, 191, 163)",
        customClass: {
          confirmButton: "receipt-swal-btn",
        },
        focusConfirm: true,
        focusDeny: false,
        showCloseButton: true,
      }).then((result) => {
        if (
          result.isDismissed == true &&
          (result.dismiss == "backdrop" || result.dismiss == "esc")
        ) {
          return false;
        }
      });
    },
    checkIten(cp, remittancePayable, element) {
      let cpAux = null;
      let item = 0;
      let cancel = false;
      let check = document.querySelectorAll(
        "input:not(.check-box-header):checked"
      );
      if (element) {
        if (element.target.checked == true) {
          if (remittancePayable) {
            for (var i = 0; i < remittancePayable.elements.length; i++) {
              cpAux = remittancePayable.elements[i];
              if (
                check.length > this.remittanceLimit &&
                this.remittanceLimit > 0
              ) {
                cp.LIBERADO_REMESSA_PAGAR = false;
                this.showLimitAlert(this.remittanceLimit);
                cancel = true;
                element.target.checked = false;
                break;
              }
              if (!cpAux.E2_NUMBOR) {
                item++;
              }
            }
          }
        }
      }
      if (cancel == true) {
        return false;
      }

      $.ajax({
        method: "POST",
        url: `${this.$store.state.API_BASE}internaltable/savedata`,
        headers: {
          token: this.$session.get("token"),
        },
        data: {
          _identifyValue: "CP",
          _field: "liberado_remessa_pagar",
          _date: cp.E2_ZDTFLUX,
          _keyId: cp.key_id,
          _data: cp.LIBERADO_REMESSA_PAGAR ? 1 : 0,
        },
      })
        .done((result) => {
          showNotification(
            "Sucesso",
            "Dados alterados com sucesso!",
            "success"
          );
        })
        .fail((error) => {
          showNotification(
            "Atenção",
            "Oops! Ocorreu um erro ao salvar a liberação da remessa pagar!",
            "danger"
          );

          // Caso dê erro, voltar a liberação.
          cp.LIBERADO_REMESSA_PAGAR = !cp.LIBERADO_REMESSA_PAGAR;

          console.log(error);
        });
    },
    checkAllGroup(remittancePayable) {
      if (remittancePayable.checked == true) {
        remittancePayable.elements.forEach((cp) => {
          if (!cp.E2_NUMBOR) {
            cp.LIBERADO_REMESSA_PAGAR = false;
            this.checkIten(cp);
          }
        });
      }

      const newValue = !remittancePayable.checked;
      let cp = null;
      let item = 0;

      remittancePayable.checked = newValue;

      if (remittancePayable.checked && remittancePayable.elements) {
        for (var i = 0; i < remittancePayable.elements.length; i++) {
          cp = remittancePayable.elements[i];
          if (item >= this.remittanceLimit && this.remittanceLimit > 0) {
            this.showLimitAlert(this.remittanceLimit);
            break;
          }

          if (!cp.E2_NUMBOR) {
            item++;
          }
          if (
            remittancePayable.checked != cp.LIBERADO_REMESSA_PAGAR &&
            !cp.E2_NUMBOR
          ) {
            cp.LIBERADO_REMESSA_PAGAR = remittancePayable.checked;
            this.checkIten(cp);
          }
        }
      }
      this.$forceUpdate();
    },
    generateCnabAll() {
      const list = [];

      this.listRemittancePayable.forEach((remittancePayable) => {
        // if (!remittancePayable.code) return

        remittancePayable.elements.forEach((cp) => {
          if (cp.LIBERADO_REMESSA_PAGAR && !cp.E2_NUMBOR) {
            list.push(cp);
          }
        });
      });

      this.generateCnab(list);
    },
    generateCnabPaymentMethod(remittancePayable) {
      const list = [];

      remittancePayable.elements.forEach((cp) => {
        if (cp.LIBERADO_REMESSA_PAGAR && !cp.E2_NUMBOR) {
          list.push(cp);
        }
      });

      this.generateCnab(list);
    },

    generateCnab(list) {
      let totalSelected = 0;
      let countSelected = 0;
      const searchGroupId = 0;
      const dataDescriptions = [];
      const dataKeys = [];
      const dataIds = [];

      const companyId = $("#company_id").val();

      list.forEach((cp) => {
        cp.erp_key.origin = cp.origin;
        cp.erp_key.COMPANY_ID = companyId;

        dataKeys.push(JSON.stringify(cp.erp_key));
        dataIds.push(JSON.stringify(cp.key_id));

        dataDescriptions.push({
          description: cp.description,
          levelThreeDescription: this.getTextTitulo(cp),
          lvlDescription: "REMESSAPAG",
          value: numeral(cp.TOTAL).format("0,0.00"),
          origin: cp.origin,
          ID: cp.ID,
          cod_bar: cp.E2_CODBAR,
          payment_method: cp.payment_method,
          bank: {
            A2_BANCO: cp[cp.banco],
            A2_AGENCIA: cp[cp.agencia],
            A2_ZDIGAG: cp[cp.digag],
            A2_NUMCON: cp[cp.conta],
            A2_ZDIGCTA: cp[cp.digcta],
          },
        });
        totalSelected += parseFloat(cp.TOTAL);
        countSelected++;
      });

      this.$refs.GenerateCnab.openModal({
        totalSelected,
        cnabGroupId: searchGroupId,
        dataDescriptions,
        countSelected,
        companyId,
        dataKeys,
        dataIds,
        typeCnab: "CP",
        self: this,
        initialReferenceDate: this.date,
        finalReferenceDate: this.date,
      });
    },
    openView(cp) {
      this.$refs.ViewInformation.openModal({
        operation: "viewInformation",
        type: "CP",
        id: cp.ID,
        origin: cp.origin,
        date: cp.E2_ZDTFLUX,
      });
    },
    edit(remittance_payable, cp) {
      switch (remittance_payable.type) {
        case 1:
        case 10:
        case 11:
          this.$refs.UpdateInformation.openModal({
            operation: "updateCodBarras",
            type: "CP",
            id: cp.ID,
            date: cp.E2_ZDTFLUX,
            codBar: cp.E2_CODBAR,
            keyId: cp.erp_key,
            erp_table_code: cp.TABLE_COMPANY_CODE,
            company_token: cp.company_token,
            company_id: cp.company_id,
            branch_token: cp.branch_token,
            id_customer: cp.id_customer,
            payer_id_customer: cp.payer_id_customer,
            payer_company_id: cp.payer_company_id,
            source_table: cp.source_table,
            origin: cp.origin,
            cp,
            self: this,
            remittanceType: remittance_payable.type,
          });
          break;
        case 2:
        case 3:
        case 5:
        case 6:
          // se os dados forem TED ou DOC devem ser alterados a partir da conexao com remittancePayableController->listAll
          // quando os dados forem salvos ativa a funcao de cashflow.js updateBankData()
          this.$refs.UpdateInformation.openModal({
            operation: "updateBankData",
            type: "CP",
            A2_BANCO: cp[cp.banco],
            A2_AGENCIA: cp[cp.agencia],
            A2_NUMCON: cp[cp.conta],
            digag: cp[cp.digag],
            digcta: cp[cp.digcta],
            origin: cp.origin,
            id: cp.ID,
            keyId: cp.erp_key,
            erp_table_code: cp.TABLE_COMPANY_CODE,
            listRemittancePayable: this.listRemittancePayable,
            company_token: cp.company_token,
            company_id: cp.company_id,
            branch_token: cp.branch_token,
            id_customer: cp.id_customer,
            payer_id_customer: cp.payer_id_customer,
            payer_company_id: cp.payer_company_id,
            source_table: cp.source_table,
            cp,
            self: this,
            remittanceType: remittance_payable.type,
          });
          break;
        case 7:
          this.scrollTo = window.scrollY;
          this.selectedItem = cp;
          this.companyId = $("#company_id").val();
          this.showPixUpdate = true;
          break;
      }
    },
    updatePayment(cp) {
      this.$refs.UpdateInformation.openModal({
        operation: "updatePayment",
        type: "CP",
        id: cp.ID,
        keys: cp.erp_key,
        erp_table: "SE2",
        origin: cp.origin,
        company_token: cp.company_token,
        branch_token: cp.branch_token,
        id_customer: cp.id_customer,
        payer_id_customer: cp.payer_id_customer,
        payer_company_id: cp.payer_company_id,
        source_table: cp.source_table,
        erp_table: "SE2",
        erp_table_code: cp.TABLE_COMPANY_CODE,
        company_token: cp.company_token,
        company_id: cp.company_id,
        branch_token: cp.branch_token,
        source_table: cp.source_table,
        cp,
        listRemittancePayable: this.listRemittancePayable,
        self: this,
      });
    },
    getTotalRecordsGeral() {
      let total = 0;

      this.listRemittancePayable.forEach((item) => {
        total += item.elements.length;
      });

      return total;
    },
    getTotalRecordsSelecionado() {
      let total = 0;

      this.listRemittancePayable.forEach((item) => {
        item.elements.forEach((it) => {
          if (it.LIBERADO_REMESSA_PAGAR || it.E2_NUMBOR?.trim()) total++;
        });
      });

      return total;
    },
    getTotalGeral() {
      let total = 0;

      this.listRemittancePayable.forEach(
        (cp) => (total += _.sumBy(cp.elements, (c) => parseFloat(c.TOTAL)))
      );

      return this.formatNumber(total);
    },
    getTotalSelecionado() {
      let total = 0;

      this.listRemittancePayable.forEach(
        (cp) =>
          (total += _.sumBy(cp.elements, (c) =>
            c.LIBERADO_REMESSA_PAGAR || c.E2_NUMBOR?.trim()
              ? parseFloat(c.TOTAL)
              : 0
          ))
      );

      return this.formatNumber(total);
    },
    loadCompanyGroup() {
      var self = this;
      // blockPage('Carregando os dados...');

      $.ajax({
        method: "GET",
        url: `${
          self.$store.state.API_BASE
        }companygroup/listOne/${self.$session.get("company_group_id")}`,
        headers: {
          token: self.$session.get("token"),
        },
      })
        .done((result) => {
          self.company_group = result.data[0];
          // unblockPage();
        })
        .fail((error) => {
          console.log(error.responseText);
          // unblockPage();
        });
    },
    onClosePixModal(data) {
      this.selectedItem[this.selectedItem.conta] = data.account;
      this.selectedItem[this.selectedItem.tipcta] = data.accountType;
      this.selectedItem[this.selectedItem.agencia] = data.agency;
      this.selectedItem[this.selectedItem.banco] = data.code;
      this.selectedItem.A2_ZCHVPIX = data.key;
      this.selectedItem.E2_ZCHVPIX = data.qrcode;
      this.selectedItem.tipo_pix = data.type;
      this.selectedItem;
      this.showPixUpdate = false;
      this.selectedItem = null;
      this.companyId = null;
      setTimeout(() => {
        window.scrollTo({
          top: this.scrollTo,
          left: 0,
          behavior: "smooth",
        });
        this.scrollTo = 0;
      }, 200);
    },

    canGenerateAInvoice() {
      const list = this.listRemittancePayable.filter((payment) => {
        const titles = payment.elements.filter(
          (title) => title.LIBERADO_REMESSA_PAGAR && !title.E2_NUMBOR
        );

        if (titles.length > 0) return true;

        return false;
      });

      const hasOnePaymentFormValid = list.length === 1;

      if (!hasOnePaymentFormValid) return false;

      return list.some((payment) => {
        const onlyCheckedOnes = payment.elements.filter(
          (title) => title.LIBERADO_REMESSA_PAGAR && !title.E2_NUMBOR
        );

        if (onlyCheckedOnes.length > 1) {
          return true;
        }
      });
    },

    async prepareTitle() {
      Swal.fire({
        icon: "question",
        html: "<h3>Deseja gerar a fatura?</h3>",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          confirmButton: "receipt-swal-btn",
          cancelButton: "receipt-swal-btn",
        },
        cancelButtonColor: "rgb(48, 133, 214)",
        showCancelButton: true,
        allowOutsideClick: true,
      }).then(async ({ value }) => {
        if (value) {
          try {
            const paymentList = this.listRemittancePayable;
            const paymentsCategories = paymentList.reduce(
              (filtered, payment) => {
                const titles = payment.elements.filter(
                  (title) => title.LIBERADO_REMESSA_PAGAR && !title.E2_NUMBOR
                );

                if (titles.length > 1) {
                  filtered.push({
                    titles: titles,
                    code: payment.code,
                    description: payment.description,
                    type: payment.type,
                    company: $("#company_id").val(),
                    provider: this.provider,
                  });
                }

                return filtered;
              },
              []
            );

            if (!this.isSameProvider(paymentsCategories)) {
              this.selectProvider(paymentsCategories);

              return;
            }

            this.generateInvoice(paymentsCategories);
          } catch (error) {
            showNotification(
              "Atenção",
              "Oops! Ocorreu um erro ao gerar a fatura.",
              "danger"
            );
          } finally {
            unblockPage();
          }
        }
      });
    },
    async deleteInvoice(cp) {
      try {
        Swal.fire({
          icon: "question",
          html: "<h3>Deseja excluir a fatura?</h3>",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "receipt-swal-btn",
            cancelButton: "receipt-swal-btn",
          },
          cancelButtonColor: "rgb(48, 133, 214)",
          showCancelButton: true,
          allowOutsideClick: true,
        }).then(async ({ value }) => {
          if (value) {
            blockPage("Excluindo Fatura....");
            const artificialInvoicesService = new ArtificialInvoices();

            await artificialInvoicesService.delete(cp.ID);

            this.load();
          }
        });
      } catch (error) {
        showNotification(
          "Atenção",
          "Oops! Ocorreu um erro ao excluir a fatura.",
          "danger"
        );
      }
    },

    selectProvider(payments) {
      this.scrollTo = window.scrollY;
      this.showProviderSelect = true;
      this.paymentMethodsSelected = payments;
      this.companyId = $("#company_id").val();
    },

    isSameProvider(payments) {
      return payments.every((payment) => {
        const titles = payment.titles.filter(
          (title) => title.LIBERADO_REMESSA_PAGAR && !title.E2_NUMBOR
        );

        const isSameProvider = titles.every((title, index) => {
          if (titles[index + 1]) {
            return title.A2_CGC === titles[index + 1].A2_CGC;
          }

          return true;
        });

        return isSameProvider;
      });
    },

    async excludeFromRemittance(accountPayable) {
      if (!accountPayable.cnab_remi_generated_id) {
        showNotification(
          "Atenção",
          "Título em borderô pelo ERP, não pode ser removido pelo inncash.",
          "warning"
        );

        return;
      }

      try {
        const { value } = await Swal.fire({
          icon: "question",
          html: "<h3>Remover título do borderô?</h3>",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "receipt-swal-btn",
            cancelButton: "receipt-swal-btn",
          },
          cancelButtonColor: "rgb(48, 133, 214)",
          showCancelButton: true,
          allowOutsideClick: true,
        });

        if (value) {
          blockPage("Removendo o título de borderô...");
          const remittanceRepository = new RemittanceRepository();
          const type =
            accountPayable.origin === "ERP"
              ? AccountType.PAYABLE
              : AccountType.INVOICE;
          const id =
            accountPayable.origin === "ERP"
              ? accountPayable.id_customer
              : accountPayable.ID;

          await remittanceRepository.excludeFromRemittance(
            this.companyGroupId,
            accountPayable.cnab_remi_generated_id,
            id,
            type
          );
        }
      } catch (error) {
        showNotification(
          "Atenção",
          "Oops! Ocorreu um erro ao excluir o título do bordero.",
          "danger"
        );
      } finally {
        unblockPage();
        this.loadRemittance();
      }
    },
    async generateInvoice(paymentsCategories) {
      try {
        blockPage("Gerando fatura...");
        const artificialInvoicesService = new ArtificialInvoices();
        await artificialInvoicesService.store(paymentsCategories);
        this.loadRemittance();
      } catch (error) {
        showNotification(
          "Atenção",
          "Oops! Ocorreu um erro ao gerar a fatura.",
          "danger"
        );
      } finally {
        unblockPage();
      }
    },
  },
};
</script>

<style scoped>
.zebra {
  background: #f0f4f7;
}
.receipt-swal-btn {
  min-width: 200px;
  /*text-transform: capitalize;*/
}

.m-checkbox > span:after {
  margin-left: -1px;
  margin-top: -5px;
  width: 4px;
  height: 9px;
}

.vue-popover {
  font-size: 16px;
  width: auto !important;
  color: black;
}

.checkbox-area {
  padding-top: 6px;
  padding-bottom: 6px;
  color: black;
}

.remittance-checkbox-label-disabled {
  margin-bottom: 0px;
  font-size: 14px;
  color: grey;
}

.remittance-checkbox-label {
  margin-bottom: 0px;
  font-size: 14px;
  color: black;
}
</style>