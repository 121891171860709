import BillingPendencyGroupTypeEnum from '@/views/billingPendencies/enums/BillingPendencyGroupTypeEnum';
import Operator from '@/domain/models/Operator';

export default class BillingPendency {
  constructor(
    public id: number | string,
    public name: string,
    public overdues: number,
    public daysOverdue: number,
    public totalOverdue: number,
    public totalDue: number,
    public totalOverdueOverall: number,
    public totalDueOverall: number,
    public origin: BillingPendencyGroupTypeEnum,
    public operator?: Operator,
    public aprovedAt?: string,
  ) {}

  static make(data: any): BillingPendency {
    const operator = data.operator
      ? Operator.make(data.operator)
      : undefined;

    const definedOrigin = (origin: string) => {
      switch (origin) {
        case BillingPendencyGroupTypeEnum.CUSTOMER: return BillingPendencyGroupTypeEnum.CUSTOMER;
        case BillingPendencyGroupTypeEnum.DOCUMENT: return BillingPendencyGroupTypeEnum.DOCUMENT;
        case BillingPendencyGroupTypeEnum.ECONOMIC_GROUP: return BillingPendencyGroupTypeEnum
          .ECONOMIC_GROUP;
        default: return BillingPendencyGroupTypeEnum.ECONOMIC_GROUP;
      }
    };

    return new BillingPendency(
      data.id ?? 0,
      data.name ?? '',
      data.overdues ?? 0,
      data.days_overdue ?? 0,
      data.total_overdue ?? 0,
      data.total_due ?? 0,
      data.total_overdue_overall ?? 0,
      data.total_due_overall ?? 0,
      definedOrigin(data.origin),
      operator,
      data.aproved_at,
    );
  }
}
