






















































































































































































































































import {
  Component,
  Emit,
  Prop,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { debounce } from 'lodash';
import { getModule } from 'vuex-module-decorators';

import StatusIcon from '@/components/tables/StatusIcon.vue';
import { toCurrency } from '@/utils/';
import IVDataTableHeader from '@/types/IVDataTableHeader';

import PaymentRemittanceModule from '@/stores/modules/PaymentRemittanceModule';

import PaymentRemittance from '@/domain/models/PaymentRemittance';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';

import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import Status from '@/domain/enums/PaymentRemittancesStatusType';
import Type from '@/domain/enums/PaymentRemittancesType';

import { formateDate } from '@/utils/date';
import { OpenActionData } from '../utils/interfaces';
import {
  formatPaymentData,
  formatTitle,
  isStatusToApproveOrReprove,
  isStatusToGenerateRemittance,
  makeBalance,
  statusToApproveOrReprove,
} from '../utils';

import ToggleButton from './ToggleButton.vue';
import MenuHeader from './ListMenuHeader.vue';
import MenuRow from './ListMenuRow.vue';

@Component({
  components: {
    ToggleButton,
    StatusIcon,
    MenuHeader,
    MenuRow,
  },
})
export default class PaymentRemittancesList extends Vue {
  @Ref('sticky') sticky!: Vue;

  @Prop({
    type: Array as () => Array<IVDataTableHeader>,
    required: true,
  }) headers!: Array<IVDataTableHeader>;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
    required: true,
  }) items!: Array<PaymentRemittance>;

  @Prop({
    type: Function,
    required: true,
  }) input!: Function;

  @Prop({
    type: String,
    required: true,
  }) title!: string;

  @Prop({
    type: Object as () => CompanyGroupConfig,
    required: true,
  }) groupConfig!: CompanyGroupConfig;

  @Prop(String) name!: string;
  @Prop(String) type!: string;

  @Watch('type') onChangeType(type: Type) {
    this.expand = type == Type.ANALYTICAL;
  }

  @Watch('items', { deep: true, immediate: true }) onChangeItems() {
    this.selecteds = [];
    this.disableds = this.items
      .reduce((prev, item) => (
        this.isItemDisabled(item) ? prev + 1 : prev
      ), 0);
  }

  @Emit()
  action(data: OpenActionData) {
    return data;
  }

  readonly statusEnum = Status;
  readonly formatCurrency = toCurrency;
  readonly formatDate = formateDate;
  readonly formatTitle = formatTitle;
  readonly isStatusToApproveOrReprove = isStatusToApproveOrReprove;
  readonly makeBalance = makeBalance;

  private readonly paymentRemittanceModule
    : PaymentRemittanceModule = getModule(PaymentRemittanceModule);

  expand: boolean = this.type == Type.ANALYTICAL;

  selecteds: Array<PaymentRemittance> = [];
  disableds: number = 0;

  currencyColumns = ['total', 'sent', 'approved', 'decrease', 'increase', 'discount', 'fees'];

  isSticky: boolean = false;

  statusList = [
    {
      code: Status.NOT_SENT,
      text: 'Não enviado',
      icon: 'mdi-arrow-top-right-thin-circle-outline',
      color: 'info',
    },
    {
      code: Status.PENDING,
      text: 'Aguardando aprovação',
      icon: 'mdi-clock-check-outline',
      color: 'warning',
    },
    {
      code: Status.APPROVED,
      text: 'Aprovado',
      icon: 'mdi-check-circle-outline',
      color: 'success',
    },
    {
      code: Status.PARTIALLY_APPROVED,
      text: 'Aprovado Parcialmente',
      icon: 'mdi-clock-check-outline',
      color: 'success',
    },
    {
      code: Status.DISAPPROVED,
      text: 'Reprovado',
      icon: 'mdi-close-circle-outline',
      color: 'error',
    },
    {
      code: Status.SENT,
      text: 'Enviado',
      icon: 'mdi-arrow-top-right-thin-circle-outline',
      color: 'success',
    },
  ];

  get generate(): boolean {
    return this.canGenerateRemittanceOrInvoice();
  }

  get invoice(): boolean {
    return Boolean(this.$session.get('user_access-generate_invoice')) && this.generate;
  }

  get approve(): boolean {
    return Boolean(this.$session.get('user_access-approve_or_reprove_remittance'))
      && this.allSelectedHasSameStatus(statusToApproveOrReprove);
  }

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + this.makeBalance(item), 0);
  }

  get sent(): number {
    return this.items.reduce((acc, item) => {
      if (isStatusToApproveOrReprove(item.status)) return acc + this.makeBalance(item);
      return acc;
    }, 0);
  }

  get approved(): number {
    return this.items
      .reduce((acc, item) => {
        if (item.status === Status.APPROVED) {
          return acc + this.makeBalance(item);
        }

        return acc;
      }, 0);
  }

  public created(): void {
    this.setStoredTableGroupsSelectionByLoggedUser();
  }

  mounted() {
    const scroll = debounce(() => {
      if (
        this.sticky
          .$el.querySelector('.v-data-table__wrapper')
          ?.classList.contains('sticky-header')
      ) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    }, 50);

    document.addEventListener('scroll', scroll);
  }

  sum(value: string): string {
    let sum = 0;

    switch (value) {
      case 'total':
        sum = this.total;
        break;
      case 'sent':
        sum = this.sent;
        break;
      case 'approved':
        sum = this.approved;
        break;
      default:
        break;
    }

    return this.formatCurrency(sum);
  }

  toggle() {
    const isSelectingAll = this.selecteds.length !== this.items.length - this.disableds;

    if (this.selecteds.length !== this.items.length - this.disableds) {
      this.selecteds = this.items
        .filter((item) => (!this.isItemDisabled(item)));
    } else {
      this.selecteds = [];
    }

    this.paymentRemittanceModule.setSelectedAllItensOnSpecificTableGroup({
      isSelectingAll,
      itemsId: this.selecteds.map(({ id }) => id),
      tableKey: this.title,
    });
  }

  isItemDisabled(item: PaymentRemittance): boolean {
    const disabledStatus = [Status.APPROVED, Status.SENT];

    return disabledStatus.includes(item.status) || Boolean(item.bordero_number);
  }

  allSelectedHasSameStatus(status: Array<Status>) {
    if (!this.selecteds.length) return false;
    return this.selecteds.every((selected) => status.includes(selected.status));
  }

  canGenerateRemittanceOrInvoice(): boolean {
    if (!this.selecteds.length) return false;
    return this.selecteds.every((selected) => (
      // selected.released_remittance_payble
      !selected.cnab_remi_generated_id
      && isStatusToGenerateRemittance(selected.status)
    ));
  }

  statusColor(status: Status) {
    switch (status) {
      case Status.NOT_SENT:
        return 'info';
      case Status.SENT:
        return 'success';
      case Status.PARTIALLY_APPROVED:
        return 'success';
      case Status.PENDING:
        return 'warning';
      case Status.APPROVED:
        return 'success';
      case Status.DISAPPROVED:
        return 'error';
      default:
        return 'default';
    }
  }

  toggleExpand() {
    this.expand = !this.expand;
  }

  public setStoredTableGroupsSelectionByLoggedUser(): void {
    const { getTableGroupsByLoggedUser: tableGroupsByLoggedUser } = this.paymentRemittanceModule;

    if (!tableGroupsByLoggedUser || !tableGroupsByLoggedUser.selectedItens[this.title]) {
      return;
    }

    const { selectedIds } = tableGroupsByLoggedUser.selectedItens[this.title];

    for (let i = 0; i < selectedIds.length; i += 1) {
      const id = selectedIds[i];

      const completeItem = this.items
        .find(({ id: paymentRemittanceId }) => paymentRemittanceId === id);

      if (completeItem && !this.isItemDisabled(completeItem)) {
        this.selecteds.push(completeItem);
      }
    }

    const onlySelectedIds = this.selecteds.map(({ id }) => id);
    this.syncActualSelectedPaymentRemittancesWithLocalStorage(onlySelectedIds);
    this.input(this.selecteds, this.title);
  }

  public syncActualSelectedPaymentRemittancesWithLocalStorage(ids: number[]): void {
    const { selectedIds } = this.paymentRemittanceModule
      .getTableGroupsByLoggedUser!.selectedItens[this.title];

    const differenceIds = selectedIds.filter((id) => !ids.includes(id));

    for (let i = 0; i < differenceIds.length; i += 1) {
      const id = differenceIds[i];

      this.paymentRemittanceModule.unsetSingularItemOnSpecificTableGroup({
        itemId: id,
        tableKey: this.title,
      });
    }

    const validItemsLength = this.items
      .filter((item) => !this.isItemDisabled(item))
      .length;

    const { selectedIds: newSelectedIds } = this.paymentRemittanceModule
      .getTableGroupsByLoggedUser!.selectedItens[this.title];

    if (validItemsLength > newSelectedIds.length) {
      this.paymentRemittanceModule.setIsSelectedAllItensToFalse(this.title);
    }
  }

  handleClickAction(type: ActionType) {
    this.action({ type, items: this.selecteds });
  }

  public handleSelectSingularItem(
    item: PaymentRemittance,
    select: Function,
    isSelected: Function,
  ): void {
    const syncSingularItemOnTableGroupParams = {
      itemId: item.id,
      tableKey: this.title,
    };

    if (!isSelected(item)) {
      this.paymentRemittanceModule
        .setSingularItemOnSpecificTableGroup(syncSingularItemOnTableGroupParams);
    } else {
      this.paymentRemittanceModule
        .unsetSingularItemOnSpecificTableGroup(syncSingularItemOnTableGroupParams);
    }

    select(item, !isSelected(item));
  }

  openComment(item: PaymentRemittance) {
    this.action({
      type: ActionType.ADD_COMMENT,
      items: [item],
    });
  }

  formatPaymentDataToHTML(item: PaymentRemittance) {
    const data = formatPaymentData(item, this.groupConfig);
    let firstJump = 1;
    if (
      item.form_of_payment_type === PaymentMethodType.GPS
      || item.form_of_payment_type === PaymentMethodType.DARF
    ) firstJump = 0;

    return Object
      .keys(data)
      .reduce((prev, key, index) => {
        if (key === 'barcode') {
          prev += data[key];
        } else {
          prev += `${key}: ${data[key]} ${index >= firstJump ? '<br>' : ''}`;
        }
        return prev;
      }, '');
  }
}
