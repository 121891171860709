



































































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { toCurrency } from '@/utils/';
import { formatErrorForNotification } from '@/utils/error';

import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatTitle, groupByRemittance, hideDuplicateOverflow } from '../../utils';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionDisapprove extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Prop({
    type: String,
  }) name!: string;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.DISAPPROVE,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen(value: boolean) {
    if (!value) {
      this.comment = '';
      this.form.resetValidation();
    }
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly formatCurrency = toCurrency;
  readonly formatTitle = formatTitle;
  readonly required = (v: string) => !!v || 'Campo obrigatório!';
  comment: string = '';

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + item.total, 0);
  }

  async save() {
    const isValidform = this.form.validate();
    if (!isValidform) return;

    try {
      this.$dialog.startLoading();

      const groups = groupByRemittance(this.items);
      const groupKeys = Object.keys(groups);

      const responses = await Promise.allSettled(
        groupKeys.map((remi) => this.handleDisapprovals(remi, groups[+remi], this.comment)),
      );

      const someReponseHasRejected = responses
        .some((response) => response.status === 'rejected');

      if (someReponseHasRejected) {
        let errors = 0;
        const remittances = responses
          .reduce((prev, response, index) => {
            if (response.status === 'rejected') {
              errors += 1;

              if (prev.length === 0) {
                prev = groupKeys[index];
              } else {
                prev += index + 1 !== responses.length
                  ? `, ${groupKeys[index]}`
                  : `e ${groupKeys[index]}`;
              }
            }
            return prev;
          }, '');

        const message = errors > 1
          ? 'Ops, ocorreu algum erro nas remessas'
          : 'Ops, ocorreu um erro na remessa';

        this.$notification.error(`${message}: ${remittances}.`);
      }

      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      hideDuplicateOverflow(this);
    }
  }

  async handleDisapprovals(remi: string, titles: Array<string>, comment?: string) {
    const group = this.$session.get('company_group_id');

    const response = await this.paymentRemittanceRepository
      .disapprovals(group, +remi, this.companies, titles, comment);

    return response;
  }
}
