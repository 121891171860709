import BillingRemittanceAccountReceivableSituationType from '@/domain/enums/BillingRemittanceAccountReceivableSituationType';

export default class BillingRemittanceAccountReceivable {
  constructor(
    public balanceValue: number,
    public bankCode: string,
    public bankingSituation: number,
    public bankingSituationDescription: BillingRemittanceAccountReceivableSituationType,
    public barCode: string,
    public billingMethodType: string,
    public borderoNumber: string,
    public branchCode: string,
    public clientCode: string,
    public clientPaymentMethod: string,
    public companyCode: string,
    public companyId: number,
    public companyName: string,
    public cnab_remi_generated_id: number,
    public documentNumber: string,
    public documentPaymentMethod: string,
    public documentType: string,
    public documentUrl: string,
    public fantasyName: string,
    public fluxDate: string,
    public id: number,
    public idCustomer: string,
    public isLiberated: boolean,
    public keyId: string,
    public occurrencesTotal: number,
    public origin: string,
    public ourNumber: string,
    public prefixCode: string,
    public quoteNumber: string,
    public registrationNumber: string,
    public sequentialNumber: string,
    public storeCode: string,
    public invoiceGenerator: string,
    public transationType: string,
  ) { }

  static make(data: any): BillingRemittanceAccountReceivable {
    const isLiberated = data.isLiberated === 'true';
    return new BillingRemittanceAccountReceivable(
      data.balanceValue,
      data.bankCode,
      data.bankingSituation,
      data.bankingSituationDescription,
      data.barCode,
      data.billingMethodType,
      data.borderoNumber,
      data.branchCode,
      data.clientCode,
      data.clientPaymentMethod,
      data.companyCode,
      data.companyId,
      data.companyName,
      data.cnab_remi_generated_id,
      data.documentNumber,
      data.documentPaymentMethod,
      data.documentType,
      data.documentUrl,
      data.fantasyName,
      data.fluxDate,
      data.id,
      data.idCustomer,
      isLiberated,
      data.keyId,
      data.occurrencesTotal,
      data.origin,
      data.ourNumber,
      data.prefixCode,
      data.quoteNumber,
      data.registrationNumber,
      data.sequentialNumber,
      data.storeCode,
      data.invoice_generator,
      data.transationType,
    );
  }

  bankingSituationIsSuccess(): boolean {
    return this.bankingSituation === 1;
  }

  bankingSituationIsSuccessWithOccurrences(): boolean {
    return this.bankingSituation === 2;
  }
}
