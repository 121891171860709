var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('billing-pendency-list-filter',{directives:[{name:"show",rawName:"v-show",value:(_vm.store.state.filter),expression:"store.state.filter"}],ref:"filter",on:{"validate":_vm.filter,"set-columns":_vm.setColumns}}),_c('v-data-table',{attrs:{"headers":_vm.headersActive,"items":_vm.billingPendencies,"loading":_vm.loading,"show-select":_vm.store.state.manager && !_vm.isMobile,"item-class":_vm.rowClasses,"loading-text":"Carregando pendências de cobrança...","no-data-text":"Nenhuma pendência de cobrança encontrada."},on:{"click:row":_vm.goToPendencies},scopedSlots:_vm._u([{key:"header.id",fn:function(){return [_c('dynamic-column-menu',{attrs:{"group-enum":_vm.groupFilterParametersEnum.BILLING_PENDENCY_LIST,"correlation-between-keys-and-headers":_vm.headerKeys,"small":""},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),(_vm.store.state.manager)?_c('billing-pendency-list-menu',{on:{"click":_vm.action}}):_vm._e()]},proxy:true},{key:"item.totalOverdue",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.totalDue",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.totalOverdueOverall",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.totalDueOverall",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.aprovedAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value && _vm.formatDate(value))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.store.state.manager)?_c('billing-pendency-list-menu',{attrs:{"item":item},on:{"click":_vm.action}}):_vm._e()]}}]),model:{value:(_vm.billingPendenciesSelecteds),callback:function ($$v) {_vm.billingPendenciesSelecteds=$$v},expression:"billingPendenciesSelecteds"}}),_c('billing-pendency-transfer-dialog',{attrs:{"pendencies":_vm.billingPendenciesToTransfer},on:{"close":_vm.close},model:{value:(_vm.openDialog.TRANSFER),callback:function ($$v) {_vm.$set(_vm.openDialog, "TRANSFER", $$v)},expression:"openDialog.TRANSFER"}}),_c('billing-pendency-history-dialog',{attrs:{"pendency":_vm.billingPendenciesToHistory},on:{"close":_vm.close},model:{value:(_vm.openDialog.HISTORY),callback:function ($$v) {_vm.$set(_vm.openDialog, "HISTORY", $$v)},expression:"openDialog.HISTORY"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }