var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider'),_c('v-card-text',{staticClass:"p-0 mt-6"},[(Object.keys(_vm.items).length > 0)?_c('v-row',{staticClass:"mb-2",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"2","md":"6","sm":"6","xs":"12"}},[_c('v-btn',{staticClass:"font-weight-medium",attrs:{"color":"info","depressed":"","block":"","large":"","dark":""},on:{"click":_vm.handleSendAllSelecteds}},[_vm._v(" GERAR REMESSA ")])],1)],1):_vm._e(),_vm._l((Object.entries(_vm.items)),function(ref,index){
var key = ref[0];
var list = ref[1];
return _c('v-data-table',{directives:[{name:"sticky",rawName:"v-sticky",value:(list.length > 9 ? list : []),expression:"list.length > 9 ? list : []"}],key:key,staticClass:"pb-10",class:{ 'mt-6': !!index },attrs:{"headers":_vm.headers,"items":list,"item-key":"uid","hide-default-footer":"","hide-default-header":"","disable-pagination":"","disable-filtering":"","show-select":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var ref_on = ref.on;
        var sort = ref_on.sort;
        var toggleDefault = ref_on['toggle-select-all'];
        var ref_props = ref.props;
        var propsHeaders = ref_props.headers;
        var ref_props_options = ref_props.options;
        var sortBy = ref_props_options.sortBy;
        var sortDesc = ref_props_options.sortDesc;
        var someItems = ref_props.someItems;
        var everyItem = ref_props.everyItem;
return [_c('thead',{staticClass:"v-data-table-header table-instructions-remittance"},[_c('tr',{attrs:{"role":"columnheader","scope":"col"}},_vm._l((propsHeaders),function(ref){
        var value = ref.value;
        var text = ref.text;
        var sortable = ref.sortable;
        var align = ref.align;
return _c('sticky-cell',{key:value,class:[
                sortable ? 'custom-sortable': '',
                align ? ("text-" + align) : 'text-start',
                align ? ("justify-" + align) : 'justify-start' ],on:{"click":function($event){sortable && sort(value)}}},[(value === 'data-table-select')?_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"indeterminate":someItems,"value":everyItem,"ripple":false,"small":""},on:{"input":function($event){return _vm.toggleSelecteds(key)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Selecionar todos")])])],1):(value === 'options')?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleSendSelecteds(key)}}},[_c('v-list-item-title',[_vm._v(" Gerar Remessa ")])],1)],1)],1)],1):(value === 'title')?_c('div',[_vm._v(" "+_vm._s(key)+" ")]):_c('div',{class:[
                  'd-flex',
                  'align-center',
                  sortBy[0] == value ? 'active' : '',
                  align ? ("text-" + align) : 'text-center',
                  align ? ("justify-" + align) : 'justify-start' ]},[_c('div',[(value === 'balance')?_c('span',[_vm._v(" "+_vm._s(_vm.itemsSummary[key] && _vm.formatCurrency(_vm.itemsSummary[key].total))+" ")]):_c('span',[_vm._v(" "+_vm._s(text)+" ")])]),(sortable)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")]):_vm._e()],1)])}),1)])]}},{key:"item.data-table-select",fn:function(ref){
        var item = ref.item;
        var select = ref.select;
        var isSelected = ref.isSelected;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":_vm.isItemDisabled(item),"disabled":_vm.isItemDisabled(item),"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTitle(item))+" ")]}},{key:"item.balance",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.field",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.translateField(value))+" ")]}},{key:"item.action",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.translateInstruction(value))+" ")]}},{key:"item.oldValue",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatFieldValue(value, item))+" ")]}},{key:"item.newValue",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatFieldValue(value, item))+" ")]}},{key:"item.status",fn:function(ref){
        var value = ref.value;
return [_c('status-icon',{attrs:{"code":value,"list":_vm.statusList}})]}},{key:"item.options",fn:function(ref){
        var item = ref.item;
return [_c('list-menu',{attrs:{"item":item},on:{"action":_vm.action}})]}}],null,true),model:{value:(_vm.selecteds),callback:function ($$v) {_vm.selecteds=$$v},expression:"selecteds"}})}),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Títulos")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v("Total Geral")]),_c('td',[_vm._v(_vm._s(_vm.itemsTotal))]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.itemsTotalValue)))])]),_c('tr',[_c('th',[_vm._v("Total Selecionados")]),_c('td',[_vm._v(_vm._s(_vm.selectedTotal))]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.selectedTotalValue)))])])])]},proxy:true}])})],2),_c('change-instruction',{attrs:{"open":_vm.open[_vm.Action.CHANGE_INSTRUCTION],"items":_vm.itemsToAction,"company":_vm.data.company_id},on:{"close":_vm.close}}),_c('view-occurrence',{attrs:{"open":_vm.open[_vm.Action.VIEW_OCCURRENCE],"items":_vm.itemsToAction},on:{"close":_vm.close}}),_c('view-details',{attrs:{"open":_vm.open[_vm.Action.VIEW_DETAILS],"items":_vm.itemsToAction},on:{"close":_vm.close}}),_c('send',{attrs:{"open":_vm.open[_vm.Action.SEND],"items":_vm.itemsToAction,"company":_vm.data.company_id},on:{"close":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }