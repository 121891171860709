import {
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/stores';
import ITableGroupsByUser from '@/stores/interfaces/ITableGroupsByUser';
import ISyncSingularListItemWithLocalStorage from '@/views/paymentRemittances/interfaces/ISyncSingularListItemWithLocalStorage';
import ISetSelectedAllListItemsWithLocalStorage from '@/views/paymentRemittances/interfaces/ISetSelectedAllListItemsWithLocalStorage';

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: 'payment-remittance',
  preserveState: localStorage.getItem('payment-remittance') !== null,
})
export default class PaymentRemittanceModule extends VuexModule {
  public hasChangesInGroupPaymentFilters: boolean = false;

  public selectedGroupPaymentFilters: string[] = [];

  public actualUserId: number|null = null;

  public tableGroupsByUsers: ITableGroupsByUser[] = [];

  public get getTableGroupsByLoggedUser(): ITableGroupsByUser|undefined {
    return this.tableGroupsByUsers.find(({ userId }) => userId === this.actualUserId!);
  }

  @Mutation
  public setActualUserId(userId: number): void {
    this.actualUserId = userId;
  }

  @Mutation
  public setSingularItemOnSpecificTableGroup(
    params: ISyncSingularListItemWithLocalStorage,
  ): void {
    let tableGroupsByUserIndex = this.tableGroupsByUsers
      .findIndex(({ userId }) => this.actualUserId === userId);

    if (tableGroupsByUserIndex < 0) {
      tableGroupsByUserIndex = (
        this.tableGroupsByUsers.push({
          userId: this.actualUserId!,
          selectedItens: {},
        })
      ) - 1;
    }

    if (!this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey]) {
      this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey] = {
        isSelectingAll: false,
        selectedIds: [params.itemId],
      };

      return;
    }

    this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey]
      .isSelectingAll = false;
    this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey].selectedIds
      .push(params.itemId);
  }

  @Mutation
  public unsetSingularItemOnSpecificTableGroup(
    params: ISyncSingularListItemWithLocalStorage,
  ): void {
    const tableGroupsByUserIndex = this.tableGroupsByUsers
      .findIndex(({ userId }) => this.actualUserId === userId);

    if (tableGroupsByUserIndex < 0) {
      return;
    }

    if (!this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey]) {
      return;
    }

    this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey]
      .isSelectingAll = false;
    this.tableGroupsByUsers[tableGroupsByUserIndex]
      .selectedItens[params.tableKey].selectedIds = this.tableGroupsByUsers[tableGroupsByUserIndex]
        .selectedItens[params.tableKey].selectedIds
          .filter((id) => id !== params.itemId);

    if (
      this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey]
        .selectedIds.length < 1
    ) {
      delete this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey];
    }
  }

  @Mutation
  public setSelectedAllItensOnSpecificTableGroup(
    params: ISetSelectedAllListItemsWithLocalStorage,
  ): void {
    let tableGroupsByUserIndex = this.tableGroupsByUsers
      .findIndex(({ userId }) => this.actualUserId === userId);

    if (tableGroupsByUserIndex < 0) {
      tableGroupsByUserIndex = (
        this.tableGroupsByUsers.push({
          userId: this.actualUserId!,
          selectedItens: {},
        })
      ) - 1;
    }

    if (!params.isSelectingAll) {
      delete this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey];

      return;
    }

    this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[params.tableKey] = {
      isSelectingAll: params.isSelectingAll,
      selectedIds: params.itemsId,
    };
  }

  @Mutation
  public setIsSelectedAllItensToFalse(tableKey: string): void {
    const tableGroupsByUserIndex = this.tableGroupsByUsers
      .findIndex(({ userId }) => this.actualUserId === userId);

    this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens[tableKey].isSelectingAll = false;
  }

  @Mutation
  public setSelectedGroupPaymentFilters(selectedGroupPaymentFilters: string[]): void {
    this.selectedGroupPaymentFilters = selectedGroupPaymentFilters;
  }

  @Mutation
  public setSelectedGroupPaymentFiltersChange(selectedGroupPaymentFilters: string[]): void {
    if (selectedGroupPaymentFilters.length !== this.selectedGroupPaymentFilters.length) {
      this.hasChangesInGroupPaymentFilters = true;
      this.selectedGroupPaymentFilters = selectedGroupPaymentFilters;
    }
  }

  @Mutation
  public resetIfHasChangedGroupingFilters(): void {
    const tableGroupsByUserIndex = this.tableGroupsByUsers
      .findIndex(({ userId }) => this.actualUserId === userId);

    if (tableGroupsByUserIndex < 0) {
      return;
    }

    if (this.hasChangesInGroupPaymentFilters) {
      this.hasChangesInGroupPaymentFilters = false;
      this.tableGroupsByUsers[tableGroupsByUserIndex].selectedItens = {};
    }
  }
}
