var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.ActionType.OCCURRENCES, _vm.item)}}},[_c('v-list-item-title',[_vm._v(" Visualizar Ocorrências ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.ActionType.DETAILS, _vm.item)}}},[_c('v-list-item-title',[_vm._v(" Visualizar Detalhes ")])],1),(_vm.item.documentUrl != null)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.ActionType.PAYMENT_SLIP, _vm.item)}}},[_c('v-list-item-title',[_vm._v(" Visualizar Boleto ")])],1):_vm._e(),(_vm.item.borderoNumber)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.ActionType.REMOVE, _vm.item)}}},[_c('v-list-item-title',[_vm._v(" Remover do Borderô ")])],1):_vm._e(),(
        _vm.canClearBlock &&
        (_vm.item.bankingSituationIsSuccess() ||
          _vm.item.bankingSituationIsSuccessWithOccurrences())
      )?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.ActionType.CLEAR_BANK_SLIP_DATA, _vm.item)}}},[_c('v-list-item-title',[_vm._v(" Remover Bloqueio ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }