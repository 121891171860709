














































































































































import {
  Vue,
  Ref,
  Component,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  dateNow,
  formatToSpecificFormat,
  getEndOf,
  getStartOf,
} from '@/utils/date';
import HistoryUpsertDialog from '@/components/timeline/HistoryUpsertDialog.vue';
import ConcludeEventDialog from '@/components/timeline/ConcludeEventDialog.vue';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import EventMenu from '@/views/schedule/components/EventMenu.vue';
import ScheduleSummary from '@/views/schedule/components/ScheduleSummary.vue';
import EventsRepository from '@/repositories/EventsRepository';
import VCalendarType from '@/types/VCalendarType';
import ScheduleEventsType from '@/views/schedule/types/ScheduleEventsType';
import BillingHistory from '@/domain/models/BillingHistory';
import BillingHistorySupport from '@/components/timeline/support/HistoryTimelineSuport';
import RangeDate from '@/types/RangeDate';

@Component({
  components: {
    HistoryUpsertDialog,
    EventMenu,
    ScheduleSummary,
    ConcludeEventDialog,
  },
})
export default class Schedule extends Vue {
  private readonly eventsRepository: EventsRepository = new EventsRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = true;
  public callUpsertDialog: boolean = false;
  public callConcludeDialog: boolean = false;
  public scheduleSummaryShow: boolean = true;
  public eventMenuShow: boolean = false;
  public showTitle: boolean = false;

  public calendarValue: string = '';
  public selectedStatus: string = '';
  public viewType: string = 'week';
  public selectedConcludeEventStatus: string = '';

  public selectedEvent: BillingHistory|null = null;
  public selectedElement: Object|null = null;

  public billingHistories: BillingHistory[] = [];
  public events: ScheduleEventsType[] = [];
  public viewTypeOptions: Array<any> = [
    { value: 'day', text: 'Dia' },
    { value: 'week', text: 'Semana' },
    { value: 'month', text: 'Mês' },
  ];

  public get calendarTitle(): string {
    return this.calendar ? this.calendar.title : formatToSpecificFormat(this.calendarValue, 'MMMM y');
  }

  @Ref('calendar') readonly calendar!: VCalendarType;

  public async created(): Promise<void> {
    await this.$nextTick();

    this.calendarValue = dateNow();

    this.getAllBillingHistoryEvents();

    this.calendar.checkChange();
    this.showTitle = true;
  }

  public async getAllBillingHistoryEvents(): Promise<void> {
    this.loading = true;
    this.$dialog.startLoading();

    try {
      const request = await this.eventsRepository.getAllBillingHistoryEvents(
        this.authenticationModule.user.company_group_id,
        this.authenticationModule.companyIds,
        this.getRangeDateBasedOnViewType(this.calendarValue, this.viewType),
      );

      console.log(request);
      this.billingHistories = request;
      this.events = request.map((event) => ({
        id: event.id,
        name: event.clientName,
        start: `${event.date} ${event.initialRangeTime}`,
        end: `${event.date} ${event.finalRangeTime}`,
        color: BillingHistorySupport.getColorByStatus(event.status),
        formattedDate: `${event.initialRangeTime} - ${event.finalRangeTime}`,
      }));
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os eventos!');
    } finally {
      this.$dialog.stopLoading();
      this.loading = false;
    }
  }

  public getRangeDateBasedOnViewType(actualDate: string, viewType: string): RangeDate {
    let definedInitialDate = '';
    let definedFinalDate = '';

    if (viewType === 'day') {
      definedInitialDate = actualDate;
      definedFinalDate = actualDate;
    }

    if (viewType === 'week') {
      definedInitialDate = getStartOf(actualDate, 'week');
      definedFinalDate = getEndOf(actualDate, 'week');
    }

    if (viewType === 'month') {
      definedInitialDate = getStartOf(actualDate, 'month');
      definedFinalDate = getEndOf(actualDate, 'month');
    }

    return {
      initialDate: definedInitialDate,
      finalDate: definedFinalDate,
    };
  }

  public handleEventClick({ nativeEvent, event }: any): void {
    const open = () => {
      const selectedEvent = this.billingHistories.find(({ id }) => id === event.id);

      if (selectedEvent !== undefined) {
        this.selectedEvent = selectedEvent;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => { this.eventMenuShow = true; }));
      }
    };

    if (this.eventMenuShow) {
      this.eventMenuShow = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  public handleConclude(billingHistory: BillingHistory, eventStatus: string): void {
    this.selectedEvent = billingHistory;
    this.selectedConcludeEventStatus = eventStatus;
    this.callConcludeDialog = true;
  }

  public handleConcludeSave(): void {
    this.callConcludeDialog = false;
    this.selectedEvent = null;
    this.selectedElement = null;
    this.selectedConcludeEventStatus = '';
    this.eventMenuShow = false;
    this.getAllBillingHistoryEvents();
  }

  public handleConcludeCancel(): void {
    this.callUpsertDialog = false;
    this.selectedEvent = null;
    this.selectedConcludeEventStatus = '';
  }

  public handleEditDialogSave() {
    this.callUpsertDialog = false;
    this.selectedEvent = null;
    this.selectedElement = null;
    this.eventMenuShow = false;

    this.getAllBillingHistoryEvents();
  }

  public handleHistory(billingHistory: BillingHistory): void {
    this.$router.push(`/clientes/${billingHistory.idCustomer}/historico-de-cobranca`);
  }

  public handleEdit(billingHistory: BillingHistory): void {
    this.selectedEvent = billingHistory;
    this.callUpsertDialog = true;
  }

  public handleEditDialogCancel() {
    this.callUpsertDialog = false;
  }

  public handleCalendarChange(direction: 'next' | 'prev'): void {
    this.calendar[direction]();
    this.getAllBillingHistoryEvents();
  }

  public handleDisplayForm(): void {
    this.getAllBillingHistoryEvents();
  }

  public handleBackToday(): void {
    this.calendarValue = dateNow();
    this.getAllBillingHistoryEvents();
  }
}
