export default class PayrollLogItem {
  constructor(
    public id: number,
    public idCustomer: string,
    public date: string,
    public name: string,
    public value: number,
    public bank: any,
  ) {}

  static make(data: any): PayrollLogItem {
    return new PayrollLogItem(
      data.id,
      data.id_customer ?? '',
      data.date ?? '',
      data.name ?? '',
      data.value ?? 0,
      data.bank ?? null,
    );
  }
}
