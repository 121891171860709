

































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/VForm';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import BillingHistory from '@/domain/models/BillingHistory';
import EventsRepository from '@/repositories/EventsRepository';
import UpsertBillingHistoryParams from '@/components/timeline/types/UpsertBillingHistoryParams';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class HistoryUpsertDialog extends Vue {
  public model: boolean;
  public loading: boolean = false;

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  private readonly eventsRepository: EventsRepository = new EventsRepository();

  private readonly clientModule: ClientModule = getModule(ClientModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public channelOptions: SelectOptions[] = [
    { value: 'whatsapp', text: 'Whatsapp' },
    { value: 'phone', text: 'Telefone' },
    { value: 'email', text: 'E-mail' },
    { value: 'sms', text: 'SMS' },
  ];
  public register: UpsertBillingHistoryParams = {
    id: null,
    idCustomer: null,
    clientName: '',
    date: '',
    initialRangeTime: '',
    finalRangeTime: '',
    channel: 'phone',
    observation: '',
  };

  constructor() {
    super();

    this.model = this.value;
  }

  @Ref('HistoryUpsertForm') readonly historyUpsertForm!: VForm;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly value!: boolean;

  @Prop({
    type: BillingHistory,
    required: false,
  }) readonly event!: BillingHistory;

  @Prop({
    type: String,
    required: true,
    default: '',
  }) readonly idCustomer!: string;

  @Watch('value')
  onValueChanged(newVal: boolean) {
      if (newVal) this.fillInputs(this.event);

      this.model = newVal;
  }

  @Watch('model')
  onModelChanged(newModel: boolean) {
      this.$emit('input', newModel);
  }

  public get title() : string {
    return this.event ? 'Editar evento' : 'Cadastrar evento';
  }

  public get companyGroupId(): string {
    return this.authenticationModule.user.company_group_id;
  }

  public created(): void {
    this.register.idCustomer = this.idCustomer;

    if (this.event) {
      this.fillInputs(this.event);
    }
  }

  public async handleSubmit(): Promise<void> {
    if (!this.historyUpsertForm.validate()) {
      return;
    }

    await this.upsertEvent();
  }

  public async upsertEvent(): Promise<void> {
    this.loading = true;

    try {
      if (this.event && this.register.id) {
        await this.eventsRepository
          .updateEvent(this.companyGroupId, this.register);
      } else {
        await this.eventsRepository
          .insertEvent(this.companyGroupId, this.clientModule.client.companyId!, this.register);
      }

      this.$notification.success('Interação salva com sucesso!');
      this.handleClose('save-event');
    } catch (error) {
      this.$notification.error('Houve um problema ao salvar a interação!');
    } finally {
      this.loading = false;
    }
  }

  public fillInputs(event: BillingHistory): void {
    this.register.id = event.id;
    this.register.clientName = event.clientName;
    this.register.date = event.date;
    this.register.initialRangeTime = event.initialRangeTime;
    this.register.finalRangeTime = event.finalRangeTime;
    this.register.channel = event.channel;
    this.register.observation = event.observation;
  }

  public handleClose(eventToTrigger: string): void {
    this.model = false;

    this.resetInputValues();
    this.$emit(eventToTrigger);
  }

  public resetInputValues(): void {
    this.register.id = null;
    this.register.clientName = '';
    this.register.date = '';
    this.register.initialRangeTime = '';
    this.register.finalRangeTime = '';
    this.register.channel = 'whatsapp';
    this.register.observation = '';
  }
}
