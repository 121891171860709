


























































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import {
  Vue,
  Component,
  Prop,
  Emit,
  // Watch,
} from 'vue-property-decorator';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';
import { toCurrency } from '@/utils/';
import { formatTitle, groupByRemittance, hideDuplicateOverflow } from '../../utils';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionApprove extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Prop({
    type: String,
  }) name!: string;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.APPROVE,
      reload,
    };
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly formatTitle = formatTitle;
  readonly formatCurrency = toCurrency;

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + item.total, 0);
  }

  async save() {
    try {
      this.$dialog.startLoading();

      const groups = groupByRemittance(this.items);
      const groupKeys = Object.keys(groups);

      const responses = await Promise.allSettled(
        groupKeys.map((remi) => this.handleApprovals(remi, groups[+remi])),
      );

      const someReponseHasRejected = responses
        .some((response) => response.status === 'rejected');

      if (someReponseHasRejected) {
        let errors = 0;
        const remittances = responses
          .reduce((prev, response, index) => {
            if (response.status === 'rejected') {
              errors += 1;

              if (prev.length === 0) {
                prev = groupKeys[index];
              } else {
                prev += index + 1 !== responses.length
                  ? `, ${groupKeys[index]}`
                  : `e ${groupKeys[index]}`;
              }
            }
            return prev;
          }, '');

        const message = errors > 1
          ? 'Ops, ocorreu algum erro nas remessas'
          : 'Ops, ocorreu um erro na remessa';

        this.$notification.error(`${message}: ${remittances}.`);
      }

      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      hideDuplicateOverflow(this);
    }
  }

  async handleApprovals(remi: string, titles: Array<string>) {
    const group = this.$session.get('company_group_id');

    const response = await this.paymentRemittanceRepository
      .approvals(group, +remi, this.companies, titles);

    return response;
  }
}
