
















































































import { Vue, Component, Ref } from 'vue-property-decorator';

import { DataOptions } from 'vuetify';

import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import StatementConciliation from '@/domain/models/StatementConciliation';
import FilterData from '@/domain/interfaces/IStatementConciliationFilter';
import FilterParameterConciliationList from '@/domain/models/filter-parameters/FilterParameterConciliationList';
import SummaryData from '@/domain/models/SummaryBankingMovement';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';

import StatementConciliationFilter from './components/Filter.vue';
import StatementConciliationSummary from './components/Summary.vue';
import StatementConciliationList from './components/List.vue';

import { formatErrorForNotification } from './utils';
import { SummaryDate } from './utils/interfaces';

@Component({
  components: {
    StatementConciliationFilter,
    StatementConciliationSummary,
    StatementConciliationList,
  },
})
export default class StatementConciliationPage extends Vue {
  @Ref('filter') readonly filter!: StatementConciliationFilter;

  readonly StatementConciliationRepository: StatementConciliationRepository =
    new StatementConciliationRepository();

  readonly filterParametersRepository: FilterParametersRepository =
    new FilterParametersRepository();

  showFilter: boolean = true;
  showSummary: boolean = false;
  showList: boolean = false;

  loading: boolean = false;
  validatedFilter: boolean = false;

  valueOfSelectedItems: number = 0;

  sortBy: string = '';
  sortDesc: boolean = false;

  list: Array<StatementConciliation> = [];

  data: FilterData = {} as FilterData;
  currentFilter: FilterData = {} as FilterData;

  summaryData: SummaryData = {} as SummaryData;
  summaryDate: SummaryDate = {} as SummaryDate;

  selectedValue(value: number) {
    this.valueOfSelectedItems = value;
  }

  updateSort(value: DataOptions) {
    const { sortBy, sortDesc } = value;
    this.sortBy = sortBy[0] || '';
    this.sortDesc = sortDesc[0] || false;
    if (!this.loading) { this.handlerSortConciliations(this.sortBy, this.sortDesc); }
  }

  getToggleIcon(): string {
    return this.showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down';
  }

  getToggleTooltip(): string {
    return this.showFilter ? 'Ocultar Parâmetros' : 'Exibir Parâmetros';
  }

  handlerToggle(): void {
    this.showFilter = !this.showFilter;
  }

  handlerGoToHistory(): void {
    this.$router.push({ path: 'conciliacoes-de-extrato/historico' });
  }

  public setFilters(filters: FilterParameterConciliationList): void {
    this.sortDesc = filters.direction;
    this.sortBy = filters.sort;

    this.data.initial_movement_date = filters.dateInitial;
    this.data.end_movement_date = filters.dateFinish;
    this.data.initial_processing_date = filters.dateProInitial;
    this.data.end_processing_date = filters.dateProFinish;
    this.data.bank_id = filters.bank;
    this.data.status = filters.status;
    this.data.company_id = filters.companyId;
  }

  sort(value: string): void {
    if (value.includes('-')) {
      this.sortBy = value.substring(1);
      this.sortDesc = true;
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  }

  handlerLoadConciliations(): void {
    this.showFilter = true;
    const validatedFilter = this.filter.validate();

    if (validatedFilter) {
      this.showList = false;
      this.showSummary = false;

      this.currentFilter = {
        ...this.data,
      };

      this.loadConciliations(this.data);
    }
  }

  handlerSortConciliations(by: string, desc: boolean): void {
    const params: FilterData = {
      ...this.currentFilter,
    };

    const order = desc ? '-' : '';
    params.sort = by ? `${order}${by}` : undefined;

    this.loadConciliations(params);
  }

  async loadConciliations(params: FilterData): Promise<void> {
    try {
      this.$dialog.startLoading();
      this.loading = true;

      this.data = {
        ...params,
      };

      const {
        company_id: companyId,
        initial_movement_date: initialBankBalance,
        end_movement_date: endBankBalance,
        bank_id: bankId,
      } = params;

      const groupId = this.$session.get('company_group_id');

      this.filterParametersRepository.setFilter(
        GroupFilterParametersEnum.CONCILIATION_LIST,
        [
          {
            key: 'date_initial_conciliation_record',
            value: params.initial_movement_date,
          },
          {
            key: 'date_finish_conciliation_record',
            value: params.end_movement_date,
          },
          {
            key: 'date_pro_initial_conciliation_record',
            value: params.initial_processing_date,
          },
          {
            key: 'date_pro_finish_conciliation_record',
            value: params.end_processing_date,
          },
          { key: 'company_id_conciliation_record', value: params.company_id },
          { key: 'bank_conciliation_record', value: params.bank_id },
          {
            key: 'status_conciliation_record',
            value: JSON.stringify(params.status),
          },
          { key: 'sort_conciliation_record', value: params.sort },
        ],
      );

      this.list = await this.StatementConciliationRepository.getConciliations(
        groupId,
        companyId,
        params,
      );

      this.valueOfSelectedItems = 0;
      this.summaryDate = { initialBankBalance, endBankBalance };

      this.summaryData = await this.StatementConciliationRepository.getSummary(
        groupId,
        companyId,
        bankId,
        initialBankBalance,
        endBankBalance,
      );
      this.showSummary = true;

      if (this.list.length) {
        this.showFilter = false;
        this.showList = true;
      } else {
        this.$notification.warn('Nenhum resultado encontrado.');
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  reload() {
    this.handlerSortConciliations(this.sortBy, this.sortDesc);
  }
}
