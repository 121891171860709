











































































import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import PreNegotiationSelectedAccountsTable from '@/views/preNegotiation/installment/PreNegotiationSelectedAccountsTable.vue';
import PreNegotiationInstallmentsTable from '@/views/preNegotiation/installment/PreNegotiationInstallmentsTable.vue';
import PreNegotiationDeadline from '@/views/preNegotiation/deadline/PreNegotiationDeadline.vue';
import PreNegotiationSubscriber from '@/views/preNegotiation/subscriber/PreNegotiationSubscribers.vue';
import PreNegotiationRepository from '@/repositories/PreNegotiationRepository';
import InstallmentChangesRepository from '@/repositories/InstallmentChangesRepository';
import BillingSignatureGroupsRepository from '@/repositories/BillingSignatureGroupsRepository';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import PreNegotiationModel from '@/domain/models/PreNegotiation';

@Component({
  components: {
    PreNegotiationSelectedAccountsTable,
    PreNegotiationInstallmentsTable,
    PreNegotiationDeadline,
    PreNegotiationSubscriber,
  },
})
export default class PreNegotiation extends Vue {
  private readonly preNegotiationRepository
  : PreNegotiationRepository = new PreNegotiationRepository();
  private readonly installmentChangesRepository
  : InstallmentChangesRepository = new InstallmentChangesRepository();
  private readonly billingSignatureGroupsRepository
  : BillingSignatureGroupsRepository = new BillingSignatureGroupsRepository();

  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);

  public hasLoadPreNegotiation: boolean = false;
  public hasLoadInstallmentChanges: boolean = false;
  public hasLoadSignatures: boolean = false;

  public totalFeeType: string|undefined = undefined;
  public totalFee: number|undefined = undefined;

  public preNegotiation: PreNegotiationModel|null = null;

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public get preNegotiationId(): number {
    return Number(this.$route.params.preNegotiationId);
  }

  public async created(): Promise<void> {
    this.$dialog.startLoading();

    await this.getPreNegotiationById();

    if (this.preNegotiation === null) {
      return;
    }

    await Promise.all([
      this.getInstallmentChangesFromPreNegotiation(),
      this.getSignaturesFromPreNegotiation(),
    ]);

    this.$dialog.stopLoading();
  }

  public async getPreNegotiationById(): Promise<void> {
    try {
      const response = await this.preNegotiationRepository.getPreNegotiationById(
        this.companyGroupId,
        this.companyIds,
        this.preNegotiationId,
      );

      this.preNegotiation = response;

      this.preNegotiationModule.setExpirationDate(response.expirationDate);
      this.preNegotiationModule.setInstallmentConfigs({
        customPeriodicity: response.customPeriodicity,
        periodicity: response.periodicity,
        quantity: response.quantity,
      });

      if (response.totalFeeValue && response.totalFeeType) {
        this.preNegotiationModule.setTotalFee(response.totalFeeValue);
        this.preNegotiationModule.setTotalFeeType(response.totalFeeType);
      }
    } catch (error) {
      this.$dialog.stopLoading();
      this.$notification.error('Houve um problema ao requisitar a pré negociação.');
    } finally {
      this.hasLoadPreNegotiation = true;
    }
  }

  public async getInstallmentChangesFromPreNegotiation(): Promise<void> {
    try {
      const response = await this.installmentChangesRepository
        .getInstallmentChangesByPreNegotiationId(
          this.companyGroupId,
          this.companyIds,
          this.preNegotiationId,
        );

      this.preNegotiationModule.setAllInstallmentChanges(response);
    } catch (error) {
      this.$dialog.stopLoading();
      this.$notification.error('Houve um problema ao requisitar as alterações de parcelas.');
    } finally {
      this.hasLoadInstallmentChanges = true;
    }
  }

  public async getSignaturesFromPreNegotiation(): Promise<void> {
    try {
      const subscribers = await this.billingSignatureGroupsRepository
        .getSignaturesByPreNegotiationId(
          this.companyGroupId,
          this.companyIds,
          this.preNegotiationId,
        );

      for (let i = 0; i < subscribers.length; i += 1) {
        const subscriber = subscribers[i];

        this.preNegotiationModule.setNewSignatureGroup(subscriber);
      }
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as assinaturas dessa Pré-Negociação.');
    } finally {
      this.hasLoadSignatures = true;
    }
  }

  public async effectiveContract(): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.preNegotiationRepository.sendPreNegotiationsToERP(
        this.companyGroupId,
        this.companyIds,
        [this.preNegotiationId],
      );

      this.$notification.success('Pré-Negociação enviada a fila de registro do ERP com sucesso.');
      this.handleCancel();
    } catch (error) {
      this.$notification.error('Houve um problema ao enviar a Pré-Negociação ao ERP.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleCancel(): void {
    this.$router.push({ name: 'PreNegotiationList' });
  }

  public handleEffectiveContract(): void {
    this.effectiveContract();
  }

  public beforeDestroy(): void {
    this.preNegotiationModule.resetPreNegotiationStoreData();
  }
}
