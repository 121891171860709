
























































































































































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { toCurrency } from '@/utils/';
import { formatErrorForNotification } from '@/utils/error';
import BankConfig from '@/domain/models/BankConfig';
import IOption from '@/domain/interfaces/ISelectOptions';
import IGenerate from '@/domain/interfaces/IPaymentRemittancesGenerate';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import GenerateFeedback from '@/views/billingRemittance/components/GenerateFeedback.vue';
import { formatTitle, hasPaymentData, hideDuplicateOverflow } from '../../utils';
import { CloseActionData } from '../../utils/interfaces';
import BankSendTypeEnum from '../../enums/BankSendTypeEnum';

@Component({
  components: { GenerateFeedback },
})
export default class PaymentRemittancesActionGenerateRemittance extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Prop({
    type: String,
  }) name!: string;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  }) groupConfig!: CompanyGroupConfig;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.GENERATE_REMITTANCE,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen(value: boolean) {
    if (value) {
      this.loadCompanies();
    } else {
      this.company = Number(undefined);
      this.bank = Number(undefined);
      this.sendType = Number(undefined);
      this.date = '';
      this.isBankSendTypeSelectedVanType = false;
      this.form.resetValidation();
    }
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly hasPaymentData = hasPaymentData;
  readonly formatCurrency = toCurrency;
  readonly formatTitle = formatTitle;
  readonly required = (v: string | number) => !!v || 'Campo obrigatório!';

  loading: boolean = false;
  loadingBank: boolean = false;
  public isBankSendTypeSelectedVanType: boolean = false;

  company: number = Number(undefined);
  companyOptions: Array<IOption<number>> = [];

  bank: number = Number(undefined);
  banks: Array<BankConfig> = [];
  bankOptions: Array<IOption<number>> = [];

  sendType = Number(undefined);
  sendTypesItems: Array<IOption<number>> = [];

  date: string = '';

  public openFeedback: boolean = false;
  public titleFeedback: string = 'Borderô gerado com sucesso!';
  public textFeedback: string = 'Número do borderô:';
  public numberFeedback: string = '';
  public typeFeedback: string = 'success';

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + item.total, 0);
  }

  get group(): number {
    return this.$session.get('company_group_id');
  }

  public get hasPermissionToShowBorderoNumberOnGenerateRemittance(): boolean {
    return this.$session.get('user_config_autotoggle_remittance_confirm_message');
  }

  public get hasAccountPayableWithoutPaymentInfo(): boolean {
    if (!this.isBankSendTypeSelectedVanType) {
      return false;
    }

    return this.items.some((item) => !hasPaymentData(item, this.groupConfig));
  }

  changedBank(): void {
    this.sendTypesItems = [];
    this.sendType = Number(undefined);
    this.isBankSendTypeSelectedVanType = false;

    const bankSelected = this.banks
      .find((bank) => bank.id === this.bank);

    this.sendTypesItems = bankSelected
      ?.send_types.map((type) => {
        const { id, description } = type;
        return { value: id, text: description };
      }) || [];
  }

  changedCompany(): void {
    this.sendTypesItems = [];
    this.sendType = Number(undefined);

    this.bank = Number(undefined);
    this.banks = [];
    this.bankOptions = [];
    this.isBankSendTypeSelectedVanType = false;
    if (this.company) this.loadBanks(this.company);
  }

  changedSendType(): void {
    if (!this.sendType) {
      this.isBankSendTypeSelectedVanType = false;

      return;
    }

    const selectedBank = this.banks.find(({ id }) => this.bank === id);

    if (!selectedBank) {
      this.isBankSendTypeSelectedVanType = false;

      return;
    }

    const selectedSendType = selectedBank.send_types.find(({ id }) => id === this.sendType);

    if (!selectedSendType) {
      this.isBankSendTypeSelectedVanType = false;

      return;
    }

    this.isBankSendTypeSelectedVanType = selectedSendType.send_type === BankSendTypeEnum.VAN;
  }

  save() {
    const isValidform = this.form.validate();

    if (!isValidform) {
      return;
    }

    if (this.hasAccountPayableWithoutPaymentInfo) {
      this.$notification.warn('Não é permitido gerar uma remessa de títulos sem dados de pagamento quando o tipo da remessa é VAN');

      return;
    }

    const {
      company,
      bank,
      sendType,
      date,
    } = this;

    const titles = this.items
      .map((item) => item.id_customer);

    const params: IGenerate = {
      bank_send_type_id: bank,
      company_id: company,
      payment_date: date,
      remittance_type: sendType,
      titles,
    };

    this.handleGenerate(params);
  }

  async loadCompanies(): Promise<void> {
    try {
      this.loading = true;

      this.companyOptions = await this.paymentRemittanceRepository
        .getCompanies(this.group);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  async loadBanks(company: number): Promise<void> {
    try {
      this.loadingBank = true;

      this.banks = await this.paymentRemittanceRepository
        .getAllBanksConfig([company]);

      this.bankOptions = this.banks.map((bank) => {
        const {
          id,
          banco,
          agencia,
          conta,
          name,
        } = bank;

        return {
          value: id,
          text: `${banco} ${agencia} ${conta} - ${name}`,
        };
      });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingBank = false;
    }
  }

  downloadFile(url: string) {
    return new Promise((res) => {
      window.open(url);

      res(true);
    });
  }

  async handleGenerate(params: IGenerate): Promise<void> {
    try {
      this.$dialog.startLoading();

      const response = await this.paymentRemittanceRepository
        .generate(this.group, this.companies, params);

      if (response.url) {
        await this.downloadFile(response.url);
      }

      if (this.hasPermissionToShowBorderoNumberOnGenerateRemittance) {
        this.numberFeedback = response.borderoNumber;
        this.openFeedback = true;

        return;
      }

      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      hideDuplicateOverflow(this);
    }
  }

  public handleCloseFeedback(): void {
    this.openFeedback = false;
    this.titleFeedback = '';
    this.textFeedback = '';
    this.numberFeedback = '';
    this.typeFeedback = '';
  }
}
