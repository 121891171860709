import EPayrollLogType from '../enums/PayrollLogType';

export default class PayrollLog {
  constructor(
    public id: number,
    public type: EPayrollLogType,
    public date: string,
    public user: string,
    public file: string,
    public customerFileName: string,
  ) {}

  static make(data: any): PayrollLog {
    return new PayrollLog(
      data.id,
      data.type ?? '',
      data.date ?? '',
      data.user ?? '',
      data.file ?? '',
      data.customer_file_name ?? '',
    );
  }
}
