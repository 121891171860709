



















































































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { formateDate } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';

import IVDataTableHeader from '@/types/IVDataTableHeader';
import IPayrollHistoryFilter from '@/domain/interfaces/IPayrollHistoryFilter';
import PayrollLog from '@/domain/models/PayrollLog';
import EPayrollLogType from '@/domain/enums/PayrollLogType';
import PayrollRepository from '@/repositories/PayrollRepository';
import PayrollHistoryReceipt from './PayrollHistoryReceipt.vue';
import PayrollHistoryView from './PayrollHistoryView.vue';

interface IDialog {
  open: boolean,
  id: number,
}

@Component({
  components: {
    PayrollHistoryReceipt,
    PayrollHistoryView,
  },
})
export default class PayrollHistoryList extends Vue {
  @Prop({
    type: Array as () => Array<PayrollLog>,
    required: true,
    default: [],
  }) readonly items!: Array<PayrollLog>;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  }) readonly serverItemsLength!: number;

  @Prop({
    type: Object as () => IPayrollHistoryFilter,
    required: true,
  }) readonly data!: IPayrollHistoryFilter;

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangePage!: () => {};

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangeItemsPerPage!: () => {};

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangeSortBy!: () => {};

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangeSortDesc!: () => {};

  headers: Array<IVDataTableHeader> = [
    { text: 'Tipo', value: 'type', sortable: true },
    { text: 'Data', value: 'date', sortable: true },
    { text: 'Usuário', value: 'user', sortable: true },
    { text: 'Nome do Arquivo do Banco', value: 'file', sortable: true },
    { text: 'Nome do Arquivo do Cliente', value: 'customerFileName', sortable: true },
    { text: '', value: 'id', sortable: false },
  ];

  options = {
    page: this.data.page ?? 1,
    itemsPerPage: this.data.itemsPerPage ?? 0,
    sortBy: [this.data.sortBy],
    sortDesc: [this.data.sortDesc],
  };

  footerProps = {
    'items-per-page-options': [50, 100, 150],
  }

  view: IDialog = {
    open: false,
    id: 0,
  }

  receipt: IDialog = {
    open: false,
    id: 0,
  }

  readonly repository = new PayrollRepository();
  readonly logType = EPayrollLogType;

  getTypeLabel(value: EPayrollLogType): string {
    if (value === EPayrollLogType.SEND) return 'Envio';
    if (value === EPayrollLogType.RETURN) return 'Retorno';
    return '';
  }

  getTypeColor(value: EPayrollLogType): string {
    if (value === EPayrollLogType.SEND) return 'primary';
    if (value === EPayrollLogType.RETURN) return 'info';
    return 'default';
  }

  formatDate(value: string): string {
    const raw = value.split(' ');

    const date = formateDate(raw[0] ?? '');
    const time = raw[1] ?? '';

    return `${date} ${time}`;
  }

  openViewLog(id: number) {
    this.view.id = id;
    this.view.open = true;
  }

  openReceipt(id: number) {
    this.receipt.id = id;
    this.receipt.open = true;
  }

  async downloadPayroll(item: PayrollLog) {
    try {
      const group = this.$session.get('company_group_id');
      const { id, type } = item;

      const response = await this.repository.downloadPayroll(
        group,
        this.data.companies,
        id,
        type,
      );

      const blob = new Blob([response]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = item.file;
      link.click();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
