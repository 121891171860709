
















import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import AccountReceivableSituationType from '@/domain/enums/BillingRemittanceAccountReceivableSituationType';

interface StatusObject {
  code: string,
  text: string,
  icon: string,
  color: string,
}

@Component({})
export default class BillingRemittanceListStatus extends Vue {
  @Prop(String) readonly code!: string;

  statusList: Array<StatusObject> = [
    {
      code: AccountReceivableSituationType.CANCELED,
      text: 'Cancelado.',
      icon: 'mdi-cancel',
      color: 'error',
    },
    {
      code: AccountReceivableSituationType.ERROR,
      text: 'Inclusão rejeitada.',
      icon: 'mdi-close-circle-outline',
      color: 'error',
    },
    {
      code: AccountReceivableSituationType.EXPIRED,
      text: 'Título vencido ou Pix expirado.',
      icon: 'mdi-timer-sand-complete',
      color: 'warning',
    },
    {
      code: AccountReceivableSituationType.NOT_SENT,
      text: 'Não enviado.',
      icon: 'mdi-arrow-top-right-thin-circle-outline',
      color: 'warning',
    },
    {
      code: AccountReceivableSituationType.PAID,
      text: 'Pago.',
      icon: 'mdi-cash-multiple',
      color: 'success',
    },
    {
      code: AccountReceivableSituationType.PAID_WITHOUT_GENERATE_DOCUMENT,
      text: 'Pago sem gerar documento.',
      icon: 'mdi-cash-multiple',
      color: 'success',
    },
    {
      code: AccountReceivableSituationType.SENT,
      text: 'Enviado ao banco.',
      icon: 'mdi-arrow-top-right-thin-circle-outline',
      color: 'default',
    },
    {
      code: AccountReceivableSituationType.SUCCESS,
      text: 'Registro confirmado.',
      icon: 'mdi-check-circle-outline',
      color: 'success',
    },
    {
      code: AccountReceivableSituationType.SUCCESS_WITH_OCCURRENCES,
      text: 'Registro confirmado.',
      icon: 'mdi-check-circle-outline',
      color: 'success',
    },
    {
      code: AccountReceivableSituationType.UNDEFINED,
      text: 'Inconsistência no título.',
      icon: 'mdi-alert-outline',
      color: 'default',
    },
  ];

  get status(): StatusObject {
    return this.statusList.find((item) => (item.code == this.code)) || {} as StatusObject;
  }
}
