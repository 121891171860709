






















































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';

import { formatErrorForNotification } from '@/utils/error';
import Breadcrumbs from '@/components/defaults/Breadcrumbs.vue';
import PageContainer from '@/components/defaults/PageContainer.vue';
import PayrollRepository from '@/repositories/PayrollRepository';
import IOption from '@/domain/interfaces/ISelectOptions';

@Component({
  components: {
    VueDropzone: vue2Dropzone,
    Breadcrumbs,
    PageContainer,
  },
})
export default class PayrollUpload extends Vue {
  @Ref('payrollUpload') dropzone!: any;

  readonly payrollRepository: PayrollRepository = new PayrollRepository();

  companies: Array<IOption<number>> = [];
  company: number = 0;
  loadingCompanies: boolean = false;

  banks: Array<IOption<number>> = [];
  bank: number = 0;
  loadingBanks: boolean = false;

  accountsPayable: Array<string> = [];

  dropzoneOptions = {
    url: () => `${this.$store.state.API_BASE}company-groups/${this.groupId}/payrolls/accounts-payable`,
    paramName: 'payroll_file',
    duplicateCheck: true,
    uploadMultiple: false,
    acceptedFiles: '.txt, .rem',
    parallelUploads: 1,
    dictRemoveFile: 'Remover',
    dictInvalidFileType: 'Tipo do arquivo inválido!',
  };

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  mounted() {
    this.getLocalStorage();
    this.loadData();

    const { accountsPayable } = this.$route.params;

    this.accountsPayable = accountsPayable && Array.isArray(accountsPayable)
    ? accountsPayable.map((item) => item.idCustomer)
    : [];
  }

  goToHistory() {
    this.$router.push('/folhas-de-pagamento/historico');
  }

  goToSelect() {
    this.$router.push('/folhas-de-pagamento/vincular');
  }

  onChangeCompany(): void {
    this.bank = 0;
    this.banks = [];
    if (this.company) {
      this.loadBanks(this.company);
      localStorage.setItem('payroll-upload-company', `${this.company}`);
      this.setLocalStorageExpiration();
    } else {
      this.removeLocalStorage();
    }
  }

  onChangeBank(): void {
    if (this.bank) {
      localStorage.setItem('payroll-upload-bank', `${this.bank}`);
      this.setLocalStorageExpiration();
    } else {
      localStorage.removeItem('payroll-upload-bank');
    }
  }

  setLocalStorageExpiration(): void {
    localStorage.setItem('payroll-upload-expiration', `${new Date().getTime() + 3600000}`);
  }

  getLocalStorage(): void {
    const expirtation = localStorage.getItem('payroll-upload-expiration');
    const companyId = localStorage.getItem('payroll-upload-company');
    const bankId = localStorage.getItem('payroll-upload-bank');

    const time = new Date().getTime();

    if (expirtation && !Number.isNaN(companyId) && time < +expirtation) {
      if (companyId && !Number.isNaN(companyId)) this.company = +companyId;
      if (bankId && !Number.isNaN(bankId)) this.bank = +bankId;
    } else {
      this.removeLocalStorage();
    }
  }

  removeLocalStorage(): void {
    localStorage.removeItem('payroll-upload-expiration');
    localStorage.removeItem('payroll-upload-company');
    localStorage.removeItem('payroll-upload-bank');
  }

  handleUploadError(file: File, error: any) {
    const message = !!error.message && typeof error.message === 'string'
    ? error.message
    : formatErrorForNotification(error);

    this.$notification.error(message ? `${message}` : `${error}`);

    this.dropzone.removeFile(file);
  }

  handleUploadSuccess() {
    this.$notification.success('Arquivo enviado com sucesso!');
    this.removeLocalStorage();
  }

  handleUpload(file: File, xhr: XMLHttpRequest, data: FormData): void {
    xhr.setRequestHeader('token', this.$session.get('token'));
    xhr.setRequestHeader('company-id', `${this.company ?? 0}`);

    data.append('bank_config_id', `${this.bank}`);

    this.accountsPayable.forEach((idCustomer) => {
      data.append('customer_ids[]', idCustomer);
    });
  }

  async loadCompanies() {
    try {
      this.loadingCompanies = true;

      this.companies = await this.payrollRepository
      .getCompanies();
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingCompanies = false;
    }
  }

  async loadBanks(company: number) {
    try {
      this.loadingBanks = true;

      this.banks = await this.payrollRepository
      .getAllBanksConfig([company]);
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingBanks = false;
    }
  }

  async loadData() {
    await this.loadCompanies();

    if (this.company) {
      this.loadBanks(this.company);
    }
  }
}
