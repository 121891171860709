
























































































































































































































































import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { toCurrency } from '@/utils/index';
import SortHelper from '@/helpers/SortHelper';
import StringHelper from '@/helpers/StringHelper';
import NumberHelper from '@/helpers/NumberHelper';
import SettleBankReturnResumeTableRowTemplate from '@/views/CnabRemittanceGenerated/components/SettleBankReturnResumeTableRowTemplate.vue';
import SettledBankRepository from '@/repositories/SettledBankRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import SettleBankAccount from '@/domain/models/SettleBankAccount';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import ISettledBankAccountTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISettledBankAccountTransaction';
import ITransactionBankAccountInfo from '@/views/CnabRemittanceGenerated/interfaces/ITransactionBankAccountInfo';
import ITransactionGroupTableRow from '@/views/CnabRemittanceGenerated/interfaces/ITransactionGroupTableRow';
import AvailableSortTypesOnSettledBankReturnResumeDataTable from '@/views/CnabRemittanceGenerated/types/AvailableSortTypesOnSettledBankReturnResumeDataTable';
import SortDataType from '@/helpers/types/SortDataType';

@Component({
  components: { SettleBankReturnResumeTableRowTemplate },
})
export default class SettledBankReturnScreen extends Vue {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly settledBankRepository: SettledBankRepository = new SettledBankRepository();

  public settledBanksReturn: SettleBankAccount|null = null;

  public hasTriedToRequestTheReturns: boolean = false;

  public headers: IVDataTableHeader[] = [
    {
      text: 'Status',
      value: 'transactionStatus',
      width: '81px',
    },
    {
      text: 'Título',
      value: 'title',
      sortable: false,
      width: '385px',
    },
    {
      text: 'Valor Título',
      value: 'titlePaymentReceiptValue',
      width: '118px',
    },
    {
      text: 'Juros',
      value: 'titlePaymentReceiptInterest',
      width: '76px',
    },
    {
      text: 'Desconto',
      value: 'titlePaymentReceiptDiscount',
      width: '97px',
    },
    {
      text: 'Valor Pago',
      value: 'amountPaidValue',
      width: '106px',
    },
    {
      text: 'Data Inclusão',
      value: 'inclusionDate',
      width: '117px',
    },
    {
      text: 'Data Baixa',
      value: 'lowDate',
      width: '104px',
    },
    {
      text: 'Ocorrência',
      value: 'occurrence',
      width: '245px',
    },
    {
      text: 'Observação',
      value: 'observation',
      width: '305px',
    },
  ];

  public get getTypeText(): string {
    if (this.settledBanksReturn === null) {
      return '';
    }

    return {
      0: 'Retorno Bancário de Pagamento',
      1: 'Retorno Bancário de Cobrança',
    }[this.settledBanksReturn.type];
  }

  public get getTransactionBankAccountInfo(): ITransactionBankAccountInfo|null {
    if (this.settledBanksReturn === null) {
      return null;
    }

    const {
      bankCode,
      bankAgency,
      bankAgencyDigit,
      bankAccount,
      bankAccountDigit,
    } = this.settledBanksReturn.transactions[0].firstTransaction;

    const definedBankAgencyDigit = StringHelper.isAlphanumeric(bankAgencyDigit ?? '') ? ` - ${bankAgencyDigit}` : '';
    const definedBankAccountDigit = StringHelper.isAlphanumeric(bankAccountDigit ?? '') ? ` - ${bankAccountDigit}` : '';

    return {
      bankCode,
      bankAgency: `${bankAgency}${definedBankAgencyDigit}`,
      bankAccount: `${bankAccount}${definedBankAccountDigit}`,
    };
  }

  public get getGeneralTransactions(): ISettledBankAccountTransaction[] {
    if (this.settledBanksReturn === null) {
      return [];
    }

    return this.settledBanksReturn.transactions
      .filter(({ firstTransaction }) => !['00', 'DV', 'NA'].includes(firstTransaction.bankOccurrence))
      .map(({ firstTransaction }) => firstTransaction);
  }

  public get getAllFailedTransactions(): ISettledBankAccountTransaction[] {
    if (this.settledBanksReturn === null) {
      return [];
    }

    return this.settledBanksReturn.completeTransactions
      .filter(({ transactionStatus, bankOccurrence }) => transactionStatus === 'fail' && !['BD', '00'].includes(bankOccurrence));
  }

  public get getTotalScheduled(): ISettledBankAccountTransaction[] {
    if (this.settledBanksReturn === null) {
      return [];
    }

    return this.settledBanksReturn.completeTransactions
      .filter(({ bankOccurrence }) => bankOccurrence === 'BD');
  }

  public get getTotalSettled(): ISettledBankAccountTransaction[] {
    if (this.settledBanksReturn === null) {
      return [];
    }

    return this.settledBanksReturn.completeTransactions
      .filter(({ bankOccurrence }) => bankOccurrence === '00');
  }

  public async created(): Promise<void> {
    await this.getSettledBankReturn();
  }

  public async getSettledBankReturn(): Promise<void> {
    this.$dialog.startLoading();

    try {
      this.settledBanksReturn = await this.settledBankRepository.getSettledBankReturnByRemittanceId(
        parseInt(this.authenticationModule.user.company_group_id, 10),
        parseInt(this.$route.params.remittanceId, 10),
      );
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar o retorno bancário!');

      this.$notification.error(message);
    } finally {
      this.hasTriedToRequestTheReturns = true;
      this.$dialog.stopLoading();
    }
  }

  public sumAllValuesFromTransactions(
    transactions: ISettledBankAccountTransaction[],
    applyCurrencyFormatter: boolean = true,
  ): number|string {
    const allValues: number[] = transactions
      .map(({ titlePaymentReceiptValue }) => {
        let definedValue = 0;

        if (NumberHelper.isNumeric(titlePaymentReceiptValue)) {
          definedValue = parseFloat(titlePaymentReceiptValue);
        }

        return definedValue;
      });

    if (allValues.length < 1) {
      return this.toCurrencyValues(0);
    }

    const summedValues = allValues
      .reduce((previousValue, actualValue) => previousValue + actualValue);

    if (applyCurrencyFormatter) {
      return this.toCurrencyValues(summedValues);
    }

    return summedValues;
  }

  public toCurrencyValues(value: string|number): string {
    return toCurrency(value, {}, true);
  }

  public handleBackToCnabList(): void {
    this.$router.push({ name: 'cnabremittancegenerated' });
  }

  public customSort(
    items: ITransactionGroupTableRow[],
    sortBy: [AvailableSortTypesOnSettledBankReturnResumeDataTable],
    sortDesc: boolean[],
  ) {
    const [fieldToSort] = sortBy;
    const [isDescending] = sortDesc;

    items.sort(({ firstTransaction }, { firstTransaction: secondTransaction }) => {
      if (fieldToSort === undefined && isDescending === undefined) {
        return 0;
      }

      const sortMethodName = this.getSortMethodBasedOnColumnName(fieldToSort);

      if (!firstTransaction[fieldToSort] || !secondTransaction[fieldToSort]) {
        return SortHelper.onInvalidValues(
          isDescending,
          firstTransaction[fieldToSort],
          secondTransaction[fieldToSort],
        );
      }

      if (isDescending) {
        return SortHelper.getSortResultBasedOnValueType(
          sortMethodName,
          secondTransaction[fieldToSort],
          firstTransaction[fieldToSort],
        );
      }

      return SortHelper.getSortResultBasedOnValueType(
        sortMethodName,
        firstTransaction[fieldToSort],
        secondTransaction[fieldToSort],
      );
    });

    return items;
  }

  public getSortMethodBasedOnColumnName(fieldName: string): SortDataType {
    if (['inclusionDate', 'lowDate'].includes(fieldName)) {
      return 'date';
    }

    if (
      [
        'titlePaymentReceiptValue',
        'titlePaymentReceiptInterest',
        'titlePaymentReceiptDiscount',
        'amountPaidValue',
      ].includes(fieldName)
    ) {
      return 'financial';
    }

    return 'string';
  }
}
