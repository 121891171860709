































































































































































































































































































import {
 Vue, Component, Prop, Emit, Watch, Ref,
} from 'vue-property-decorator';

import { VForm } from '@/types/VForm';

import ActionType from '@/domain/enums/StatementConciliationActionType';

import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IERPMovement from '@/domain/interfaces/IERPMovement';
import IIncludeMovement from '@/domain/interfaces/IIncludeMovement';
import IIncludeMovementWithTransfer from '@/domain/interfaces/IIncludeMovementWithTransfer';

import Bank from '@/domain/models/Bank';
import CostCenter from '@/domain/models/CostCenter';
import ValueClass from '@/domain/models/ValueClass';
import AccountingItem from '@/domain/models/AccountingItem';
import FinancialNature from '@/domain/models/FinancialNature';
import ConciliationConfig from '@/domain/models/ConciliationConfig';

import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';
import CompanyConfigurationRepository from '@/repositories/CompanyConfigurationsRepository';

import { formateDate } from '@/utils/date';
import Company from '@/domain/models/Company';

import CompanyConfigurations from '@/domain/models/CompanyConfigurations';
import { CreateMovementData } from '../utils/interfaces';
import {
  formatCurrency,
  formatErrorForNotification,
  formatType,
} from '../utils';

interface Rules {
  valueRequerid: any;
  booleanRequerid: any;
  lessOrEquals: any;
}

@Component({})
export default class StatementConciliationCreateMovement extends Vue {
  companies: Array<ISelectOptions> = [];

  @Ref('form') readonly form!: VForm;

  @Prop(Boolean) readonly open!: boolean;

  @Prop({
    type: Object as () => CreateMovementData,
  })
  readonly data!: CreateMovementData;

  @Emit()
  close(): ActionType {
    this.movement = {} as CreateMovementData;
    this.conciliationConfig = {} as ConciliationConfig;
    this.form.resetValidation();
    return ActionType.CREATE;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  @Watch('movement.do_bank_transfer')
  handleWithTransfer(isTransfer: boolean) {
    if (isTransfer) {
      this.movement.currency = this.companyConfiguration.bankTransactionTransferCurrencyType;
    } else {
      this.movement.currency = this.companyConfiguration.bankTransactionCurrencyType;
    }
  }

  @Watch('data')
  async loadedData(): Promise<void> {
    try {
      this.loading = true;

      this.movement = {
        ...this.data,
      };

      await this.getFirstCompanyConfigurationByCompanyId();

      if (this.company && this.bank) {
        await this.loadBanks();
        await this.loadConciliationConfigs();
        await this.loadCompanies();
      } else {
        this.close();
        this.$notification.error('Nenhuma empresa e/ou banco selecionada!');
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  @Watch('financialNatureSearch')
  async changeFinancialNatures(search: string) {
    if (this.financialNatureLoading || this.movement.financial_nature) return;

    try {
      this.financialNatureLoading = true;
      await this.loadFinancialNatures(search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.financialNatureLoading = false;
    }
  }

  async loadCompanies(): Promise<void> {
    const companies = await this.StatementConciliationRepository.getCompanies();

    this.companies = companies.map((company: Company) => {
      const { name: text, id: value } = company;
      return { text, value };
    });
  }

  @Watch('costCenterSearch')
  async changeCostCenters(search: string) {
    if (this.costCenterLoading || this.movement.cost_center) return;

    try {
      this.costCenterLoading = true;
      await this.loadCostCenters(search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.costCenterLoading = false;
    }
  }

  @Watch('accountingItemSearch')
  async changeAccountingItems(search: string) {
    if (this.accountingItemLoading || this.movement.account_item) return;

    try {
      this.accountingItemLoading = true;
      await this.loadAccountingItems(search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.accountingItemLoading = false;
    }
  }

  @Watch('valueClassSearch')
  async changeValueClasss(search: string) {
    if (this.valueClassLoading || this.movement.value_class) return;

    try {
      this.valueClassLoading = true;
      await this.loadValueClasses(search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.valueClassLoading = false;
    }
  }

  readonly formateDate = formateDate;
  readonly formatType = formatType;
  readonly formatCurrency = formatCurrency;

  readonly StatementConciliationRepository: StatementConciliationRepository =
    new StatementConciliationRepository();

  readonly CompanyConfigurationRepository: CompanyConfigurationRepository =
    new CompanyConfigurationRepository();

  movement: CreateMovementData = {} as CreateMovementData;
  conciliationConfig: ConciliationConfig = {} as ConciliationConfig;
  companyConfiguration: CompanyConfigurations = {} as CompanyConfigurations;

  loading: boolean = false;

  financialNatureLoading: boolean = false;
  financialNatureSearch: string = '';

  costCenterLoading: boolean = false;
  costCenterSearch: string = '';

  accountingItemLoading: boolean = false;
  accountingItemSearch: string = '';

  valueClassLoading: boolean = false;
  valueClassSearch: string = '';

  banks: Array<ISelectOptions> = [];
  costCenters: Array<ISelectOptions> = [];
  accountItems: Array<ISelectOptions> = [];
  valueClasses: Array<ISelectOptions> = [];
  financialNatures: Array<ISelectOptions> = [];

  bank: Bank = {} as Bank;

  doOrNotdo: Array<ISelectOptions> = [
    { text: 'Não', value: false },
    { text: 'Sim', value: true },
  ];

  rules: Rules = {
    valueRequerid: (value: string | number) => !!value || 'Campo obrigatório!',
    booleanRequerid: (value: boolean) => value !== undefined || 'Campo obrigatório!',
    lessOrEquals: (size: number) => [
      (value: string) => (value && value.length <= size)
        || `O tamanho do campo precisa ser menor ou igual a ${size}.`,
    ],
  };

  get company() {
    return this.data.company;
  }

  get group() {
    return this.$session.get('company_group_id');
  }

  async loadBanks(): Promise<void> {
    const banks = await this.StatementConciliationRepository.getBanksByCompany(
      this.company,
    );

    this.banks = banks.map((bank: Bank) => {
      const {
       code, agency, account, name, idCustomer: value,
      } = bank;
      const text = `${code} ${agency} ${account} ${name}`;
      return { text, value };
    });

    const { bank: idCustomer } = this.data;
    const currentBank = banks.find((b: Bank) => b.idCustomer === idCustomer) || ({} as Bank);
    this.bank = currentBank;
  }

  async loadFinancialNatures(search?: string): Promise<void> {
    const financialNatures = await this.StatementConciliationRepository.getFinancialNatures(
        this.company,
        search,
      );

    const moreFinancialNatures = financialNatures.map(
      (financialNature: FinancialNature) => {
        const { ed_codigo: value, ed_descric: text } = financialNature;
        return { text: `${value} - ${text}`, value };
      },
    );

    this.financialNatures.push(...moreFinancialNatures);
  }

  async loadCostCenters(search?: string): Promise<void> {
    const costCenters = await this.StatementConciliationRepository.getCostCenters(
        this.company,
        search,
      );

    const moreCostCenters = costCenters.map((costCenter: CostCenter) => {
      const { ctt_custo: value, ctt_desc01: text } = costCenter;
      return { text: `${value} - ${text}`, value };
    });

    this.costCenters.push(...moreCostCenters);
  }

  async loadAccountingItems(search?: string): Promise<void> {
    const accountItems = await this.StatementConciliationRepository.getAccountingItems(
        this.company,
        search,
      );

    const moreAccountItems = accountItems.map((accountItem: AccountingItem) => {
      const { ctd_item: value, ctd_desc01: text } = accountItem;
      return { text: `${value} - ${text}`, value };
    });

    this.accountItems.push(...moreAccountItems);
  }

  async loadValueClasses(search?: string): Promise<void> {
    const valueClasses = await this.StatementConciliationRepository.getValueClasses(
        this.company,
        search,
      );

    const moreValueClasses = valueClasses.map((valueClass: ValueClass) => {
      const { cth_clvl: value, cth_desc01: text } = valueClass;
      return { text: `${value} - ${text}`, value };
    });

    this.valueClasses.push(...moreValueClasses);
  }

  async loadConciliationConfigs(): Promise<void> {
    const { code: bankCode } = this.bank;
    const conciliationConfig = await this.StatementConciliationRepository.getConciliationConfigs(
        this.company,
        bankCode,
      );

    if (conciliationConfig.length > 0) {
      const { 0: config } = conciliationConfig;
      this.conciliationConfig = config;
    } else {
      this.close();
      throw new Error(
        'Verifique as configurações de conciliação bancária para esta empresa.',
      );
    }
  }

  async getFirstCompanyConfigurationByCompanyId() {
    this.companyConfiguration = await this.CompanyConfigurationRepository
    .getCompanyConfigurationByCompanyId(
        this.group,
        this.company,
      );

    this.movement.currency = this.companyConfiguration.bankTransactionCurrencyType;
  }

  handlerSave(event: Event): void {
    event.preventDefault();

    const isValid = this.form.validate();

    if (isValid) {
      const { do_bank_transfer: withTransfer } = this.movement;

      if (withTransfer) {
        this.includeBankMovement(withTransfer);
      } else {
        this.includeBankMovement();
      }
    }
  }

  async includeBankMovement(withTransfer?: boolean) {
    try {
      this.$dialog.startLoading();

      const { idCustomer: bankIdCustomer } = this.bank;

      const {
        id,
        value: dataValue,
        financial_nature: dataNature,
        history: dataHistory,
        date: dataDate,
        type: dataType,
        cost_center: dataCostCenter,
        value_class: dataValueClass,
        account_item: dataAccountItem,
        currency,
        company_id: companyId,
      } = this.movement;

      const docType = dataType === 'C' ? 'R' : 'P';
      const isP = docType === 'P';
      const isR = docType === 'R';

      const erpMovement: IERPMovement = {
        date: dataDate,
        amount: parseFloat(dataValue),
        nature: dataNature,
        disposition_date: dataDate,
        cost_center_credit: isR ? dataCostCenter : '',
        cost_center_debit: isP ? dataCostCenter : '',
        value_class_credit: isR ? dataValueClass : '',
        value_class_debit: isP ? dataValueClass : '',
        account_item_credit: isR ? dataAccountItem : '',
        account_item_debit: isP ? dataAccountItem : '',
        history: dataHistory,
        currency,
        type: docType,
      };

      if (withTransfer) {
        const { bank_transfer_to: destinationBank } = this.movement;
        const includeMovementWithTransfer: IIncludeMovementWithTransfer = {
          bank_movement_id: id,
          erp_movement: erpMovement,
          origin_bank_id: bankIdCustomer,
          destination_bank_id: destinationBank,
          disposition_date: dataDate,
          company_id: companyId,
        };

        const success = await this.StatementConciliationRepository.includeBankMovementWithTranfer(
            this.group,
            this.company,
            includeMovementWithTransfer,
          );

        if (success) {
          this.$notification.success(
            'Movimento bancário incluido com sucesso.',
          );
          this.close();
          this.reload();
        }
      } else {
        const includeMovement: IIncludeMovement = {
          bank_movement_id: id,
          erp_movement: erpMovement,
          bank_id: bankIdCustomer,
          disposition_date: dataDate,
          currency,
        };

        const success = await this.StatementConciliationRepository.includeBankMovement(
            this.group,
            this.company,
            includeMovement,
          );

        if (success) {
          this.$notification.success(
            'Movimento bancário incluido com sucesso.',
          );
          this.close();
          this.reload();
        }
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
