import Bank from '@/domain/models/Bank';
import Company from '@/domain/models/Company';
import Customer from '@/domain/models/Customer';
import BillingMethod from '@/domain/models/BillingMethod';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import FinancialDocumentSituation from '@/domain/models/FinancialDocumentSituation';
import BillingRemittancePaymentMethod from '@/domain/models/BillingRemittancePaymentMethod';
import BillingRemittanceAccountReceivableDetail from '@/domain/models/BillingRemittanceAccountReceivableDetail';
import BillingRemittanceAccountReceivableOccurrence from '@/domain/models/BillingRemittanceAccountReceivableOccurrence';
import BankConfig from '@/domain/models/BankConfig';
import BillingRemittanceGenerate from '@/domain/models/BillingRemittanceGenerate';

import IBillingRemittanceSearch from '@/domain/interfaces/IBillingRemittanceSearch';

import IAutocompleteBank from '@/views/billingRemittance/interfaces/IAutocompleteBank';
import Repository from './base/Repository';

export default class BillingRemittanceRepository extends Repository {
  async getTypes(groupId: number): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get(
      `financialdocumentsituation/listActives/${groupId}/0`,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Tipos de Carteira: ${response.message}`);

      const types: Array<FinancialDocumentSituation> = response.data.map(
        FinancialDocumentSituation.make,
      );

      const selectOptions = types.map((type) => {
        const { code, description } = type;
        return { value: code, text: description };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos tipos de carteira.');
  }

  async getMethods(groupId: number): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get(
      `billingmethod/listAll/${groupId}`,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Formas de Recebimento: ${response.message}`);

      const methods: Array<BillingMethod> = response.data.map(BillingMethod.make);

      const selectOptions = methods.map((method) => {
        const { code, description } = method;
        return { value: code, text: description };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das forma de recebimento.');
  }

  async getBanks(groupId: number): Promise<IAutocompleteBank[]> {
    const { status, data: response } = await this.datasource.get(`bank/listAll/${groupId}`);

    if (status == 200) {
      if (response.error) throw new Error(`Bancos: ${response.message}`);

      const banks: Array<Bank> = response.data.map(Bank.make);

      const selectOptions = banks.map(({ code, name, account }) => ({
          code,
          account,
          isNull: false,
          text: `${code} - ${account} - ${name}`,
        }));

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos bancos.');
  }

  async getCompanies(): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get('company/listAllActv');

    if (status == 200) {
      if (response.error) throw new Error(`Empresas: ${response.message}`);

      const companies: Array<Company> = response.data.map(Company.make);

      const selectOptions = companies.map((company) => {
        const { id, name } = company;
        return { value: id, text: name };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das companhias do grupo.');
  }

  async getCustomers(
    groupId: number,
    companies: Array<number>,
    search: string,
    type?: string,
  ): Promise<Array<SelectOptions>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/customers/search/like`,
      {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
        params: {
          limit: 15,
          search,
          type: type || undefined,
        },
      },
    );

    if (status == 200) {
      if (response.error) throw new Error(`Bancos: ${response.message}`);

      const customers: Array<Customer> = response.data.map(Customer.make);

      const selectOptions = customers.map((customer) => {
        const {
          document, name, code, store,
        } = customer;
        return { value: document, text: `${code} ${store} - ${name}` };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos clientes.');
  }

  async getRemittances(
    data: IBillingRemittanceSearch,
    ids: Array<number>,
  ): Promise<{ list: Array<BillingRemittancePaymentMethod>; limit: number; approval: boolean }> {
    const { status, data: response } = await this.datasource.send('/chargeRemittance/list', data, {
      headers: {
        'company-ids': JSON.stringify(ids),
      },
    });

    if (status == 200) {
      if (response.error) throw new Error(`Remessas: ${response.message}`);

      const billingRemittance = response.data.map(BillingRemittancePaymentMethod.make);

      return {
        list: billingRemittance,
        limit: response.remittanceLimit || 0,
        approval: response?.billingApproval ? response?.body?.billingApproval == 'Y' : true,
      };
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das remessas de cobrança.');
  }

  async getDetails(
    id: string,
    date: string,
  ): Promise<Array<BillingRemittanceAccountReceivableDetail>> {
    const { status, data: response } = await this.datasource.get(
      `view/tituloreceber/${id}/${date}/0/0`,
    );

    if (status == 200) {
      if (response.error) { throw new Error(`Detalhes do título a receber ${id}: \n\n${response.message}`); }

      const details = response.data.map(BillingRemittanceAccountReceivableDetail.make);

      return details;
    }
    throw new Error(
      `Ops, algo inesperado aconteceu na listagem dos detalhes do título a receber (${id}).`,
    );
  }

  async getOccurrences(data: object): Promise<Array<BillingRemittanceAccountReceivableOccurrence>> {
    const { status, data: response } = await this.datasource.send(
      'bankremittancetransaction/listbydocument',
      data,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Ocorrências: \n\n${response.message}`);

      const occurrences = response.data.map(BillingRemittanceAccountReceivableOccurrence.make);

      return occurrences;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das ocorrências.');
  }

  async excludeFromRemittance(
    companyGroupId: number,
    cnabRemiGeneratedId: number,
    idCustomer: string,
    type: string,
  ): Promise<boolean> {
    const { status, data: response } = await this.datasource.destroy(
      `company-groups/${companyGroupId}/remittances/${cnabRemiGeneratedId}/${type}/${idCustomer}`,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Exclusão do título no borderô: \n\n${response.message}`);
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu na exclusão do título no borderô.');
  }

  async clearBankSlipData(
    companyGroupId: number,
    cnabRemiGeneratedId: number,
    idCustomer: string,
    type: string,
  ): Promise<void> {
    await this.datasource.destroy(
      `company-groups/${companyGroupId}/remittances/${cnabRemiGeneratedId}/${type}/${idCustomer}/transactions`,
    );
  }

  async getAllBanksConfig(companies: Array<number>): Promise<Array<BankConfig>> {
    const { status, data: response } = await this.datasource.get('bankconfig/listAllBanks/', {
      headers: {
        'company-ids': JSON.stringify(companies),
      },
    });

    if (status == 200) {
      if (response.error) throw new Error(`Configurações bancárias: ${response.message}`);
      if (response.total_records == 0) throw new Error('Nenhuma configuração bancária encontrada.');

      const data = response.data.map(BankConfig.make);

      const banksConfig = data.filter(
        (bankConfig: BankConfig) => bankConfig.tipo == 'RECEBER' && bankConfig.send_types.length > 0,
      );

      return banksConfig;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das configurações bancárias.');
  }

  async generateBordero(data: object): Promise<BillingRemittanceGenerate> {
    const { status, data: response } = await this.datasource.send(
      'cnabremittancegenerated/generateBorderoNumber',
      data,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Geração do borderô: \n\n${response.message}`);

      return BillingRemittanceGenerate.make(response);
    }
    throw new Error('Ops, algo inesperado aconteceu na geração do borderô.');
  }

  async generate(data: object): Promise<BillingRemittanceGenerate> {
    const { status, data: response } = await this.datasource.send(
      'cnabremittancegenerated/generate',
      data,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Geração da remessa: \n\n${response.message}`);

      return BillingRemittanceGenerate.make(response);
    }
    throw new Error('Ops, algo inesperado aconteceu na geração da remessa.');
  }

  async dispatchFile(companyGroupId: number, fileId: number, companyId: number): Promise<void> {
    const { status, data: response } = await this.datasource.send(
      `company-groups/${companyGroupId}/remittances/upload/${fileId}`,
      {},
    );

    if (status == 200) {
      if (response.error) throw new Error('Houve um problema ao enviar o arquivo para a NeoGrid, favor entre em contato');
      return;
    }
    throw new Error('Houve um problema ao enviar o arquivo para a NeoGrid, favor entre em contato');
  }
}
