























































import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import UserGroup from '@/domain/models/UserGroup';
import Company from '@/domain/models/Company';
import UserType from '@/domain/enums/UserType';
import UserRepository from '@/repositories/UserRepository';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import CompanyRepository from '@/repositories/CompanyRepository';
import IUserCreateParams from '@/repositories/parameters/IUserCreateParams';
import IUserCompaniesParams from '@/repositories/parameters/IUserCompaniesParams';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import CompanyGroupRepository from '@/repositories/CompanyGroupRepository';
import CompanyGroup from '@/domain/models/CompanyGroup';
import PageAction from '@/types/PageAction';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import ExistentUserException from '@/views/users/exceptions/ExistentUserException';
import ICompanyActivePair from './types/ICompanyActivePair';
import RegisterTab from './components/RegisterTab.vue';
import CompaniesTab from './components/CompaniesTab.vue';

@Component({
  components: {
    RegisterTab,
    CompaniesTab,
  },
})
export default class UserCreate extends Vue {
  userParameters: IUserCreateParams = {
    name: '',
    email: '',
    user_group_id: '',
    password: '',
    erp_code: '',
    url_powerbi: '',
    company_group_id: '',
    status: false,
  };
  userGroups: UserGroup[] = [];
  companyActivePairs: ICompanyActivePair[] = [];
  companyGroups: CompanyGroup[] = [];
  currentUserType: UserType = UserType.NOT_DEFINED;
  authStore?: AuthenticationModule;
  readonly currentPageAction: PageAction = PageAction.CREATE;
  readonly userRepository: UserRepository = new UserRepository();
  readonly userGroupRepository: UserGroupRepository = new UserGroupRepository();
  readonly companyRepository: CompanyRepository = new CompanyRepository();
  readonly companyGroupRepository: CompanyGroupRepository = new CompanyGroupRepository();

  get isOwner(): boolean {
    return (this.authStore && this.authStore!.isOwner) ?? false;
  }

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  mounted() {
    if (!this.isOwner) {
      this.userParameters.company_group_id = this.authStore!.user.company_group_id.toString();
    }

    const getUserGroups: Promise<DataWithPaginationInfo<UserGroup[]>> = this.userGroupRepository
      .getAll();
    const getCompanies: Promise<Company[]> = this.companyRepository.getAllActivie();
    const getCompanyGroups: Promise<CompanyGroup[]> = this.isOwner
      ? this.companyGroupRepository.getAll()
      : new Promise((res) => res([]));

    Promise.all([getUserGroups, getCompanies, getCompanyGroups])
      .then(([userGroups, companies, companyGroups]) => {
        this.$dialog.stopLoading();

        const { data: userGroupsData } = userGroups;
        this.userGroups = userGroupsData;

        this.companyActivePairs = companies.map((company) => ({
          company,
          active: false,
        }));
        this.companyGroups = companyGroups;
      })
      .catch((error) => {
        this.$dialog.stopLoading();
        this.$notification.error(error);
        this.$router.push({ name: 'UserList' });
      });
  }

  handleCancel() {
    this.$router.push({ name: 'UserList' });
  }

  async handleSave() {
    const companiesParameters: IUserCompaniesParams[] = this.companyActivePairs
      .filter((pair) => pair.active)
      .map((pair) => ({ company_id: pair.company.id.toString() }));

    try {
      await this.userRepository.checkIfExists(this.userParameters.email);
      await this.userRepository.save(this.userParameters, companiesParameters);
      this.$dialog.stopLoading();
      this.$notification.success('Usuário criado com sucesso!');
      this.$router.push({ name: 'UserList' });
    } catch (error) {
      let errorMessage = 'Houve um problema';

      if (error instanceof ExistentUserException) {
        errorMessage = 'O e-mail informado já é utilizado por outro usuário.';
      }

      this.$dialog.stopLoading();
      this.$notification.error(errorMessage);
    }
  }
}
