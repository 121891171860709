


































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';

@Component
export default class ButtonOptions extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: [],
  }) readonly options!: IButtonOption[];

  @Prop({
    type: String,
    required: false,
    default: null,
  }) readonly iconSymbol!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly offsetX!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly offsety!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly closeOnContentClick!: boolean;

  public handleOptionClick(selectedOption: string): void {
    this.$emit('selected-option', selectedOption);
  }

  public getClassBasedOnState(disabled: boolean): string {
    return disabled ? 'is-disabled' : '';
  }
}
