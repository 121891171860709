var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_vm._v(" Gerar Fatura ")]),_c('v-col',{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.companyOptions,"rules":[_vm.required],"label":"Empresa","placeholder":"Selecione uma emrpesa","loading":_vm.loading,"no-data-text":_vm.loading
                    ? 'Carregando empresas...'
                    : 'Não há dados disponíveis.',"clearable":"","filled":"","dense":""},on:{"change":function($event){return _vm.changedCompany()}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-autocomplete',{attrs:{"loading":_vm.loadingSuppliers,"items":_vm.suppliersOptions,"rules":[_vm.required],"label":"Fornecedores","placeholder":"Digite para buscar.","no-data-text":_vm.loadingSuppliers
                    ? 'Carregando fornecedores...'
                    : 'Nenhum fornecedor correspondente encontrado.',"clearable":"","filled":"","dense":""},on:{"update:search-input":_vm.debounceSuppliers},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('inn-date-field',{attrs:{"label":"Data de Vencimento","placeholder":"Selecione a data de vencimento","required":true,"buttons":false,"nudge-top":'25%'},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('inn-auto-complete-field-debounce',{attrs:{"search":_vm.paymentFormSearch,"items":_vm.paymentMethods,"loading":_vm.loadingPaymentForm,"rules":[_vm.inputRules.required()],"autocomplete":"off","label":"Forma de Pagamento","placeholder":"Selecione a forma de pagamento","custom-selection-slot":"","custom-item-slot":"","filled":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}]),model:{value:(_vm.paymentForm),callback:function ($$v) {_vm.paymentForm=$$v},expression:"paymentForm"}})],1)],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Filial")]),_c('th',[_vm._v("Portador")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.formatTitle(item, _vm.name)))]),_c('td',[_vm._v(_vm._s(item.branch))]),_c('td',[_vm._v(_vm._s(item.ported))]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(item.total)))])])}),0),_c('tfoot',[_c('tr',[_c('th',[_vm._v("Total Selecionados")]),_c('td',[_vm._v(_vm._s(_vm.items.length))]),_c('th',[_vm._v("Valor Total")]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.total)))])])])]},proxy:true}])})],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c('v-btn',{staticClass:"font-weight-medium",attrs:{"color":"warning","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")])],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c('v-btn',{staticClass:"font-weight-medium",attrs:{"color":"success","depressed":"","large":"","block":"","dark":""},on:{"click":_vm.save}},[_vm._v(" CONTINUAR ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }