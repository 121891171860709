import { DateTime } from 'luxon';
import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import PaymentRemittancesStatusType from '@/domain/enums/PaymentRemittancesStatusType';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import PaymentRemittance from '@/domain/models/PaymentRemittance';

function getKeyType(pix: string): KeyType | string {
  let type: string = '';

  const key = pix
    .replaceAll(' ', '')
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replaceAll('/', '')
    .replaceAll('(', '')
    .replaceAll(')', '');

  const isEmail = key.includes('@');
  const isPhone = key.charAt(0) === '+';
  const number = new RegExp('^[0-9]+$', 'g');

  if (key.length > 0) {
    if (isPhone) {
      type = 'PHONE';
    }

    if (isEmail) {
      type = 'EMAIL';
    }

    if (!isPhone && number.test(key)) {
      if (key.length >= 6 && key.length <= 11) {
        type = 'CPF';
      } else if (key.length >= 12 && key.length <= 14) {
        type = 'CNPJ';
      }
    }

    if (key.length >= 15 && !isPhone && !isEmail) {
      type = 'RANDOM';
    }
  }

  return type;
}

function getPixMask(item: PaymentRemittance) {
  let mask: RegExp | null = null;

  const type = getKeyType(item.pixKey);

  switch (type) {
    case 'PHONE':
      mask = /^(\+\d{2})(\d{2})(\d{5})(\d{4})$/;
      return item.pixKey.replace(mask, '$1 ($2) $3-$4');

    case 'CPF':
      mask = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
      return item.pixKey.replace(mask, '$1.$2.$3-$4');

    case 'CNPJ':
      mask = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
      return item.pixKey.replace(mask, '$1.$2.$3/$4-$5');

    case 'RANDOM': {
      if (item.pixKey.includes('.') || item.pixKey.includes('/')) {
        item.pixKey = item.pixKey
          .replaceAll(' ', '')
          .replaceAll('.', '')
          .replaceAll('-', '')
          .replaceAll('/', '')
          .replaceAll('(', '')
          .replaceAll(')', '');
      }

      mask = /^(.{8})(.{4})(.{4})(.{4})(.{12})$/;
      return item.pixKey.replace(mask, '$1-$2-$3-$4-$5');
    }

    case 'EMAIL': return item.pixKey;

    default:
      return '';
  }
}

export function formatCNPJ(value: string): string {
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

export function formatCPF(value: string): string {
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatCGC(data: Record<string, string>, value: string, field?: string) {
  if (value.length === 14) {
    data[field ?? 'CNPJ'] = formatCNPJ(value);
  } else {
    data[field ?? 'CPF'] = formatCPF(value);
  }
}

export function formatTitle(item: PaymentRemittance, name?: string): string {
  let title = '';

  title += name == 'TRADE' ? item.supplier_fantasy_name : item.supplier_name;
  title += item.branch ? ` - ${item.branch}` : '';
  title += item.prefix ? ` - ${item.prefix}` : '';
  title += ` - ${item.number}`;
  title += item.parcel ? ` - ${item.parcel}` : '';
  title += item.type ? ` - ${item.type}` : '';

  return title;
}

export function formatPaymentData(item: PaymentRemittance, groupConfig: CompanyGroupConfig) {
  const data: Record<string, string> = {};
  const { use_digag, use_digcta } = groupConfig;

  const barcodeType = [
    PaymentMethodType.BANK_SLIP,
    PaymentMethodType.CONCESSIONAIRE,
    PaymentMethodType.TRIBUTE,
  ];

  const pixType = [PaymentMethodType.PIX];

  const transferType = [
    PaymentMethodType.TED,
    PaymentMethodType.DOC,
    PaymentMethodType.TED_OTHER,
    PaymentMethodType.DOC_OTHER,
  ];

  const transferOtherType = [PaymentMethodType.TED_OTHER, PaymentMethodType.DOC_OTHER];

  const otherType = [PaymentMethodType.GPS, PaymentMethodType.DARF];

  if (barcodeType.includes(item.form_of_payment_type)) {
    return {
      barcode: item.barcode,
    };
  }

  if (pixType.includes(item.form_of_payment_type)) {
    if (item.pixType === '05') {
      data.Bco = item.supplier_bank;

      data.Ag = item.supplier_agency;
      data.Ag += use_digag && item.supplier_agency_digit ? `/${item.supplier_agency_digit}` : '';

      data.Cc = item.supplier_account_number;
      data.Cc += use_digcta && item.supplier_account_digit ? `/${item.supplier_account_digit}` : '';

      if (transferOtherType.includes(item.form_of_payment_type)) {
        formatCGC(data, item.supplier_z_document);
        if (item.supplier_z_document) data.Nome = item.supplier_z_name;
      } else {
        formatCGC(data, item.supplier_document);
      }

      return data;
    }

      data['Chave Pix'] = getPixMask(item);
  }

  if (otherType.includes(item.form_of_payment_type)) {
    if (
      item.code
      && item.account_payable_supplier_document
      && item.period
      && DateTime.fromFormat(item.period, 'yyyy-MM-dd').isValid
    ) {
      const isGPS = item.form_of_payment_type === PaymentMethodType.GPS;

      data['Código'] = item.code;

      const splitDate = item.period.split('-');
      if (isGPS) splitDate.pop();

      data[isGPS ? 'Competência' : 'Período'] = splitDate.reverse().join('/');

      formatCGC(data, item.account_payable_supplier_document, isGPS ? undefined : 'Identificador');
    }
  }

  if (transferType.includes(item.form_of_payment_type)) {
    data.Bco = item.supplier_bank;

    data.Ag = item.supplier_agency;
    data.Ag += use_digag && item.supplier_agency_digit ? `/${item.supplier_agency_digit}` : '';

    data.Cc = item.supplier_account_number;
    data.Cc += use_digcta && item.supplier_account_digit ? `/${item.supplier_account_digit}` : '';

    if (transferOtherType.includes(item.form_of_payment_type)) {
      formatCGC(data, item.supplier_z_document);
      if (item.supplier_z_document) data.Nome = item.supplier_z_name;
    } else {
      formatCGC(data, item.supplier_document);
    }
  }

  return data;
}

export function groupByRemittance(items: Array<PaymentRemittance>): Record<number, Array<string>> {
  const groups = items.reduce((previus, item) => {
    const cnabRemiGeneratedId = item.cnab_remi_generated_id ?? 0;

    return {
      ...previus,
      [cnabRemiGeneratedId]: [...(previus[cnabRemiGeneratedId] ?? []), item.id_customer],
    };
  }, {} as Record<number, Array<string>>);

  return groups;
}

export function hideDuplicateOverflow(self: any): void {
  self.$nextTick(() => {
    const doc = document.querySelector('html') || ({} as HTMLElement);
    doc.classList.add('overflow-y-hidden');
  });
}

export const statusToApproveOrReprove: Array<PaymentRemittancesStatusType> = [
  PaymentRemittancesStatusType.PENDING,
  PaymentRemittancesStatusType.PARTIALLY_APPROVED,
];

export function isStatusToApproveOrReprove(status: PaymentRemittancesStatusType): boolean {
  return statusToApproveOrReprove.includes(status);
}

export const statusToGenerateRemittance: Array<PaymentRemittancesStatusType> = [
  PaymentRemittancesStatusType.NOT_SENT,
  PaymentRemittancesStatusType.DISAPPROVED,
];

export function isStatusToGenerateRemittance(status: PaymentRemittancesStatusType): boolean {
  return statusToGenerateRemittance.includes(status);
}

export function hasPaymentData(item: PaymentRemittance, groupConfig: CompanyGroupConfig): boolean {
  const { use_digag, use_digcta } = groupConfig;

  const barcodeType = [
    PaymentMethodType.BANK_SLIP,
    PaymentMethodType.CONCESSIONAIRE,
    PaymentMethodType.TRIBUTE,
  ];

  const transferType = [
    PaymentMethodType.TED,
    PaymentMethodType.DOC,
    PaymentMethodType.TED_OTHER,
    PaymentMethodType.DOC_OTHER,
  ];

  const transferOtherType = [PaymentMethodType.TED_OTHER, PaymentMethodType.DOC_OTHER];

  const gpsOrDarfTypes = [
    PaymentMethodType.DARF,
    PaymentMethodType.GPS,
  ];

  if (barcodeType.includes(item.form_of_payment_type)) {
    if (!item.barcode) return false;
    if (item.form_of_payment === '008' && (!item.supplier_z_document || !item.supplier_z_name)) { return false; }
  }

  if (transferType.includes(item.form_of_payment_type)) {
    // const useDigAg = use_digag && item.supplier_agency_digit;
    const useDigCta = use_digcta && item.supplier_account_digit;

    if (
      !item.supplier_bank
      || !item.supplier_agency
      || !item.supplier_account_number
      || !useDigCta
    ) { return false; }

    if (transferOtherType.includes(item.form_of_payment_type)) {
      if (!item.supplier_z_document || !item.supplier_z_name) return false;
    } else if (!item.supplier_document) {
      return false;
    }
  }

  if (gpsOrDarfTypes.includes(item.form_of_payment_type)) {
    return !(
      !item.code
      || !item.account_payable_supplier_document
      || !item.period
      || !item.number_reference
    );
  }

  if (item.form_of_payment_type === PaymentMethodType.PIX) {
    return !!(item.pixKey);
  }

  return true;
}

export function makeBalance(item: PaymentRemittance): number {
  return Math.round((item.total + item.fees) * 100) / 100;
}
