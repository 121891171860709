














































































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

import StatementConciliation from '@/domain/models/StatementConciliation';

import ActionType from '@/domain/enums/StatementConciliationActionType';
import StatusType from '@/domain/enums/StatementConciliationStatusType';
import MovementType from '@/domain/enums/StatementConciliationMovementType';
import { ActionData } from '../utils/interfaces';

@Component({})
export default class StatementConciliationListAction extends Vue {
  @Prop({
    type: Object as () => StatementConciliation,
  }) item!: StatementConciliation;

  @Prop(Number) readonly writeOffPermission!: number;
  @Prop(Number) readonly reconcilePermission!: number;
  @Prop(Number) readonly generateAdvancePermission!: number;
  @Prop(String) readonly bankType!: string;

  @Emit()
  check(item: StatementConciliation): StatementConciliation {
    return item;
  }
  @Emit()
  action(option: string, item: StatementConciliation): ActionData {
    return { option, item };
  }

  readonly ActionType = ActionType;

  get status(): number {
    return this.item.status;
  }

  canWriteOff(): boolean {
    return (
      this.status === StatusType.NOT_FOUND_ERP
      && this.bankType === MovementType.CREDIT
      && this.writeOffPermission === 1
    );
  }

  canReconcile(): boolean {
    return (
      this.status === StatusType.NOT_CONCILIATED_ERP
      && this.reconcilePermission === 1
    );
  }

  showMenu(): boolean {
    return (
      this.status != StatusType.IGNORED
      && this.status != StatusType.PROCESSING
      && this.status != StatusType.PENDING_WITH_ERP
    );
  }

  showUndoConciliation(): boolean {
    return (
      this.status === StatusType.CONCILIATED
      || this.status === StatusType.ERP_CONCILIATED
      || this.status === StatusType.PARTIALLY_CONCILIATED
      || this.status === StatusType.DIVERGENCE
      || this.status === StatusType.FAILED_WITH_ERP
    );
  }

  showCreateMovement(): boolean {
    return (
      this.status === StatusType.NOT_PROCESSED
      || this.status === StatusType.NOT_FOUND_ERP
      || this.status === StatusType.NOT_CONCILIATED
    );
  }

  showAdvanceMovement(): boolean {
    return !!this.generateAdvancePermission
    && (
      this.status === StatusType.NOT_PROCESSED
      || this.status === StatusType.NOT_FOUND_ERP
      || this.status === StatusType.NOT_CONCILIATED
    ) && (
      this.item.movements[0].type === 'C'
      && this.item.movements[0].origin === 'bank'
    );
  }

  showSearchMovement(): boolean {
    return (
      this.status === StatusType.NOT_PROCESSED
      || this.status === StatusType.NOT_FOUND_ERP
      || this.status === StatusType.NOT_CONCILIATED
      || this.status === StatusType.NOT_CONCILIATED_ERP
    );
  }

  showWriteOff(): boolean {
    return (
      this.status !== StatusType.CONCILIATED
      && this.status !== StatusType.NOT_CONCILIATED_ERP
      && this.status !== StatusType.ERP_CONCILIATED
      && this.status !== StatusType.DIVERGENCE
      && this.status !== StatusType.FAILED_WITH_ERP
      && this.bankType === MovementType.CREDIT
      && this.writeOffPermission === 1
    );
  }

  showJustUndoConciliation(): boolean {
    return (
      this.showUndoConciliation()
      && !this.showCreateMovement()
      && !this.showSearchMovement()
      && !this.showWriteOff()
    );
  }
}
