




























































import {
  Component,
  Prop,
  Mixins,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import AccountReceivableBankSlipRepository from '@/repositories/AccountReceivableBankSlipRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import { VForm } from '@/types/VForm';
import DialogHelper from '@/helpers/DialogHelper';
import IGenerateBankSlipsByAccountsReceivableAndSendMailParams from '@/repositories/parameters/IGenerateBankSlipsByAccountsReceivableAndSendMailParams';

@Component
export default class GenerateBilletsEmailFieldDialog extends Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly accountReceivableBankSlipRepository: AccountReceivableBankSlipRepository =
    new AccountReceivableBankSlipRepository();

  public inputRulesHelper: InputRulesHelper = new InputRulesHelper();

  public mailToSendBillets: string = '';

  public model: boolean = this.value;
  public loading: boolean = false;

  @Prop({
    type: Array,
    required: true,
    default: () => ([]),
  }) readonly selectedSecuritiesIdCustomer!: string[];

  @Prop({
    type: Boolean,
    required: true,
    default: false,
  }) readonly isSelectingAllSecurities!: boolean;

  @Prop({
    type: Number,
    required: true,
  }) readonly companyId!: number;

  @Ref('SendBilletsToMailForm') readonly sendBilletsToMailForm!: VForm;

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
      smAndDown: '100%',
      md: '60%',
      default: '40%',
    });
  }

  public get idCustomerFromRoute(): string {
    return this.$route.params.idCustomer;
  }

  public closeDialog(reloadScreen: boolean = false): void {
    this.$emit('on-close', reloadScreen);
  }

  public handleCancel(): void {
    this.closeDialog();
  }

  public async handleConfim(): Promise<void> {
    if (!this.sendBilletsToMailForm.validate()) {
      this.$notification.error('O campo está inválido.');

      return;
    }

    try {
      this.loading = true;

      const params: IGenerateBankSlipsByAccountsReceivableAndSendMailParams = {
        is_selecting_all_securities: this.isSelectingAllSecurities,
        securities_ids_customer: this.selectedSecuritiesIdCustomer,
        mails: [this.mailToSendBillets],
      };

      if (this.idCustomerFromRoute) {
        params.id_customer = this.idCustomerFromRoute;
      }

      await this.accountReceivableBankSlipRepository.generateBilletsAndSendByMailBySpecificCustomer(
        this.companyGroupId,
        this.companyId,
        params,
      );

      this.closeDialog();
    } catch (error) {
      const erroMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar a geração dos boletos e envia-los por e-mail.');

      this.$notification.error(erroMessage);
    } finally {
      this.loading = false;
    }
  }
}
