var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":"Assinantes por Empresa"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-form',{staticClass:"width-100"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"sm":"12","md":"9","cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify-md":"start","justify-sm":"space-between"}},[_c('v-col',{attrs:{"sx":"12","sm":"10","md":"8","cols":"12"}},[_c('inn-text-field-debounce',{staticClass:"search-field",attrs:{"label":"Digite algum valor para a pesquisa","icon":"mdi-magnify","hide-details":""},on:{"on-finished-debounce":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"sm":"auto","md":"4","cols":"auto"}},[_c('button-filter-menu-options',{attrs:{"offset-y":"","tooltip-text":"Filtrar por","options":_vm.filterOptions,"show-clear-button":false,"defined-option":_vm.defaultColumnToSearch},on:{"on-change-value-in-option":_vm.handleFilterChange}})],1)],1)],1),_c('v-col',{attrs:{"xs":"12","md":"3","cols":"12"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"info"},on:{"click":_vm.handleCreate}},[_vm._v(" ADICIONAR NOVO ")])],1)],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.companySubscribersList,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.filterByColumn,"loading-text":"Carregando assinantes por empresa...","no-data-text":"Nenhum assinante por empresa encontrado.","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('status-chip',{attrs:{"value":value,"list":_vm.status}})]}},{key:"item.subscribers",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatSubscribers(value))+" ")]}},{key:"item.id",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('action-button',{attrs:{"label":"Editar","icon":"mdi-pencil","disabled":_vm.loading},on:{"click":function($event){return _vm.handleEdit(value)}}}),_c('action-button',{attrs:{"label":"Excluir","icon":"mdi-delete","disabled":_vm.loading},on:{"click":function($event){return _vm.handleDelete(item)}}})]}}])}),_c('delete-dialog',{attrs:{"company-subscribers":_vm.companySubscribers},on:{"close":_vm.handleClose},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }