export default class PaymentRemittancesSummaryBalance {
  constructor(
    public value: number,
    public sent: number,
    public approved: number,
    public fees: number,
    public discount: number,
    public addition: number,
    public decrease: number,
  ) {}

  static make(data: any): PaymentRemittancesSummaryBalance {
    const value = (data.sent ?? 0) + (data.approved ?? 0);
    return new PaymentRemittancesSummaryBalance(
      value,
      data.sent ?? 0,
      data.approved ?? 0,
      data.fees ?? 0,
      data.discount ?? 0,
      data.addition ?? 0,
      data.decrease ?? 0,
    );
  }
}
