



































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import FinancialStatement from '@/views/financialStatement/components/FinancialStatement.vue';
import EconomicGroupRepository from '@/repositories/EconomicGroupRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import EconomicGroup from '@/domain/models/EconomicGroup';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import IFiltersToConsiderOnFinancialStatement from '@/views/clients/interfaces/IFiltersToConsiderOnFinancialStatement';
import AccountReceivable from '@/domain/models/AccountReceivable';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import FinancialStatementChart from '@/domain/models/FinancialStatementChart';
import FileHelper from '@/helpers/FileHelper';
import AccountReceivableBankSlipRepository from '@/repositories/AccountReceivableBankSlipRepository';
import ISelectedAccountsReceivableWithAction from '@/views/financialStatement/interfaces/ISelectedAccountsReceivableWithAction';
import InvalidResponseException from '@/repositories/exceptions/InvalidResponseException';
import PrepareFileToDownloadException from '@/helpers/exceptions/PrepareFileToDownloadException';
import ISelectedSecuritiesParams from '@/views/financialStatement/interfaces/ISelectedSecuritiesParams';
import PreNegotiationOriginEnum from '@/views/preNegotiation/enums/PreNegotiationOriginEnum';

@Component({
  components: { FinancialStatement },
})
export default class FinancialStatementBillingPendenciesEconomicGroup extends Vue {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);
  private readonly economicGroupRepository: EconomicGroupRepository = new EconomicGroupRepository();
  private readonly accountReceivableBankSlipRepository
    : AccountReceivableBankSlipRepository = new AccountReceivableBankSlipRepository();
  private readonly accountsReceivableRepository
    : AccountsReceivableRepository = new AccountsReceivableRepository();

  public isLoading: boolean = false;
  public shouldResetAccountsSelection: boolean = false;

  public economicGroup: EconomicGroup|null = null;

  public accountsReceivableList: DataWithPaginationInfo<AccountReceivable[]> = {
    data: [],
    meta: {},
  };
  public accountsReceivableChart: FinancialStatementChart = {
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
    averageMaturity: 0,
    averageDelay: 0,
  };

  public get economicGroupName(): string {
    if (this.economicGroup) {
      return this.economicGroup.name;
    }

    return '';
  }

  public get economicGroupId(): number {
    return Number(this.$route.params.economicGroupId);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  private get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public created(): void {
    this.getEconomicGroupData();
  }

  public async getEconomicGroupData(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const economicGroup = await this.economicGroupRepository.getEconomicGroup(
        this.companyGroupId,
        this.companyIds,
        this.economicGroupId,
      );

      this.economicGroup = economicGroup;
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os dados do grupo econômico.');

      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getAccountsReceivableWithFiltersFromEconomicGroup(
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): Promise<DataWithPaginationInfo<AccountReceivable[]>> {
    return this.accountsReceivableRepository.getAccountReceivableListFromEconomicGroupWithFilters(
      this.companyGroupId,
      this.companyIds,
      this.economicGroup!.id,
      filters,
    );
  }

  public async getAccountsReceivableChartWithFiltersFromEconomicGroup(
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): Promise<FinancialStatementChart> {
    return this.accountsReceivableRepository
      .getAccountReceivableChartFromEconomicGroupWithFilters(
        this.companyGroupId,
        this.economicGroup!.companyId,
        this.economicGroup!.id,
        filters,
      );
  }

  public resetAccountsSelection(): void {
    this.shouldResetAccountsSelection = true;
  }

  public async handleGetAccountsReceivable(
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>|null,
  ): Promise<void> {
    try {
      this.isLoading = true;

      if (filters === null) {
        return;
      }

      const [
        list,
        chart,
      ] = await Promise.all([
        this.getAccountsReceivableWithFiltersFromEconomicGroup(filters),
        this.getAccountsReceivableChartWithFiltersFromEconomicGroup(filters),
      ]);

      if (list && chart) {
        this.accountsReceivableList = list;
        this.accountsReceivableChart = chart;
      }
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os valores de contas a receber do grupo econômico.');
    } finally {
      this.isLoading = false;
    }
  }

  public async handleGetBilletFromSelectedSecurities(
    {
      action,
      isSelectingAllSecurities,
      securitiesIdsCustomer,
    }: ISelectedAccountsReceivableWithAction,
  ): Promise<void> {
    this.$dialog.startLoading();

    try {
      const blobFile = await this.accountReceivableBankSlipRepository
        .downloadAccountReceivableBankSlip(
            this.companyGroupId,
            this.economicGroup!.companyId,
            {
              securities_ids_customer: securitiesIdsCustomer,
              is_selecting_all_securities: Number(isSelectingAllSecurities) as 0 | 1,
            },
        );

      const base64File = await FileHelper.blobToBase64(blobFile);

      if (action === 'download_duplicate_billet') {
        FileHelper.downloadFileByObjectUrl(base64File, 'segunda_via_boleto');

        return;
      }

      await FileHelper.openPrintScreenOfPdf(blobFile);
    } catch (error) {
      let errorMessage = 'Houve um problema ao fazer download do boleto bancário!';

      if (
        error instanceof InvalidResponseException
        || error instanceof PrepareFileToDownloadException
      ) {
        errorMessage = error.message;
      }

      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async handleSendAccountsReceivableToMail(
    params: ISelectedSecuritiesParams,
  ): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.accountsReceivableRepository.sendAccountsReceivableToCustomerMails(
        this.companyGroupId,
        this.economicGroup!.companyId,
        {
          is_selecting_all_securities: Boolean(params.isSelectingAllSecurities),
          securities_ids_customer: params.securitiesIdsCustomer,
        },
      );

      this.$notification.success('Envio do E-Mail enviado a fila!');
      this.resetAccountsSelection();
    } catch (error) {
      this.$notification.error('Houve um problema ao enviar o E-Mail de títulos.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleOpenPreNegotiationScreenFromSelectedAccounts(
    selectedSecurities: ISelectedSecuritiesParams,
  ): void {
    this.preNegotiationModule.setSelectedSecurites(selectedSecurities);
    this.preNegotiationModule.setOrigin(PreNegotiationOriginEnum.ECONOMIC_GROUP);

    this.$router.push({
      name: 'CreatePreNegotiation',
      params: {
        groupIdentifierValue: String(this.economicGroupId),
      },
    });
  }
}
