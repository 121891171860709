

















































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { toCurrency } from '@/utils/index';
import SettleBankReturnResumeTableTitleColumn from '@/views/CnabRemittanceGenerated/components/SettleBankReturnResumeTableTitleColumn.vue';
import ISettledBankAccountTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISettledBankAccountTransaction';

@Component({
  components: { SettleBankReturnResumeTableTitleColumn },
})
export default class SettleBankReturnResumeTableRowTemplate extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly rowItem!: ISettledBankAccountTransaction;

  @Prop({
    type: Number,
    required: true,
  }) readonly settledBanksReturnType!: 0 | 1;

  @Prop({
    type: String,
    required: true,
  }) readonly rowClass!: string;

  public getStatusIcon({ transactionStatus }: ISettledBankAccountTransaction): string {
    switch (transactionStatus) {
      case 'success': return 'mdi-check-outline';
      case 'fail': return 'mdi-close';
      default: return '';
    }
  }

  public getStatusColor({ transactionStatus }: ISettledBankAccountTransaction): string {
    switch (transactionStatus) {
      case 'success': return 'green darken-2';
      case 'fail': return 'red darken-2';
      default: return '';
    }
  }

  public toCurrency(value: string|number): string {
    return toCurrency(value, {}, true);
  }
}
