var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"PreNegotiationInstallmentsForm"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{staticClass:"number-input-without-arrows",attrs:{"type":"number","label":"Parcelas","filled":"","disabled":!_vm.canEdit,"rules":_vm.installmentQuantityRules},model:{value:(_vm.installmentQuantity),callback:function ($$v) {_vm.installmentQuantity=$$v},expression:"installmentQuantity"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Periodicidade das Parcelas","filled":"","items":_vm.installmentsPeriodicityOptions,"rules":[_vm.inputRules.required()],"disabled":!_vm.canEdit || _vm.hasDefinedPeriodicityOnPolitics},on:{"change":_vm.handlePeriodicityChange},model:{value:(_vm.installmentPeriodicity),callback:function ($$v) {_vm.installmentPeriodicity=$$v},expression:"installmentPeriodicity"}})],1),(_vm.isCustomPeriodicity)?_c('v-col',[_c('v-text-field',{staticClass:"number-input-without-arrows",attrs:{"type":"number","label":"Dias de intervalo entre as parcelas","filled":"","disabled":!_vm.canEdit,"rules":[_vm.inputRules.required(), _vm.inputRules.isNumber()]},model:{value:(_vm.installmentCustomPeriodicity),callback:function ($$v) {_vm.installmentCustomPeriodicity=$$v},expression:"installmentCustomPeriodicity"}})],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.availableHeaders,"items":_vm.installmentItems,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-plus","tooltip-text":"Adicionar Parcela"},on:{"click":_vm.handleInstallmentAddition}})]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.hasInstallmentItems)?_c('tbody',[_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.installment))]),_c('td',[(_vm.canEdit)?_c('date-picker-button',{attrs:{"date":item.dueDate,"dense":"","hide-details":"","label":"Escolha um vencimento"},on:{"on-date-selection":function($event){return _vm.handleInstallmentValueChange($event, item, 'due_date')}}}):_c('span',[_vm._v(" "+_vm._s(_vm.setDateToBrazilianFormat(item.dueDate))+" ")])],1),_c('td',[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":item.financialValue,"formatted-value-to-show":_vm.getMoneyFormat(item.financialValue),"type-of-value":"number","with-button":"","rules":[
                    _vm.inputRules.required(),
                    _vm.inputRules.isNumber(),
                    _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleInstallmentValueChange($event, item, 'value')}}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.financialValue))+" ")])],1),_c('td',[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":item.financialDecrease,"formatted-value-to-show":_vm.getMoneyFormat(item.financialDecrease),"type-of-value":"number","with-button":"","rules":[
                    _vm.inputRules.required(),
                    _vm.inputRules.isNumber(),
                    _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleInstallmentValueChange($event, item, 'decrease')}}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.financialDecrease))+" ")])],1),_c('td',[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":item.financialAddition,"formatted-value-to-show":_vm.getMoneyFormat(item.financialAddition),"type-of-value":"number","with-button":"","rules":[
                    _vm.inputRules.required(),
                    _vm.inputRules.isNumber(),
                    _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleInstallmentValueChange($event, item, 'increase')}}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.financialAddition))+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.getMoneyFormat(item.financialTotalValue)))]),(_vm.canEdit)?_c('td',[_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-close","tooltip-text":"Desconsiderar Parcela"},on:{"click":function($event){return _vm.handleExcludeInstallment()}}})],1):_vm._e()])}),_c('tr',[_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v("Total")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.installmentsTotalTable.totalValue))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.installmentsTotalTable.totalDecrease))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.installmentsTotalTable.totalAddition))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.installmentsTotalTable.totalValueWithChanges))+" ")]),(_vm.canEdit)?_c('td'):_vm._e()])],2):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_vm._v(" Preencha os campos acima ou adicione uma parcela manualmente. ")])])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }