import Dashboard from '@oldinncash/views/dashboard/dashboard.vue';
import CashFlow from '@oldinncash/views/cashflow/cashflow.vue';
import AdvancePayments from '@oldinncash/views/advancepayments/advancepayments.vue';

import Company from '@oldinncash/views/company/index.vue';
import NewCompany from '@oldinncash/views/company/newcompany.vue';
import EditCompany from '@oldinncash/views/company/editcompany.vue';
import CompanyConfiguration from '@oldinncash/views/company/companyconfiguration.vue';

import UserGroup from '@oldinncash/views/usergroup/index.vue';
import NewUserGroup from '@oldinncash/views/usergroup/newusergroup.vue';
import EditUserGroup from '@oldinncash/views/usergroup/editusergroup.vue';

import User from '@oldinncash/views/user/index.vue';
import NewUser from '@oldinncash/views/user/newuser.vue';
import EditUser from '@oldinncash/views/user/edituser.vue';
import Profile from '@oldinncash/views/user/profile.vue';

import CompanyGroup from '@oldinncash/views/companygroup/index.vue';
import NewCompanyGroup from '@oldinncash/views/companygroup/newcompanygroup.vue';
import EditCompanyGroup from '@oldinncash/views/companygroup/editcompanygroup.vue';

import Block from '@oldinncash/views/block/index.vue';
import NewBlock from '@oldinncash/views/block/newblock.vue';
import EditBlock from '@oldinncash/views/block/editblock.vue';

import Grouper from '@oldinncash/views/grouper/index.vue';
import NewGrouper from '@oldinncash/views/grouper/newgrouper.vue';
import EditGrouper from '@oldinncash/views/grouper/editgrouper.vue';

import GrouperIten from '@oldinncash/views/grouperiten/index.vue';
import NewGrouperIten from '@oldinncash/views/grouperiten/newgrouperiten.vue';
import EditGrouperIten from '@oldinncash/views/grouperiten/editgrouperiten.vue';

import AccessUserGroup from '@oldinncash/views/accessusergroup/index.vue';
import EditAccessUserGroup from '@oldinncash/views/accessusergroup/editaccessusergroup.vue';

import CnabRemittance from '@oldinncash/views/cnabremittance/index.vue';
import NewCnabRemittance from '@oldinncash/views/cnabremittance/new_cnab_remittance.vue';
import EditCnabRemittance from '@oldinncash/views/cnabremittance/edit_cnab_remittance.vue';

import CnabRemittanceConfig from '@oldinncash/views/cnabremittanceconfig/index.vue';
import NewCnabRemittanceConfig from '@oldinncash/views/cnabremittanceconfig/new_cnab_remittance_config.vue';
import EditCnabRemittanceConfig from '@oldinncash/views/cnabremittanceconfig/edit_cnab_remittance_config.vue';

import CnabRemittanceGenerated from '@oldinncash/views/cnabremittancegenerated/index.vue';

import CashFlowRealized from '@oldinncash/views/cashflowrealized/cashflowrealized.vue';
import CashFlowProjected from '@oldinncash/views/cashflowprojected/cashflowprojected.vue';
import CashFlowProjectedUNOESC from '@oldinncash/views/cashflowprojected-unoesc/cashflowprojected.vue';

import DdaConfig from '@oldinncash/views/ddaconfig/index.vue';
import NewDdaConfig from '@oldinncash/views/ddaconfig/newddaconfig.vue';

import DdaUpload from '@oldinncash/views/ddaupload/index.vue';
import DdaHistory from '@oldinncash/views/ddaconciliation/ddahistory.vue';
import DdaConciliation from '@oldinncash/views/ddaconciliation/index.vue';
import RemittancePayable from '@oldinncash/views/remittancepayable/remittancepayable.vue';

import PaymentMethod from '@oldinncash/views/paymentmethod/index.vue';
import NewPaymentMethod from '@oldinncash/views/paymentmethod/newpaymentmethod.vue';

import ConciliationConfig from '@oldinncash/views/conciliationconfig/listconciliationconfig.vue';
import NewConciliationConfig from '@oldinncash/views/conciliationconfig/newconciliationconfig.vue';
import ConciliationActionConfig from '@oldinncash/views/conciliationactionconfig/listconciliationactionconfig.vue';
import NewConciliationActionConfig from '@oldinncash/views/conciliationactionconfig/newconciliationactionconfig.vue';

import ConciliationUploadList from '@oldinncash/views/conciliationupload/List/index.vue';
import ConciliationUploadSave from '@oldinncash/views/conciliationupload/Save/index.vue';

import StatementConciliation from '@oldinncash/views/statementconciliation/statementconciliation.vue';
import StatementConciliationHistory from '@oldinncash/views/statementconciliation/statementconciliationhistory.vue';

import BankConfiguration from '@oldinncash/views/bankconfig/index.vue';
import SaveBankConfiguration from '@oldinncash/views/bankconfig/save.vue';

import BankretConfig from '@oldinncash/views/bankret/bankretconfig.vue';
import BankretConfigAdd from '@oldinncash/views/bankret/bankretconfigadd.vue';

import Ocurrences from '@oldinncash/views/ocurrences/List/ListOcurrences.vue';
import AddOccurrences from '@oldinncash/views/ocurrences/Save/AddOccurrences.vue';

import CnabReturnsUpload from '@oldinncash/views/cnabreturnsupload/List/CnabReturnsUploadList.vue';
import CnabReturnsSave from '@oldinncash/views/cnabreturnsupload/Save/CnabReturnSendFile.vue';

export default [
	{
		path: '/dashboard', customName: 'Dashboard', name: 'dashboard', component: Dashboard, meta: { title: 'Dashboard', link2: '' },
	},
	{
		path: '/cashflow', customName: 'CashFlow', name: 'cashflow', component: CashFlow, meta: { title: 'Fluxo de caixa', link2: '' },
	},
	{
		path: '/advancepayments', customName: 'AdvancePayments', name: 'advancepayments', component: AdvancePayments, meta: { title: 'Solicitações de Adiantamento a Fornecedores', link2: '' },
	},
	{
		path: '/user/profile', name: 'profile', component: Profile, meta: { title: 'Meu Perfil', link2: '' },
	},
	{
		path: '/company', name: 'company', component: Company, meta: { title: 'Empresa', link2: '' },
	},
	{
		path: '/company/new', name: 'newcompany', component: NewCompany, meta: { title: 'Cadastro de empresa', link2: 'company' },
	},
	{
		path: '/company/edit/:company_id', name: 'editcompany', component: EditCompany, props: true, meta: { title: 'Alteração de empresa', link2: 'company' },
	},
	{
		path: '/companyconfiguration', name: 'companyconfiguration', component: CompanyConfiguration, meta: { title: 'Configuração de Empresa', link2: '' },
	},
	{
		path: '/usergroup', name: 'usergroup', component: UserGroup, meta: { title: 'Tipos de usuário', link2: '' },
	},
	{
		path: '/usergroup/new', name: 'newusergroup', component: NewUserGroup, meta: { title: 'Cadastro de tipo de usuário', link2: 'usergroup' },
	},
	{
		path: '/usergroup/edit/:user_group_id', name: 'editusergroup', component: EditUserGroup, props: true, meta: { title: 'Alteração de tipo de usuário', link2: 'usergroup' },
	},
	{
		path: '/user', name: 'user', component: User, meta: { title: 'Usuários', link2: '' },
	},
	{
		path: '/user/new', name: 'newuser', component: NewUser, meta: { title: 'Cadastro de usuário', link2: 'user' },
	},
	{
		path: '/user/edit/:user_id', name: 'edituser', component: EditUser, props: true, meta: { title: 'Alteração de usuário', link2: 'user' },
	},
	{
		path: '/companygroup', name: 'companygroup', component: CompanyGroup, meta: { title: 'Grupo de empresas', link2: '' },
	},
	{
		path: '/companygroup/new', name: 'newcompanygroup', component: NewCompanyGroup, meta: { title: 'Cadastro de grupo de empresas', link2: 'companygroup' },
	},
	{
		path: '/companygroup/edit/:company_group_id', name: 'editcompanygroup', component: EditCompanyGroup, props: true, meta: { title: 'Alteração de grupo de empresas', link2: 'companygroup' },
	},
	{
		path: '/block', name: 'block', component: Block, meta: { title: 'Bloqueios', link2: '' },
	},
	{
		path: '/block/new', name: 'newblock', component: NewBlock, meta: { title: 'Cadastro de bloqueios', link2: 'block' },
	},
	{
		path: '/block/edit/:block_id', name: 'editblock', component: EditBlock, props: true, meta: { title: 'Alteração de bloqueios', link2: 'block' },
	},
	{
		path: '/grouper', name: 'grouper', component: Grouper, meta: { title: 'Agrupadores', link2: '' },
	},
	{
		path: '/grouper/new', name: 'newgrouper', component: NewGrouper, meta: { title: 'Cadastro de agrupador', link2: 'grouper' },
	},
	{
		path: '/grouper/edit/:grouper_id', name: 'editgrouper', component: EditGrouper, props: true, meta: { title: 'Alteração de agrupador', link2: 'grouper' },
	},
	{
		path: '/grouperiten', name: 'grouperiten', component: GrouperIten, meta: { title: 'Agrupadores Nível 2', link2: '' },
	},
	{
		path: '/grouperiten/new', name: 'newgrouperiten', component: NewGrouperIten, meta: { title: 'Cadastro de agrupador nível 2', link2: 'grouperiten' },
	},
	{
		path: '/grouperiten/edit/:grouper_iten_id', name: 'editgrouperiten', component: EditGrouperIten, props: true, meta: { title: 'Alteração de agrupador nível 2', link2: 'grouperiten' },
	},
	{
		path: '/accessusergroup', name: 'accessusergroup', component: AccessUserGroup, meta: { title: 'Acessos do tipo de usuário', link2: '' },
	},
	{
		path: '/accessusergroup/:user_group_id', name: 'editaccessusergroup', component: EditAccessUserGroup, props: true, meta: { title: 'Acessos do tipo de usuário', link2: '' },
	},
	{
		path: '/cnabremittance', name: 'cnabremittance', component: CnabRemittance, meta: { title: 'CNAB', link2: '' },
	},
	{
		path: '/cnabremittance/new', name: 'newcnabremittance', component: NewCnabRemittance, meta: { title: 'Cadastro de CNAB', link2: '' },
	},
	{
		path: '/cnabremittance/edit/:cnab_remittance_id', name: 'editcnabremittance', component: EditCnabRemittance, meta: { title: 'Alteração de CNAB', link2: '' },
	},
	{
		path: '/cnabremittanceconfig', name: 'cnabremittanceconfig', component: CnabRemittanceConfig, meta: { title: 'CNAB', link2: '' },
	},
	{
		path: '/cnabremittanceconfig/new', name: 'newcnabremittanceconfig', component: NewCnabRemittanceConfig, meta: { title: 'CNAB', link2: '' },
	},
	{
		path: '/cnabremittanceconfig/edit/:cnab_remittance_config_id', name: 'editcnabremittanceconfig', component: EditCnabRemittanceConfig, meta: { title: 'CNAB', link2: '' },
	},
	{
		path: '/cnabremittancegenerated', name: 'cnabremittancegenerated', component: CnabRemittanceGenerated, meta: { title: 'Remessas Cnab Geradas', link2: '' },
	},
	{
		path: '/cashflowrealized', customName: 'CashFlow Realized', name: 'cashflowrealized', component: CashFlowRealized, meta: { title: 'Fluxo de Caixa Realizado', link2: '' },
	},
	{
		path: '/cashflowprojected', customName: 'CashFlow Projected', name: 'cashflowprojected', component: CashFlowProjected, meta: { title: 'Fluxo de Caixa Projetado', link2: '' },
	},
	{
		path: '/unoesc/cashflowprojected', customName: 'CashFlow Projected', name: 'cashflowprojectedunoesc', component: CashFlowProjectedUNOESC, meta: { title: 'Fluxo de Caixa Projetado', link2: '' },
	},
	{
		path: '/ddaconfig', name: 'ddaconfig', component: DdaConfig, meta: { title: 'Configuração DDA', link2: '' },
	},
	{
		path: '/ddaconfig/:dda_config_id', name: 'newddaconfig', component: NewDdaConfig, meta: { title: 'Configuração DDA', link2: '' },
	},
	{
		path: '/ddaupload', customName: 'Envio DDA', name: 'ddaupload', component: DdaUpload, meta: { title: 'Envio DDA', link2: '' },
	},
	{
		path: '/ddahistory', customName: 'Histórico DDA', name: 'ddahistory', component: DdaHistory, meta: { title: 'Histórico DDA', link2: '' },
	},
	{
		path: '/ddaconciliation', customName: 'Conciliação DDA', name: 'ddaconciliation', component: DdaConciliation, meta: { title: 'Conciliação DDA', link2: '' },
	},
	{
		path: '/remittancepayable', customName: 'Remessa a Pagar', name: 'remittancepayable', component: RemittancePayable, meta: { title: 'Remessa a Pagar', link2: '' },
	},
	{
		path: '/paymentmethod', name: 'paymentmethod', component: PaymentMethod, meta: { title: 'Formas de Pagamento', link2: '' },
	},
	{
		path: '/paymentmethod/:id', name: 'newpaymentmethod', component: NewPaymentMethod, meta: { title: 'Cadastro de Forma de Pagamento', link2: 'paymentmethod' },
	},
	{
		path: '/conciliationconfig', name: 'conciliationconfig', component: ConciliationConfig, meta: { title: 'Configuração Conciliação Bancária', link2: '' },
	},
	{
		path: '/conciliationconfig/:conciliation_config_id', name: 'newconciliationconfig', component: NewConciliationConfig, meta: { title: 'Configuração Conciliação Bancária', link2: '' },
	},
	{
		path: '/conciliationactionconfig', name: 'conciliationactionconfig', component: ConciliationActionConfig, meta: { title: 'Configuração das Ações da Conciliação Bancária', link2: '' },
	},
	{
		path: '/conciliationactionconfig/:conciliation_action_config_id', name: 'newconciliationactionconfig', component: NewConciliationActionConfig, meta: { title: 'Ação da Conciliação Bancária', link2: '' },
	},
	{
		path: '/conciliationupload', customName: 'Envio Extrato Bancário', name: 'conciliationupload', component: ConciliationUploadSave, meta: { title: 'Envio Extrato Bancário', link2: '' },
	},
	{
		path: '/conciliationupload/list', name: 'conciliationuploadList', customName: 'Listagem de arquivos de Extrato Bancário', component: ConciliationUploadList, meta: { title: 'Listagem de arquivos de Extrato Bancário', link2: '' },
	},
	{
		path: '/statementconciliation', customName: 'Conciliação Extrato Bancário', name: 'statementconciliation', component: StatementConciliation, meta: { title: 'Conciliação Extrato Bancário', link2: '' },
	},
	{
		path: '/statementconciliationhistory', customName: 'Histórico Conciliação Bancária', name: 'statementconciliationhistory', component: StatementConciliationHistory, meta: { title: 'Histórico Conciliação Bancária', link2: '' },
	},
	{
		path: '/bankconfig', name: 'bankconfig', customName: 'Configurações Bancárias', component: BankConfiguration, meta: { title: 'Configurações Bancárias', link2: '' },
	},
	{
		path: '/bankconfig/:edit', name: 'savebankconfig', customName: 'Edicao/Cadastro conf bancaria', component: SaveBankConfiguration, meta: { title: 'Configurações Bancárias', link2: '' },
	},
	{
		path: '/bankret', name: 'bankretconfig', customName: 'Configurações de retorno bancário', component: BankretConfig, meta: { title: 'Configurações de Retorno Bancário', link2: '' },
	},
	{
		path: '/bankret/edit', name: 'bankretconfigadd', customName: 'Configurações de retorno bancário', component: BankretConfigAdd, meta: { title: 'Configurações de Retorno Bancário', link2: '' },
	},
	{
		path: '/occurrences', name: 'Occurrences', customName: 'Configurações de ocorrências', component: Ocurrences,
	},
	{
		path: '/occurrences/:id', name: 'EditOccurrences', customName: 'Edição de configurações de ocorrências', component: AddOccurrences,
	},

	{
		path: 'manualreturns/list', name: 'CnabReturns', customName: 'Listagem de arquivos de cnab', component: CnabReturnsUpload,
	},

	{
		path: '/manualreturns', name: 'CnabReturnsSend', customName: 'Envio de arquivos de cnab', component: CnabReturnsSave,
	},
];
