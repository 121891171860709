



















































































import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import { formatErrorForNotification } from '@/utils/error';

import PayrollRepository from '@/repositories/PayrollRepository';

interface IReceipt {
  open: boolean,
  id: number,
}

@Component
export default class PayrollHistoryReceipt
  extends Mixins<ModelManagementMixin<IReceipt>>(ModelManagementMixin) {
  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  close() {
    this.model.open = false;
    this.model.id = 0;
  }

  readonly repository = new PayrollRepository();
  loading: boolean = false;

  async download(type: 'together' | 'separate' | 'compress') {
    try {
      this.loading = true;
      const group = this.$session.get('company_group_id');

      const response = await this.repository.downloadReceipt(
        group,
        this.companies,
        this.model.id,
        type,
      );

      const blobType = type === 'compress' ? 'application/zip' : 'application/pdf';

      const blob = new Blob([response], { type: blobType });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', '');
      link.click();

      this.close();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
