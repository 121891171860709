



























import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import FinancialStatement from '@/views/financialStatement/components/FinancialStatement.vue';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import ClientRepository from '@/repositories/ClientRepository';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import AccountReceivableBankSlipRepository from '@/repositories/AccountReceivableBankSlipRepository';
import AccountReceivable from '@/domain/models/AccountReceivable';
import FinancialStatementChart from '@/domain/models/FinancialStatementChart';
import ISelectedSecuritiesParams from '@/views/financialStatement/interfaces/ISelectedSecuritiesParams';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import IFiltersToConsiderOnFinancialStatement from '@/views/clients/interfaces/IFiltersToConsiderOnFinancialStatement';
import IFiltersFromFinancialStatementCustomerParams from '@/repositories/parameters/IFiltersFromFinancialStatementCustomerParams';
import AccountReceivableChart from '@/domain/models/AccountReceivableChart';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import FinancialStatementBillingOriginType from '@/views/financialStatement/types/FinancialStatementBillingOriginType';
import Client from '@/domain/models/Client';

@Component({
  components: { FinancialStatement },
})
export default class FinancialStatementBillingPendenciesCustomer extends Vue {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);
  private readonly accountsReceivableRepository
    : AccountsReceivableRepository = new AccountsReceivableRepository();
  private readonly accountReceivableBankSlipRepository
    : AccountReceivableBankSlipRepository = new AccountReceivableBankSlipRepository();
  public readonly clientRepository
    : ClientRepository = new ClientRepository();

  public customer: Client|null = null;

  public isLoading: boolean = false;
  public shouldResetAccountsSelection: boolean = false;

  public accountsReceivableList: IDataTableWithLength<AccountReceivable[]> = {
    data: [],
    total: 0,
  };
  public accountsReceivableChart: FinancialStatementChart = {
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
    averageMaturity: 0,
    averageDelay: 0,
  };

  public get idCustomerOrDocumentRoot(): string {
    return this.$route.params.idCustomerOrDocument;
  }

  public get billingOrigin(): FinancialStatementBillingOriginType {
    return this.$route.params.origin as FinancialStatementBillingOriginType;
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public get customerName(): string {
    if (!this.customer) {
      return '';
    }

    return this.customer.tradeName;
  }

  public created(): void {
    if (this.billingOrigin === 'customer') {
      this.getCustomerByIdCustomer();
    } else {
      this.getCustomerByDocumentRoot();
    }
  }

  public async getCustomerByIdCustomer(): Promise<void> {
    try {
      const customer = await this.clientRepository.getClientById(
        this.idCustomerOrDocumentRoot,
        this.companyGroupId,
        this.companyIds,
      );

      this.customer = customer;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getCustomerByDocumentRoot(): Promise<void> {
    try {
      const customer = await this.clientRepository.getCustomerByDocumentRoot(
        this.idCustomerOrDocumentRoot,
        this.companyGroupId,
        this.companyIds,
      );
      this.customer = customer;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getAccountsReceivableListFromCustomerOrDocumentRoot(
    filters: ILaravelOrdenationTableParams<IFiltersFromFinancialStatementCustomerParams>,
  ): Promise<IDataTableWithLength<AccountReceivable[]>> {
    return this.accountsReceivableRepository.getDueAccountsReceivablePaginatedListByFilters(
      this.companyGroupId,
      this.companyIds,
      filters,
    );
  }

  public async getAccountsReceivableChartFromCustomerOrDocumentRoot(
    filters: IFiltersFromFinancialStatementCustomerParams,
  ): Promise<AccountReceivableChart> {
    return this.accountsReceivableRepository.getDueAccountsReceivableChartsByFilters(
      this.companyGroupId,
      this.companyIds,
      filters,
    );
  }

  public resetAccountsSelection(): void {
    this.shouldResetAccountsSelection = true;
  }

  public async handleGetAccountsReceivable(
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>|null,
  ): Promise<void> {
    try {
      this.isLoading = true;

      if (filters === null) {
        return;
      }

      const formattedFilter: IFiltersFromFinancialStatementCustomerParams = {
        id_customer_or_document_root: this.idCustomerOrDocumentRoot,
        origin: this.billingOrigin,
        number: filters.filter.number,
        start_date_emission: filters.filter.start_date_emission,
        end_date_emission: filters.filter.end_date_emission,
        start_date_actual_expiration: filters.filter.start_date_actual_expiration,
        end_date_actual_expiration: filters.filter.end_date_actual_expiration,
      };
      const formattedFilterWithOrdenation: ILaravelOrdenationTableParams<
        IFiltersFromFinancialStatementCustomerParams> = {
        filter: formattedFilter,
        items_per_page: filters.items_per_page,
        page: filters.page,
        sort: filters.sort,
      };

      const [
        list,
        chart,
      ] = await Promise.all([
        this.getAccountsReceivableListFromCustomerOrDocumentRoot(formattedFilterWithOrdenation),
        this.getAccountsReceivableChartFromCustomerOrDocumentRoot(formattedFilter),
      ]);

      if (list && chart) {
        this.accountsReceivableList = list;
        this.accountsReceivableChart = chart;
      }
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os valores de contas a receber do grupo econômico.');
    } finally {
      this.isLoading = false;
    }
  }

  public handleOpenPreNegotiationScreenFromSelectedAccounts(
    selectedSecurities: ISelectedSecuritiesParams,
  ): void {
    this.preNegotiationModule.setSelectedSecurites(selectedSecurities);
    this.preNegotiationModule.setOrigin(this.billingOrigin);

    this.$router.push({
      name: 'CreatePreNegotiation',
      params: {
        groupIdentifierValue: this.idCustomerOrDocumentRoot,
      },
    });
  }
}
