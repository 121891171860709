var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showMenu && !_vm.showJustUndoConciliation)?_c('v-menu',{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}],null,false,511091820)},[_c('v-list',[(_vm.showResolve)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.RESOLVE)}}},[_c('v-list-item-title',[_vm._v(" Conciliar ")])],1):_vm._e(),(_vm.showInvoice)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.INVOICE)}}},[_c('v-list-item-title',[_vm._v(" Gerar Fatura ")])],1):_vm._e(),(_vm.showViewInvoice)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.VIEW_INVOICE)}}},[_c('v-list-item-title',[_vm._v(" Visualizar Fatura ")])],1):_vm._e(),(_vm.showSearch)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.SEARCH_ERP)}}},[_c('v-list-item-title',[_vm._v(" Procurar Título ")])],1):_vm._e(),(_vm.hasPermissionToGenerateAccountPayable && _vm.showInvoice)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.GENERATE_INVOICE)}}},[_c('v-list-item-title',[_vm._v(" Gerar Título a Pagar ")])],1):_vm._e(),(_vm.showSearchDDA)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.SEARCH_DDA)}}},[_c('v-list-item-title',[_vm._v(" Procurar DDA ")])],1):_vm._e(),(_vm.showRegisterBarcode)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.BARCODE)}}},[_c('v-list-item-title',[_vm._v(" Cadastrar Cód. Barras ")])],1):_vm._e(),(_vm.showUndoConciliation)?_c('v-list-item',{on:{"click":function($event){return _vm.action(_vm.actionType.UNDO)}}},[_c('v-list-item-title',[_vm._v(" Desfazer Conciliação ")])],1):_vm._e()],1)],1):_vm._e(),(_vm.showJustUndoConciliation)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.action(_vm.actionType.UNDO)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,false,471307702)},[_c('span',[_vm._v("Desfazer Conciliação")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }