import PageContainer from './components/layout/PageContainer.vue';
import PageHeader from './components/layout/PageHeader.vue';
import PageContent from './components/layout/PageContent.vue';
import PageCard from './components/layout/PageCard.vue';
import PageTabsContent from './components/layout/PageTabsContent.vue';

import InnButton from './components/buttons/InnButton.vue';
import InnIconButton from './components/buttons/InnIconButton.vue';
import InnAddButton from './components/buttons/InnAddButton.vue';
import InnEditButton from './components/buttons/InnEditButton.vue';
import ButtonTooltip from './components/buttons/ButtonTooltip.vue';
import ButtonOptions from './components/buttons/ButtonOptions.vue';

import InnTextField from './components/fields/InnTextField.vue';
import InnTextFieldDebounce from './components/fields/InnTextFieldDebounce.vue';
import InnAutoCompleteFieldDebounce from './components/fields/InnAutoCompleteFieldDebounce.vue';
import InnRadioGroup from './components/fields/InnRadioGroup.vue';
import InnSelect from './components/fields/InnSelect.vue';
import InnTextarea from './components/fields/InnTextarea.vue';
import InnTimeField from './components/fields/InnTimeField.vue';
import InnDateField from './components/fields/InnDateField.vue';
import DatePickerButton from './components/fields/DatePickerButton.vue';
import InnDateRangeField from './components/fields/InnDateRangeField.vue';

import InnCheckbox from './components/controls/InnCheckbox.vue';
import InnSwitch from './components/controls/InnSwitch.vue';

import InnChip from './components/misc/InnChip.vue';
import Breadcrumbs from './components/misc/Breadcrumbs.vue';

const plugin = {
  install(Vue) {
    Vue.component('breadcrumbs', Breadcrumbs);
    Vue.component('page-container', PageContainer);
    Vue.component('page-header', PageHeader);
    Vue.component('page-content', PageContent);
    Vue.component('page-card', PageCard);
    Vue.component('page-tabs-content', PageTabsContent);

    Vue.component('inn-text-field', InnTextField);
    Vue.component('inn-text-field-debounce', InnTextFieldDebounce);
    Vue.component('inn-auto-complete-field-debounce', InnAutoCompleteFieldDebounce);
    Vue.component('inn-radio-group', InnRadioGroup);
    Vue.component('inn-select', InnSelect);
    Vue.component('inn-btn', InnButton);
    Vue.component('button-tooltip', ButtonTooltip);
    Vue.component('button-options', ButtonOptions);
    Vue.component('inn-icon-btn', InnIconButton);
    Vue.component('inn-edit-btn', InnEditButton);
    Vue.component('inn-add-btn', InnAddButton);
    Vue.component('inn-textarea', InnTextarea);
    Vue.component('inn-time-field', InnTimeField);
    Vue.component('inn-date-field', InnDateField);
    Vue.component('date-picker-button', DatePickerButton);
    Vue.component('inn-date-range-field', InnDateRangeField);
    Vue.component('inn-chip', InnChip);
    Vue.component('inn-checkbox', InnCheckbox);
    Vue.component('inn-switch', InnSwitch);
  },
};

export default plugin;
