enum PaymentRemittancesActionType {
  ADD_COMMENT = 'add-comment',
  APPROVE = 'approve',
  CHANGE_METHOD = 'change-method',
  DISAPPROVE = 'disapprove',
  EDIT = 'edit',
  GENERATE_INVOICE = 'generate-invoice',
  GENERATE_REMITTANCE = 'generate-remittance',
  GENERATE_REPORT = 'generate-report',
  REMOVE = 'remove',
  REMOVE_INVOICE = 'remove-invoice',
  VIEW_DETAILS = 'view-details',
  VIEW_INVOICE = 'view-invoice',
}

export default PaymentRemittancesActionType;
