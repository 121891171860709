var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider'),_c('v-card-text',{staticClass:"p-0"},[_c('v-data-table',{staticClass:"w-100 overflow-x",attrs:{"expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.items,"item-key":"id","hide-default-header":"","hide-default-footer":"","disable-pagination":"","disable-filtering":"","show-expand":"","dense":""},on:{"click:row":_vm.expandItem,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var sort = ref.on.sort;
var ref_props_options = ref.props.options;
var sortBy = ref_props_options.sortBy;
var sortDesc = ref_props_options.sortDesc;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',{attrs:{"role":"columnheader","scope":"col"}},_vm._l((_vm.headers),function(ref){
var value = ref.value;
var text = ref.text;
return _c('th',{key:value,staticClass:"custom-sortable",on:{"click":function($event){return sort(value)}}},[(value === 'data-table-expand')?_c('div',[_c('toggle-button',{attrs:{"value":_vm.expand},on:{"click":_vm.toggleexpand}})],1):(value === 'column')?_c('div',{staticClass:"d-flex align-center justify-end text-right"},[_c('dynamic-column-menu',{attrs:{"group-enum":_vm.groupFilterParametersEnum.PAYMENT_REMITTANCES,"correlation-between-keys-and-headers":_vm.headerKeys},model:{value:(_vm.availableHeaders),callback:function ($$v) {_vm.availableHeaders=$$v},expression:"availableHeaders"}})],1):(value === 'title')?_c('div',{staticClass:"d-flex align-center justify-start text-left"},[_c('span',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(text)+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")])],1):_c('div',{class:sortBy[0] == value
                  ? 'active d-flex align-center justify-start text-left'
                  : 'd-flex align-center justify-start text-left'},[_c('div',{staticClass:"d-flex flex-column subtitle-2 font-weight-medium"},[_c('span',[_vm._v(_vm._s(text))]),_c('span',[_vm._v("R$ "+_vm._s(_vm.formatCurrency(_vm.totalValueOf(value))))])]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")])],1)])}),0)])]}},{key:"item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
                  var expand = ref.expand;
                  var isExpanded = ref.isExpanded;
return [_c('tr',{staticClass:"table-row-item",on:{"click":function($event){return _vm.expandItem(item, { expand: expand, isExpanded: isExpanded })}}},_vm._l((headers),function(header,index){return _c('td',{key:item.id + header.value + index + 'item'},[(header.value === 'data-table-expand')?_c('toggle-button',{attrs:{"value":isExpanded}}):(header.value === 'title')?_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.bank)+" "+_vm._s(item.agency)+" "+_vm._s(item.account)+" ")]):(header.value === 'column')?_c('span',{staticClass:"d-block text-right"}):_c('span',[_vm._v(" R$ "+_vm._s(_vm.formatCurrency(item.bankBalance[header.value] - item.paymentBalance[header.value]))+" ")])],1)}),0)]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('tr',{staticClass:"table-row-item-expand"},_vm._l((headers),function(header,index){return _c('td',{key:item.id + header.value + index + 'expanded-item',staticStyle:{"padding":"12px  0 !important"}},[(header.value === 'data-table-expand')?_c('span'):(header.value === 'title')?_c('span',{staticClass:"d-block pl-3"},[_vm._v(" Saldo Bancário "),_c('v-divider',{staticClass:"my-3"}),_vm._v(" Pagamentos ")],1):(header.value === 'column')?_c('span',{staticClass:"d-block text-right pr-3"},[_c('menu-custom',{attrs:{"type":"bank","item":item},on:{"action":_vm.openAction,"close":_vm.closeAction}}),_c('v-divider',{staticClass:"my-3"}),_c('menu-custom',{attrs:{"type":"payment","item":item},on:{"action":_vm.openAction,"close":_vm.closeAction}})],1):_c('span',[_c('span',{staticClass:"px-3"},[_vm._v(" R$ "+_vm._s(_vm.formatCurrency(item.bankBalance[header.value]))+" ")]),_c('v-divider',{staticClass:"my-3"}),_c('span',{staticClass:"px-3"},[_vm._v(" R$ "+_vm._s(_vm.formatCurrency(item.paymentBalance[header.value]))+" ")])],1)])}),0)]}},(!_vm.expand)?{key:"body",fn:function(){return undefined},proxy:true}:null],null,true)})],1),_c('balance',{attrs:{"open":_vm.open[_vm.ActionType.EDIT],"item":_vm.item},on:{"close":_vm.closeAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }