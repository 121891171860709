import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import AccountReceivable from '@/domain/models/AccountReceivable';
import AccountReceivableChart from '@/domain/models/AccountReceivableChart';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import IFiltersToConsiderOnFinancialStatement from '@/views/clients/interfaces/IFiltersToConsiderOnFinancialStatement';
import FinancialStatementChart from '@/domain/models/FinancialStatementChart';
import ISendAccountsReceivableToCustomerMailsParams from '@/repositories/parameters/ISendAccountsReceivableToCustomerMailsParams';
import IFiltersFromFinancialStatementCustomerParams from '@/repositories/parameters/IFiltersFromFinancialStatementCustomerParams';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import IUpdateAccountsReceivableObservationParams from './parameters/IUpdateAccountsReceivableObservationParams';

export default class AccountsReceivableRepository extends Repository {
  public async getAccountsReceivablePaginatedListByFilters(
    companyGroupId: number,
    companyIds: number[],
    idCustomer: string,
    dataTableConfig: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): Promise<DataWithPaginationInfo<AccountReceivable[]>> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-receivable/financial-statements/${idCustomer}`,
      {
        params: dataTableConfig,
        paramsSerializer: qs.stringify,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return {
      data: response.data.map(AccountReceivable.make),
      meta: response.meta,
    };
  }

  public async getAccountsReceivableChartsByFilters(
    companyGroupId: number,
    companyIds: number[],
    idCustomer: string,
    filters: IFiltersToConsiderOnFinancialStatement,
  ): Promise<AccountReceivableChart> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-receivable/financial-statements/charts/${idCustomer}`,
      {
        params: filters,
        paramsSerializer: qs.stringify,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return AccountReceivableChart.make(response.data);
  }

  public async getDueAccountsReceivablePaginatedListByFilters(
    companyGroupId: number,
    companyIds: number[],
    dataTableConfig: ILaravelOrdenationTableParams<IFiltersFromFinancialStatementCustomerParams>,
  ): Promise<IDataTableWithLength<AccountReceivable[]>> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/customers/billings/accounts-receivable`,
      {
        params: dataTableConfig,
        paramsSerializer: qs.stringify,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return {
      data: response.data.map(AccountReceivable.make),
      total: response.total,
    };
  }

  public async getDueAccountsReceivableChartsByFilters(
    companyGroupId: number,
    companyIds: number[],
    filters: IFiltersFromFinancialStatementCustomerParams,
  ): Promise<AccountReceivableChart> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/customers/billings/accounts-receivable/charts`,
      {
        params: filters,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return AccountReceivableChart.make(response.data);
  }

  public async getAccountReceivableListFromEconomicGroupWithFilters(
    companyGroupId: number,
    companyIds: number[],
    economicGroupId: number,
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): Promise<DataWithPaginationInfo<AccountReceivable[]>> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/economic-groups/accounts-receivable/${economicGroupId}/list`, {
      params: filters,
      paramsSerializer: qs.stringify,
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return {
      data: response.data.map(AccountReceivable.make),
      meta: response.meta,
    };
  }

  public async getAccountReceivableChartFromEconomicGroupWithFilters(
    companyGroupId: number,
    economicGroupCompanyId: number,
    economicGroupId: number,
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): Promise<FinancialStatementChart> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/economic-groups/accounts-receivable/${economicGroupId}/charts`, {
      params: filters,
      paramsSerializer: qs.stringify,
      headers: { 'company-id': economicGroupCompanyId },
    });

    return FinancialStatementChart.make(response.data);
  }

  public async sendAccountsReceivableToCustomerMails(
    companyGroup: number,
    companyId: number,
    params: ISendAccountsReceivableToCustomerMailsParams,
  ): Promise<void> {
    await this.datasource.send(
      `company-groups/${companyGroup}/customers/securities-mail/customer`,
      params,
      { headers: { 'company-id': companyId } },
    );
  }

  public async getAccountsReceivableDetails(
    companyGroupId: number,
    companyId: number,
    accountReceivableCustomerId: string,
  ) {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-receivable/${accountReceivableCustomerId}/details`,
      { headers: { 'company-id': companyId } },
    );

    return response;
  }

  public async sendPendingAccountsReceivableToCustomerMails(
    companyGroupId: number,
    companyId: number,
    customerId: string,
  ): Promise<void> {
    await this.datasource.send(
      `company-groups/${companyGroupId}/customers/${customerId}/pending-accounts-receivable`,
      {},
      { headers: { 'company-id': companyId } },
    );
  }

  public async updateAccountsReceivableObservation(
    companyGroupId: number,
    companyId: number,
    customerId: string,
    params: IUpdateAccountsReceivableObservationParams,
  ): Promise<void> {
    await this.datasource.patch(
      `company-groups/${companyGroupId}/accounts-receivable/${customerId}/update-observation`,
      params,
      { headers: { 'company-id': companyId } },
    );
  }
}
