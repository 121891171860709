import { formatToSpecificFormat } from '@/utils/date';
import EventStatusEnum from '@/components/timeline/enums/EventStatusEnum';

export default class BillingHistory {
    constructor(
        public id: number,
        public clientName: string,
        public date: string,
        public initialRangeTime: string,
        public finalRangeTime: string,
        public channel: 'phone' | 'sms' | 'email' | 'whatsapp',
        public observation: string,
        public concludeObservation: string,
        public status: EventStatusEnum,
        public idCustomer: string,
        public concludeDateTime: string|null,
        public userWhoCompletedName: string,
    ) {}

    static make(data: any): BillingHistory {
        return new BillingHistory(
            data.id,
            data.clientName,
            data.date,
            formatToSpecificFormat(data.initialRangeTime, 'HH:mm', 'HH:mm:ss'),
            formatToSpecificFormat(data.finalRangeTime, 'HH:mm', 'HH:mm:ss'),
            data.channel || 'default',
            data.observation,
            data.concludeObservation,
            data.status,
            data.idCustomer,
            data.concludeDateTime !== null ? formatToSpecificFormat(data.concludeDateTime, 'dd/MM/yyyy HH:mm:ss', 'yyyy-MM-dd HH:mm:ss') : null,
			data.userWhoCompletedName,
        );
    }
}
