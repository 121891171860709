







































































































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { toCurrency } from '@/utils/index';
import { formateDate } from '@/utils/date';
import StatusChip from '@/components/tables/StatusChip.vue';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import VMenuOptions from '@/types/VMenuOptions';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import PreNegotiationListModel from '@/domain/models/PreNegotiationList';
import StatusObject from '@/components/tables/interfaces/StatusObject';
import PreNegotiationStatusOptionsEnum from '@/views/preNegotiation/enums/PreNegotiationStatusOptionsEnum';
import PreNegotiationRepository from '@/repositories/PreNegotiationRepository';

@Component({
  components: {
    ButtonFilterMenuOptions,
    StatusChip,
  },
})
export default class PreNegotiationList extends Vue {
  private readonly preNegotiationRepository
    : PreNegotiationRepository = new PreNegotiationRepository();
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;
  public isSelectingAll: boolean = false;

  public search: string = '';

  public defaultColumnToSearch: VMenuOptions | null = null;

  public readonly formatCurrency = toCurrency;
  public readonly formatDate = formateDate;

  public selectedPreNegotiations: PreNegotiationListModel[] = [];
  public preNegotiations: PreNegotiationListModel[] = [];
  public statusList: StatusObject[] = [
    { label: 'Pendente', value: 'pending', color: 'primary' },
    { label: 'Efetivado', value: 'made_effective', color: 'teal' },
    { label: 'Enviado ao ERP', value: 'sent_to_erp', color: 'grey' },
    { label: 'Expirado', value: 'expired', color: 'purple' },
    { label: 'Cancelado', value: 'canceled', color: 'error' },
  ];
  public optionsList: VMenuOptions[] = [
    { value: 'view_details', text: 'Visualizar Detalhes' },
    { value: 'effective_contract', text: 'Contrato Efetivado' },
  ];
  public filterOptions: VMenuOptions[] = [
    { text: 'grupo econômico', value: 'economic_group' },
  ];
  public headers: IVDataTableHeader[] = [
    { text: 'Empresa/Grupo Econômico', value: 'name' },
    { text: 'Nº Títulos', value: 'numberOfAccounts' },
    { text: 'Data de Expiração', value: 'expirationDate' },
    { text: 'Valor Total', value: 'totalValue' },
    { text: 'Status', value: 'status' },
    { text: '', value: 'options', width: '80px' },
  ];

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  @Watch('selectedPreNegotiations')
  public onSelectedPreNegotiationsChange(): void {
    if (this.selectedPreNegotiations.length === 0) {
      this.isSelectingAll = false;
    }
  }

  public created(): void {
    this.getPreNegotiationList();
  }

  public mounted(): void {
    this.defaultColumnToSearch = { ...this.filterOptions[0] };
  }

  public async getPreNegotiationList(): Promise<void> {
    try {
      this.loading = true;
      this.$dialog.startLoading();

      const preNegotiations = await this.preNegotiationRepository.getPreNegotiationListBySearch(
        this.companyGroupId,
        this.companyIds,
        this.search,
      );

      this.preNegotiations = preNegotiations;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as Pré-Negociações');
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  public async effectiveContract(preNegotiationIds: number[]): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.preNegotiationRepository.sendPreNegotiationsToERP(
        this.companyGroupId,
        this.companyIds,
        preNegotiationIds,
      );

      await this.getPreNegotiationList();
    } catch (error) {
      this.$notification.error('Houve um problema ao enviar as Pré-Negociações ao ERP.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleSearch(search: string): void {
    this.search = search;

    this.getPreNegotiationList();
  }

  public handleCancel(): void {
    console.log('handleCancel');
  }

  public handleEffectiveContract(): void {
    const preNegotiationIds = this.selectedPreNegotiations.map(({ id }) => id);

    this.effectiveContract(preNegotiationIds);
  }

  public handleOptionSelected(item: PreNegotiationListModel, optionSelected: string): void {
    if (optionSelected === 'effective_contract') {
      this.effectiveContract([item.id]);
    }

    if (optionSelected === 'view_details') {
      this.handlePreNegotiationClick(item);
    }
  }

  public handlePreNegotiationClick(item: PreNegotiationListModel): void {
    this.$router.push({
      name: 'PreNegotiation',
      params: { preNegotiationId: `${item.id}` },
    });
  }

  public async handleDataTableSelectHeaderChange() {
    this.isSelectingAll = !this.isSelectingAll;

    if (!this.isSelectingAll) {
      this.selectedPreNegotiations = [];

      return;
    }

    this.selectedPreNegotiations = this.preNegotiations
      .filter(({ status }) => status === PreNegotiationStatusOptionsEnum.PENDING);
  }
}
