import IPaymentRemittances from '@/domain/interfaces/IPaymentRemittances';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import Status from '@/domain/enums/PaymentRemittancesStatusType';
import pdfMake from 'pdfmake/build/pdfmake';
import { DateTime } from 'luxon';
import { formateDate } from '@/utils/date';
import { toCurrency } from '@/utils/';
import { formatPaymentData, formatTitle, isStatusToApproveOrReprove } from '.';
import {
  ICON_APPROVED,
  ICON_DISAPPROVED,
  ICON_NOT_SENT,
  ICON_PARTIALLY_APPROVED,
  ICON_PENDING,
  ICON_SENT,
  LOGO_COLOR,
} from './images';

const STATUS_LIST = [
  {
    value: Status.NOT_SENT,
    text: 'Não Enviado',
    icon: ICON_NOT_SENT,
  },
  {
    value: Status.PENDING,
    text: 'Aguardando Aprovação',
    icon: ICON_PENDING,
  },
  {
    value: Status.APPROVED,
    text: 'Aprovado',
    icon: ICON_APPROVED,
  },
  {
    value: Status.PARTIALLY_APPROVED,
    text: 'Aprovado Parcialmente',
    icon: ICON_PARTIALLY_APPROVED,
  },
  {
    value: Status.DISAPPROVED,
    text: 'Reprovado',
    icon: ICON_DISAPPROVED,
  },
  {
    value: Status.SENT,
    text: 'Enviado',
    icon: ICON_SENT,
  },
];

function getStatusIcon(status: Status): string {
  return STATUS_LIST
    .find((s) => s.value === status)
      ?.icon ?? ICON_NOT_SENT;
}

function getZebra(index: number): string {
  return index % 2 === 0 ? '#f7f7f7' : '';
}

function formatPaymentDataToString(item: PaymentRemittance, groupConfig: CompanyGroupConfig) {
  const data = formatPaymentData(item, groupConfig);

  return Object
    .keys(data)
    .reduce((prev, key, index) => {
      if (key === 'barcode') {
        prev += data[key];
      } else {
        prev += `${key}: ${data[key]} ${index > 0 ? '\n' : ' '}`;
      }
      return prev;
    }, '');
}

export default function generatePDF(
  list: Record<string, Array<PaymentRemittance>>,
  headers: Array<IVDataTableHeader>,
  data: IPaymentRemittances,
  groupConfig: CompanyGroupConfig,
) {
  const dateToday = DateTime.now().toLocaleString();

  const allItems = {
    total: 0,
    value: 0,
  };

  const pendingItems = {
    total: 0,
    value: 0,
  };

  const sentItems = {
    total: 0,
    value: 0,
  };

  const styles = {
    table: {
      width: '100%',
      marginBottom: 30,
    },
    'table-header': {
      fontSize: 10,
      bold: true,
      margin: [1.5, 3],
      fillColor: '#F2F3F7',
    },
    'table-header-center': {
      fontSize: 10,
      bold: true,
      alignment: 'center',
      margin: [1.5, 3],
      fillColor: '#F2F3F7',
    },
    'table-header-right': {
      fontSize: 10,
      bold: true,
      alignment: 'right',
      margin: [1.5, 3],
      fillColor: '#F2F3F7',
    },
  };

  const header = () => ({
    columns: [
      [
        {
          text: 'Remessas de Pagamento',
          margin: [20, 20, 20, 5],
          style: {
            bold: true,
            fontSize: 14,
          },
        },
        {
          text: data.dateInitial != data.dateEnd
            ? `${formateDate(data.dateInitial)} até ${formateDate(data.dateEnd)}`
            : `${formateDate(data.dateInitial)}`,
          margin: [20, 0, 20, 5],
          style: {
            fontSize: 10,
          },
        },
      ],
      {
        image: LOGO_COLOR,
        margin: [20, 20, 20, 0],
        width: 120,
        alignment: 'center',
      },
    ],
  });

  const footer = (currentPage: number, pageCount: number) => ({
    columns: [
      {
        text: `Gerado em ${dateToday}`,
        margin: [20, 20, 20, 0],
        style: {
          fontSize: 10,
        },
      },
      {
        text: `Página ${currentPage} de ${pageCount}`,
        margin: [20, 20, 20, 0],
        style: {
          fontSize: 10,
          alignment: 'right',
        },
      },
    ],
  });

  const legend = {
    layout: 'customLayout',
    style: 'table',
    table: {
      widths: ['*', '*', '*'],
      body: [
        [
          {
            text: 'Legendas',
            colSpan: 3,
            style: {
              bold: true,
              fontSize: 10,
              margin: 3,
              fillColor: '',
            },
          },
          {},
          {},
        ],
        [
          {
            fillColor: '#f7f7f7',
            columnGap: 10,
            columns: [
              {
                svg: STATUS_LIST[0].icon,
                width: 14,
                style: {
                  alignment: 'center',
                },
              },
              {
                text: STATUS_LIST[0].text,
                style: {
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              },
            ],
          },
          {
            fillColor: '#f7f7f7',
            columnGap: 10,
            columns: [
              {
                svg: STATUS_LIST[1].icon,
                width: 14,
                style: {
                  alignment: 'center',
                },
              },
              {
                text: STATUS_LIST[1].text,
                style: {
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              },
            ],
          },
          {
            fillColor: '#f7f7f7',
            columnGap: 10,
            columns: [
              {
                svg: STATUS_LIST[2].icon,
                width: 14,
                style: {
                  alignment: 'center',
                },
              },
              {
                text: STATUS_LIST[2].text,
                style: {
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              },
            ],
          },
        ],
        [
          {
            columnGap: 10,
            columns: [
              {
                svg: STATUS_LIST[3].icon,
                width: 14,
                style: {
                  alignment: 'center',
                },
              },
              {
                text: STATUS_LIST[3].text,
                style: {
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              },
            ],
          },
          {
            columnGap: 10,
            columns: [
              {
                svg: STATUS_LIST[4].icon,
                width: 14,
                style: {
                  alignment: 'center',
                },
              },
              {
                text: STATUS_LIST[4].text,
                style: {
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              },
            ],
          },
          {
            columnGap: 10,
            columns: [
              {
                svg: STATUS_LIST[5].icon,
                width: 14,
                style: {
                  alignment: 'center',
                },
              },
              {
                text: STATUS_LIST[5].text,
                style: {
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              },
            ],
          },
        ],
      ],
    },
  };

  Object.keys(list).map((key) => list[key].sort((a, b) => b.total - a.total));

  const tables = Object
    .keys(list)
    .map((key) => ({
      layout: 'customLayout',
      style: 'table',
      table: {
        widths: headers.map((_, i) => (i === 0 ? '*' : 'auto')),
        body: [
          headers
            .map((header, index) => {
              let align = index === 0 ? 'left' : 'center';
                align = index === headers.length - 1 ? 'right' : align;

              let text = index === 0 ? key : header.text;
                text = header.value === 'total' ? 'Valor' : text;

              return {
                text,
                style: {
                  bold: true,
                  fontSize: 10,
                  margin: 3,
                  fillColor: '#F2F3F7',
                  alignment: align,
                },
              };
            }),
          ...list[key]
            .map((item, index) => {
              allItems.total += 1;
              allItems.value += item.total;

              if (isStatusToApproveOrReprove(item.status)) {
                pendingItems.total += 1;
                pendingItems.value += item.total;
              }

              if (item.status === Status.APPROVED) {
                sentItems.total += 1;
                sentItems.value += item.total;
              }

              return headers
                .map((h, i) => {
                  let text = (item as any)[`${h.value}`];
                  if (h.value === 'title') text = formatTitle(item, data.supplierName);
                  if (h.value === 'total') text = toCurrency(item.total);
                  if (h.value === 'data_of_payment') text = formatPaymentDataToString(item, groupConfig);

                  const zebra = getZebra(index);

                  if (h.value === 'status') {
                    const svg = getStatusIcon(item.status);

                    return {
                      svg,
                      width: 16,
                      style: {
                        margin: 3,
                        alignment: 'center',
                        fillColor: zebra,
                      },
                    };
                  }

                  let align = i === 0 ? 'left' : 'center';
                  align = i === headers.length - 1 ? 'right' : align;

                  return {
                    text,
                    style: {
                      fontSize: 10,
                      margin: 3,
                      alignment: align,
                      fillColor: zebra,
                    },
                  };
                });
            }),
        ],
      },
    }));

  const summary = {
    layout: 'customLayout',
    style: 'table',
    table: {
      widths: ['*', '*', '*'],
      body: [
        [
          {
            text: '',
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '',
            },
          },
          {
            text: 'Títulos',
            style: {
              bold: true,
              fontSize: 10,
              margin: 3,
              fillColor: '',
              alignment: 'center',
            },
          },
          {
            text: 'Valor',
            style: {
              bold: true,
              fontSize: 10,
              margin: 3,
              fillColor: '',
              alignment: 'right',
            },
          },
        ],
        [
          {
            text: 'Total Geral',
            style: {
              bold: true,
              fontSize: 10,
              margin: 3,
              fillColor: '#f7f7f7',
            },
          },
          {
            text: allItems.total,
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '#f7f7f7',
              alignment: 'center',
            },
          },
          {
            text: toCurrency(allItems.value),
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '#f7f7f7',
              alignment: 'right',
            },
          },
        ],
        [
          {
            text: 'Total Enviado',
            style: {
              bold: true,
              fontSize: 10,
              margin: 3,
              fillColor: '',
            },
          },
          {
            text: pendingItems.total,
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '',
              alignment: 'center',
            },
          },
          {
            text: toCurrency(pendingItems.value),
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '',
              alignment: 'right',
            },
          },
        ],
        [
          {
            text: 'Total Aprovado',
            style: {
              bold: true,
              fontSize: 10,
              margin: 3,
              fillColor: '#f7f7f7',
            },
          },
          {
            text: sentItems.total,
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '#f7f7f7',
              alignment: 'center',
            },
          },
          {
            text: toCurrency(sentItems.value),
            style: {
              fontSize: 10,
              margin: 3,
              fillColor: '#f7f7f7',
              alignment: 'right',
            },
          },
        ],
      ],
    },
  };

  const document = {
    pageOrientation: headers.length > 7 ? 'landscape' : 'portrait',
    pageMargins: [20, 80, 20, 50],
    header,
    content: [
      legend,
      ...tables,
      summary,
    ],
    footer,
    styles,
  };

  const tableLayouts = {
    customLayout: {
      hLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length ? 0 : 0.5),
      vLineWidth: (i: number) => 0,
      hLineColor: (i: number) => (i !== 1 ? '#f7f7f7' : '#F2F3F7'),
      paddingLeft: (i: number) => (i === 0 ? 3 : 6),
      paddingRight: (i: number, node: any) => (i === node.table.widths.length - 1 ? 3 : 6),
    },
  };

  pdfMake
    .createPdf(document, tableLayouts)
    .download(`InnCash - Remessas de Pagamento ${dateToday.replaceAll('/', '-')}`);
}
