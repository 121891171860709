






























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PaymentConditionRepository from '@/repositories/PaymentConditionRepository';
import VMenuOptions from '@/types/VMenuOptions';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

@Component
export default class ClientInformation extends Vue {
  private readonly paymentConditionRepository:
    PaymentConditionRepository = new PaymentConditionRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public clientModule: ClientModule = getModule(ClientModule);
  public risksOptions: string[] = ['A', 'B', 'C', 'D', 'E', 'X'];
  public classOptions: string[] = ['A', 'B', 'C', 'X'];

  public loadingPaymentConditionField: boolean = false;
  public paymentConditions: VMenuOptions[] = [];
  public searchPaymentCondition: string = '';
  public selectedPaymentCondition: any = '';

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public async created(): Promise<void> {
    this.setOrderInformations();
  }

  public handleInput(value: string | number, type: string): void {
    this.clientModule.setClient({
      ...this.clientModule.client,
      [type]: value,
    });
  }

  public async handleOnFinishedDebouncePaymentCondition(search: string): Promise<void> {
    if (this.loadingPaymentConditionField || search === '') {
      return;
    }

    this.loadingPaymentConditionField = true;

    try {
      const paymentConditions = await this.paymentConditionRepository.getPaymentConditionsByCode(
        this.companyGroupId,
        Number(this.clientModule.client.companyId),
        search,
      );

      this.paymentConditions = paymentConditions
        .map(({ code, description }) => ({ text: description, value: code }));
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as condições de pagamento.');

      this.$notification.error(errorMessage);
    } finally {
      this.loadingPaymentConditionField = false;
    }
  }

  public handlePaymentConditionChange(condition: string): void {
    this.clientModule.setPaymentCondition(condition);
  }

  public async setOrderInformations(): Promise<void> {
    this.selectedPaymentCondition = this.clientModule.client.deadline;

    if (this.selectedPaymentCondition) {
      this.searchPaymentCondition = this.selectedPaymentCondition;
      this.handleOnFinishedDebouncePaymentCondition(this.searchPaymentCondition);
    }
  }
}
