var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"pt-2 pb-8"},[_vm._v("Ausência Temporária")]),_c('v-form',{ref:"form",attrs:{"disabled":_vm.upsertLoading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"3"}},[_c('inn-date-field',{attrs:{"placeholder":"Selecione a data","label":"Data Inicial","required":true,"buttons":false,"nudge-top":'25%',"error":_vm.errorDateRange,"error-messages":_vm.errorDateRange
            ? 'Data inicial deve ser menor ou igual a data final'
            : ''},model:{value:(_vm.data.initialDate),callback:function ($$v) {_vm.$set(_vm.data, "initialDate", $$v)},expression:"data.initialDate"}})],1),_c('v-col',{class:{ 'mb-6': !_vm.errorDateRange },attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"3"}},[_c('inn-date-field',{attrs:{"placeholder":"Selecione a data","label":"Data Final (Opcional)","required":_vm.errorDateRange,"buttons":false,"nudge-top":'25%',"error":_vm.errorDateRange,"error-messages":_vm.errorDateRange
            ? 'Data final deve ser menor ou igual a data inicial'
            : ''},model:{value:(_vm.data.endDate),callback:function ($$v) {_vm.$set(_vm.data, "endDate", $$v)},expression:"data.endDate"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"3"}},[_c('v-autocomplete',{attrs:{"rules":[_vm.rule.required],"items":_vm.operators,"item-value":"id","item-text":"name","label":"Operador Substituto","clearable":"","filled":"","dense":""},model:{value:(_vm.data.substituteId),callback:function ($$v) {_vm.$set(_vm.data, "substituteId", $$v)},expression:"data.substituteId"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"3"}},[_c('v-btn',{staticClass:"mb-10",attrs:{"disabled":_vm.upsertLoading,"color":"info","large":"","block":""},on:{"click":_vm.addAbsence}},[_vm._v(" ADICIONAR ")])],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.model,"headers":_vm.headers,"loading":_vm.getDataLoading,"loading-text":"Carregando ausências...","no-data-text":"Nenhuma ausência cadastrada.","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.initialDate",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.endDate",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(value && _vm.formatDate(value))+" ")]}},{key:"item.substituteId",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getSubstituteNameFromOperatorsById(value))+" ")]}},{key:"item.id",fn:function(ref){
            var index = ref.index;
return [_c('action-button',{attrs:{"label":"Remover","icon":"mdi-close","disabled":_vm.upsertLoading},on:{"click":function($event){return _vm.removeAbsence(index)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }