import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import IOption from '@/domain/interfaces/ISelectOptions';

import Company from '@/domain/models/Company';
import Supplier from '@/domain/models/Supplier';
import BankConfig from '@/domain/models/BankConfig';
import PayrollLog from '@/domain/models/PayrollLog';
import PayrollSelectItem from '@/domain/models/PayrollSelectItem';

import Repository from '@/repositories/base/Repository';
import PayrollLogItem from '@/domain/models/PayrollLogItem';
import EPayrollLogType from '@/domain/enums/PayrollLogType';

export default class PayrollRepository extends Repository {
  async getCompanies(): Promise<Array<IOption<number>>> {
    const { status, data: response } = await this.datasource.get('company/listAllActv');

    if (status == 200) {
      if (response.error) throw new Error(`Empresas: ${response.message}`);

      const companies: Array<Company> = response.data.map(Company.make);

      const selectOptions = companies.map((company) => {
        const { id, name } = company;
        return { value: id, text: name };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das companhias do grupo.');
  }

  async getSuppliers(
    groupId: number,
    companies: Array<number>,
    search: string,
  ): Promise<Array<IOption<string>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/suppliers/search/like`,
      {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
        params: {
          limit: 25,
          search,
        },
      },
    );

    if (status == 200) {
      if (response.error) throw new Error(`Fornecedores: ${response.message}`);

      const suppliers: Array<Supplier> = response.data.map(Supplier.make);

      const selectOptions = suppliers.map((supplier) => {
        const {
          id, name, code, document,
        } = supplier;
        return { value: id, text: `${code} - ${name} - ${document}` };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos fornecedores.');
  }

  async getTitles(
    group: number,
    companies: Array<number>,
    params: object,
  ): Promise<DataWithPaginationInfo<Array<PayrollSelectItem>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/payrolls/accounts-payable`, {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
        params,
      });

    if (status == 200) {
      const items = response && response.data && Array.isArray(response.data)
        ? response.data.map(PayrollSelectItem.make)
        : [] as Array<PayrollSelectItem>;

      return { data: items, meta: response.meta };
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem dos títulos.');
  }

  async getAllBanksConfig(companies: Array<number>): Promise<Array<IOption<number>>> {
    const { status, data: response } = await this.datasource.get('bankconfig/listAllBanks/', {
      headers: {
        'company-ids': JSON.stringify(companies),
      },
    });

    if (status >= 200 && status < 300) {
      if (response.error) throw new Error(`Configurações bancárias: ${response.message}`);
      if (response.total_records == 0) throw new Error('Nenhuma configuração bancária encontrada.');

      const data = response.data.map(BankConfig.make);

      const banksConfig: Array<BankConfig> = data.filter(
        (bankConfig: BankConfig) => bankConfig.tipo == 'PAGAR' && bankConfig.send_types.length > 0,
      );

      const options: Array<IOption<number>> = banksConfig.map((bank) => ({
        value: bank.id,
        text: `${bank.banco} ${bank.agencia} ${bank.conta} - ${bank.name}`,
      }));

      return options;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das configurações bancárias.');
  }

  async uploadPayroll(groupId: number, companyId: number, data: FormData): Promise<void> {
    const { status } = await this.datasource
    .send(`company-groups/${groupId}/payrolls/accounts-payable`, data, {
      headers: {
        'company-id': companyId,
      },
    });

    if (status >= 200 && status < 400) return;

    throw new Error('Ops, algo inesperado no upload da folha de pagamento.');
  }

  async getLogs(
    group: number,
    companies: Array<number>,
    params: object,
  ): Promise<DataWithPaginationInfo<Array<PayrollLog>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/payrolls/logs`, {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
        params,
      });

    if (status == 200) {
      const items = response && response.data && Array.isArray(response.data)
        ? response.data.map(PayrollLog.make)
        : [] as Array<PayrollLog>;

      return { data: items, meta: response.meta };
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem dos logs.');
  }

  async getLog(
    group: number,
    companies: Array<number>,
    id: number,
  ): Promise<Array<PayrollLogItem>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/payrolls/logs/${id}`, {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
      });

    if (status == 200) {
      const items = response && response.data && Array.isArray(response.data)
        ? response.data.map(PayrollLogItem.make)
        : [] as Array<PayrollLogItem>;

      return items;
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem do log.');
  }

  async downloadPayroll(
    group: number,
    companies: Array<number>,
    id: number,
    type: EPayrollLogType,
  ): Promise<Blob> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/payrolls/logs/${id}/download/${type}`, {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
      });

    if (status == 200) {
      return response;
    }

    throw new Error('Ops, algo inesperado aconteceu no donwload do arquivo.');
  }

  async downloadReceipt(
    group: number,
    companies: Array<number>,
    id: number,
    type: 'together' | 'separate' | 'compress',
  ): Promise<Blob> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/payrolls/logs/${id}/receipt/download/${type}`, {
        responseType: 'arraybuffer',
        headers: {
          'company-ids': JSON.stringify(companies),
        },
      });

    if (status == 200) {
      return response;
    }

    throw new Error('Ops, algo inesperado aconteceu no donwload do comprovante.');
  }

  async downloadReceiptFromItem(
    group: number,
    companies: Array<number>,
    log: number,
    id: number,
  ): Promise<Blob> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/payrolls/logs/${log}/receipt/${id}/download`, {
        responseType: 'arraybuffer',
        headers: {
          'company-ids': JSON.stringify(companies),
        },
      });

    if (status == 200) {
      return response;
    }

    throw new Error('Ops, algo inesperado aconteceu no donwload do comprovante.');
  }
}
