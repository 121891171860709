enum GroupFilterParametersEnum {
    CLIENT_LIST = 'CLIENT_LIST',
    CONCILIATION_LIST = 'CONCILIATION_LIST',
    ACCOUNT_RECEIVABLE_CLIENT_LIST = 'ACCOUNT_RECEIVABLE_CLIENT_LIST',
    SALES_ORDER_CLIENT_LIST = 'SALES_ORDER_CLIENT_LIST',
    SALES_ORDER_ORDER_LIST = 'SALES_ORDER_ORDER_LIST',
    BILLING_HISTORY = 'BILLING_HISTORY',
    WRITE_OFF_MOVEMENT = 'WRITE_OFF_MOVEMENT',
    BILLING_REMITTANCE = 'BILLING_REMITTANCE',
    BILLING_PENDENCY_LIST = 'BILLING_PENDENCY_LIST',
    ORDERS = 'ORDERS',
    ORDER_ANALISYS = 'ORDER_ANALISYS',
    DDA_CONCILIATION_LIST = 'DDA_CONCILIATION_LIST',
    DDA_CONCILIATION_INVOICE = 'DDA_CONCILIATION_INVOICE',
    DDA_CONCILIATION_SEARCH_ERP = 'DDA_CONCILIATION_SEARCH_ERP',
    DDA_CONCILIATION_SEARCH_DDA = 'DDA_CONCILIATION_SEARCH_DDA',
    PAYMENT_REMITTANCES = 'PAYMENT_REMITTANCES',
    INSTRUCTIONS_REMITTANCE = 'INSTRUCTIONS_REMITTANCE',
    PAYROLL_SELECT_LIST = 'PAYROLL_SELECT_LIST',
    FINANCIAL_STATEMENT_CREDIT_CUSTOMER = 'FINANCIAL_STATEMENT_CREDIT_CUSTOMER',
    FINANCIAL_STATEMENT_CREDIT_ORDER = 'FINANCIAL_STATEMENT_CREDIT_ORDER',
}

export default GroupFilterParametersEnum;
