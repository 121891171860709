



























































































  import {
    Component,
    Emit,
    Ref,
    Vue,
  } from 'vue-property-decorator';
  import { VForm } from '@/types/VForm';
  import { formatErrorForNotification } from '@/utils/error';

  import IOption from '@/domain/interfaces/ISelectOptions';

  import EGroupFilterParameters from '@/domain/enums/GroupFilterParametersEnum';
  import FilterParametersRepository from '@/repositories/FilterParametersRepository';

  import BillingPendencyRepository from '@/repositories/BillingPendencyRepository';
  import EBillingPendencyPeriod from '@/domain/enums/BillingPendencyPeriod';
  import FilterParameters from '@/domain/models/filter-parameters/FilterParameterBillingPendencyList';
  import ColumnToShow from '@/types/ColumnToShow';
import PaymentConditionRepository from '@/repositories/PaymentConditionRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import { getModule } from 'vuex-module-decorators';
import EconomicGroupRepository from '@/repositories/EconomicGroupRepository';
import EconomicGroupGroupingTypesEnum from '@/views/economicGroups/enums/EconomicGroupGroupingTypesEnum';

  @Component
  export default class BillingPendencyListFilter extends Vue {
    @Ref('filter') readonly filter!: VForm;

    @Emit('validate')
    public emitValidate(): { valid: boolean, data: Omit<FilterParameters, 'columnsToShow'> } {
      return this.validate();
    }

    @Emit('set-columns')
    public setColumns(columnsToShow: Array<ColumnToShow>) {
      return columnsToShow;
    }

    public readonly filterParametersRepository:
      FilterParametersRepository = new FilterParametersRepository();

    public readonly billingPendencyRepository:
      BillingPendencyRepository = new BillingPendencyRepository();

    public readonly paymentConditionRepository:
      PaymentConditionRepository = new PaymentConditionRepository();

    public readonly economicGroupRepository
      : EconomicGroupRepository = new EconomicGroupRepository();

    private readonly authenticationModule
      : AuthenticationModule = getModule(AuthenticationModule);

    public companies: Array<IOption<number>> = [];
    public saleGroups: Array<IOption<number>> = [];
    public paymentConditions: Array<IOption<string>> = [];

    public periodies: Array<IOption<EBillingPendencyPeriod>> = [
      {
        text: 'A Vencer nos Próximos',
        value: EBillingPendencyPeriod.DUE,
      },
      {
        text: 'Vencidos nos Últimos',
        value: EBillingPendencyPeriod.OVERDUE,
      },
    ];

    public data: Omit<FilterParameters, 'columnsToShow'> = {
      search: '',
      companies: [],
      saleGroups: [],
      paymentConditions: [],
      periodies: [],
      days: undefined,
    };

    public get groupId(): number {
      return this.$session.get('company_group_id');
    }

    public mounted(): void {
      this.loadOptions();
    }

    public validate(): { valid: boolean, data: Omit<FilterParameters, 'columnsToShow'> } {
      const isValidFilter = this.filter.validate() ?? false;

      if (isValidFilter) {
        const relationship = 'billing_pendency_list';

        this.filterParametersRepository.setFilter(EGroupFilterParameters.BILLING_PENDENCY_LIST, [
          { key: `search_${relationship}`, value: this.data.search },
          { key: `companies_${relationship}`, value: JSON.stringify(this.data.companies) },
          { key: `sale_groups_${relationship}`, value: JSON.stringify(this.data.saleGroups) },
          { key: `payment_conditions_${relationship}`, value: JSON.stringify(this.data.paymentConditions) },
          { key: `periodies_${relationship}`, value: JSON.stringify(this.data.periodies) },
          { key: `days_${relationship}`, value: this.data.days },
        ]);

        return {
          valid: true,
          data: this.data,
        };
      }

      return {
        valid: false,
        data: this.data,
      };
    }

    async loadOptions(): Promise<void> {
      try {
        this.$dialog.startLoading();

        await this.loadCompanies();
        await this.loadSaleGroups();
        await this.loadPaymentConditions();

        await this.loadFilterParameters();

        this.emitValidate();
      } catch (error: any) {
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
      } finally {
        this.$dialog.stopLoading();
      }
    }

    async loadFilterParameters(): Promise<void> {
      try {
        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(EGroupFilterParameters.BILLING_PENDENCY_LIST);

        const { columnsToShow, ...params } = FilterParameters
          .make(filterParameters);

        this.data = { ...params };
        this.setColumns(columnsToShow);
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      }
    }

    async loadCompanies(): Promise<void> {
      const companies = await this.billingPendencyRepository
        .getCompanies(this.groupId);

      this.companies = companies
        .map((company) => ({ value: company.id, text: company.name }));
    }

    async loadSaleGroups(): Promise<void> {
      const economicGroups = await this.economicGroupRepository
        .getEconomicGroups(
          this.groupId,
          this.authenticationModule.companyIds,
          EconomicGroupGroupingTypesEnum.CUSTOMER,
        );

      // TODO
      // const saleGroups = await this.billingPendencyRepository
      //   .getSaleGroups(this.groupId);

      this.saleGroups = economicGroups
        .map((economicGroup) => ({ value: economicGroup.id, text: economicGroup.name }));
    }

    async loadPaymentConditions(): Promise<void> {
      const paymentConditions = await this.paymentConditionRepository
        .getPaymentConditions(this.groupId, this.authenticationModule.companyIds);

      this.paymentConditions = paymentConditions
        .map((saleGroup) => ({ value: saleGroup.code, text: saleGroup.description }));
    }
  }
