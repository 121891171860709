



























import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';

@Component
export default class ButtomTooltip extends Vue {
  public isLeft: boolean = false;
  public isRight: boolean = false;
  public isTop: boolean = false;
  public isBottom: boolean = false;

  @Prop({
    type: String,
    required: false,
    default: '',
  }) readonly customButtonClass!: String;

  @Prop(String) readonly buttonIcon!: String | null;

  @Prop(String) readonly buttonText!: String | null;

  @Prop(String) readonly tooltipText!: String | null;

  @Prop(String) readonly position!: 'left' | 'right' | 'bottom' | 'top' | '';

  public created(): void {
    this.setTooltipPosition(this.position);
  }

  public setTooltipPosition(position: string): void {
    switch (position) {
      case 'left':
        this.isLeft = true;
        break;
      case 'right':
        this.isRight = true;
        break;
      case 'bottom':
        this.isBottom = true;
        break;
      case 'top':
        this.isTop = true;
        break;
      default:
        this.isBottom = true;
        break;
    }
  }
}
