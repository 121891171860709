






































































































































































































































































import {
  Vue,
  Component,
  Watch,
  Emit,
  Prop,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { pluralize, toCurrency } from '@/utils';
import { formatToSpecificFormat } from '@/utils/date';
import InclusionSecurityDialog from '@/views/clients/components/InclusionSecurityDialog.vue';
import ColorsHelper from '@/helpers/ColorsHelper';
import DataTableHelper from '@/helpers/DataTableHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import ChangeDueDateDialog from '@/views/clients/components/ChangeDueDateDialog.vue';
import InvoiceChart from '@/components/charts/InvoiceChart.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import TotalCalculator from '@/views/clients/components/TotalCalculator.vue';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import AccountsReceivableListFeeTypeEnum from '@/views/preNegotiation/enums/AccountsReceivableListFeeTypeEnum';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import AccountReceivableBankSlipRepository from '@/repositories/AccountReceivableBankSlipRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientFinancialChartValues from '@/views/clients/types/ClientFinancialChartValues';
import LooseObjectType from '@/types/LooseObjectType';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import FilterParameterAccountReceivableClient from '@/domain/models/filter-parameters/FilterParameterAccountReceivableClient';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import AccountReceivable from '@/domain/models/AccountReceivable';
import VMenuOptions from '@/types/VMenuOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import IChartLabels from '@/views/clients/interfaces/IChartLabels';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';
import IFiltersToConsiderOnFinancialStatement from '@/views/clients/interfaces/IFiltersToConsiderOnFinancialStatement';
import AccountReceivableChart from '@/domain/models/AccountReceivableChart';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import FinancialStatementChart from '@/domain/models/FinancialStatementChart';
import ISelectedSecuritiesParams from '@/views/financialStatement/interfaces/ISelectedSecuritiesParams';
import AccountReceivableStatusEnum from '@/views/clients/enums/AccountReceivableStatusEnum';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import FinancialStatementModuleOriginType from '@/views/financialStatement/types/FinancialStatementModuleOriginType';
import ISortAndDirectionDataTable from '@/helpers/interfaces/ISortAndDirectionDataTable';

@Component({
  components: {
    InvoiceChart,
    ButtonFilterMenuOptions,
    TotalCalculator,
    DynamicColumnMenu,
    InclusionSecurityDialog,
    ChangeDueDateDialog,
  },
})
export default class FinancialStatement extends Vue {
  private readonly filterParametersRepository: FilterParametersRepository =
    new FilterParametersRepository();
  private readonly accountReceivableBankSlipRepository: AccountReceivableBankSlipRepository =
    new AccountReceivableBankSlipRepository();
  private readonly accountsReceivableRepository: AccountsReceivableRepository =
    new AccountsReceivableRepository();
  private readonly authenticationModule: AuthenticationModule =
    getModule(AuthenticationModule);
  public readonly documentFormattation = DocumentFormattation;

  public totalItems: number = 0;
  public averageTime: number = 0;
  public averageDelay: number = 0;

  public groupFiltersHasBeenSet: boolean = false;
  public isInclusionSecurityDialogOpen: boolean = false;
  public isChangeDueDateDialogOpen: boolean = false;
  public isSelectingAllSecurities: boolean = false;

  public search: string = '';
  public definedDateFilterOption: string = '';

  public defaultDateFilterOption: VMenuOptions | null = null;
  public dataTableOptions: DataOptions =
    DataTableHelper.getDefaultDataTableOptions();
  public footerOptions: DataTableFooterOptions =
    DataTableHelper.getDefaultFooterProps();
  public filtersToConsider: IFiltersToConsiderOnFinancialStatement = {};
  public chartValues: ClientFinancialChartValues = {
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
  };
  public headerKeysObject: LooseObjectType<string> = {
    number: 'data_table_account_receivable_show_column_number',
    order: 'data_table_account_receivable_show_column_order',
    emissionDate: 'data_table_account_receivable_show_column_emission_date',
    issueDate: 'data_table_account_receivable_show_column_issue_date',
    document: 'data_table_account_receivable_show_column_document',
    officialDueDate:
      'data_table_account_receivable_show_column_official_due_date',
    delayDays: 'data_table_account_receivable_show_column_delay_days',
    value: 'data_table_account_receivable_show_column_value',
    balance: 'data_table_account_receivable_show_column_balance',
    status: 'data_table_account_receivable_show_column_status',
    titleDecreaseDate:
      'data_table_account_receivable_show_column_title_decrease_date',
  };

  public rangeDate: string[] = [];
  public items: AccountReceivable[] = [];
  public selectedSecurities: AccountReceivable[] = [];
  public dateFilterOptions: VMenuOptions[] = [
    { value: 'date_emission', text: 'Data de Emissão' },
    { value: 'date_actual_expiration', text: 'Vencimento Real' },
  ];
  public titlesActionOptions: IButtonOption[] = [
    { value: 'increase_decrease', text: 'Acréscimo/Decréscimo' },
    { value: 'change_due_date', text: 'Alterar data de vencimento' },
    { value: 'open_pre_negotiations_screen', text: 'Gerar Pré Negociação' },
  ];

  public labels: IChartLabels = {
    paidOut: true,
    toExpired: true,
    expired: true,
  };

  public availableHeaders: IVDataTableHeader[] = [
    { text: 'Número', value: 'number', show: true },
    { text: 'Pedido', value: 'order', show: true },
    { text: 'Dt. Emissão', value: 'emissionDate', show: true },
    { text: 'Vencimento', value: 'issueDate', show: true },
    { text: 'CNPJ', value: 'document', show: true },
    { text: 'Vencimento Real', value: 'officialDueDate', show: true },
    {
      text: 'Dias Atraso',
      value: 'delayDays',
      show: true,
      sortable: false,
    },
    { text: 'Valor', value: 'value', show: true },
    { text: 'Saldo', value: 'balance', show: true },
    {
      text: 'Status',
      value: 'status',
      show: true,
      sortable: false,
    },
    { text: 'Data Pagto', value: 'titleDecreaseDate', show: true },
  ];

  public get formattedChartValues(): Object {
    const { totalExpired, totalToExpire, totalPaidOut } = this.chartValues;

    return {
      labels: [
        `Vencido: R$ ${toCurrency(totalExpired, {}, true)}`,
        `A vencer: R$ ${toCurrency(totalToExpire, {}, true)}`,
        `Pago: R$ ${toCurrency(totalPaidOut, {}, true)}`,
      ],
      datasets: [
        {
          label: 'Valor',
          data: [totalExpired, totalToExpire, totalPaidOut],
          backgroundColor: ['#E9637B', '#9994D5', '#0EB799'],
          borderWidth: 0,
        },
      ],
    };
  }

  public get getInfoBlockColorBasedOnTheme(): string {
    return ColorsHelper.getBaseBlockColorBasedOnTheme(this.$vuetify.theme.dark);
  }

  public get getTextPriceValuesColor(): string {
    return ColorsHelper.getTextValuesColorBasedOnTheme(
      this.$vuetify.theme.dark,
    );
  }

  public get idCustomer(): string {
    return this.$route.params.idCustomer;
  }

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get headers(): IVDataTableHeader[] {
    const headersToShow = this.availableHeaders.filter((h) => h.show);

    if (this.isOnLowWidthSizeDataTable) {
      return headersToShow;
    }

    return headersToShow.concat(
      {
        text: '',
        align: 'center',
        value: 'actions',
        sortable: false,
      },
      {
        text: '',
        align: 'center',
        value: 'headerManagement',
        sortable: false,
      },
    );
  }

  public get hasSelectedSecurities(): boolean {
    return this.selectedSecurities.length > 0 || this.isSelectingAllSecurities;
  }

  public get selectedSecuritiesIdsCustomer(): string[] {
    return this.selectedSecurities.map(
      ({ accountReceivableCustomerId }) => accountReceivableCustomerId,
    );
  }

  public get isOnLowWidthSizeDataTable(): boolean {
    return this.$vuetify.breakpoint.width < 1466;
  }

  public get actualSortAndDirection(): ISortAndDirectionDataTable {
    return {
      sort: this.dataTableOptions.sortBy[0],
      direction: this.dataTableOptions.sortDesc[0],
    };
  }

  public get filterParameterEnumBasedOnOrigin(): GroupFilterParametersEnum {
    switch (this.origin) {
      case 'customer':
          return GroupFilterParametersEnum.FINANCIAL_STATEMENT_CREDIT_CUSTOMER;
      case 'order':
          return GroupFilterParametersEnum.FINANCIAL_STATEMENT_CREDIT_ORDER;
      default: throw new Error('Origem inválida');
    }
  }

  public get editorType(): AccountsReceivableListFeeTypeEnum {
    return AccountsReceivableListFeeTypeEnum.PERCENTAGE;
  }

  public get isBillingPendencyOrigin(): boolean {
    return this.origin === 'billing_pendency';
  }

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    if (!this.groupFiltersHasBeenSet) {
      return;
    }

    this.getFinancialValues();
  }

  @Watch('accountsReceivableList')
  handleAccountsReceivableListChange(list: IDataTableWithLength<AccountReceivable[]>): void {
    if (list === null) {
      return;
    }

    this.items = list.data;
    this.totalItems = list.total;
  }

  @Watch('accountsReceivableChartValues')
  handleAccountsReceivableChartsChange(chart: FinancialStatementChart): void {
    if (chart === null) {
      return;
    }

    this.chartValues = { ...chart };
    this.averageTime = chart.averageMaturity;
    this.averageDelay = chart.averageDelay;
  }

  @Watch('shouldResetAccountsSelection')
  handleResedAccountsSelection(shouldReset: boolean): void {
    if (shouldReset) {
      console.log('desselecionnou');
      this.unselectSecurities();
    }
  }

  @Emit('get-accounts-receivable')
  public getFinancialValues(
    onRequestTableOptions: OnRequestTableOptions = {},
  ): ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>|null {
    if (onRequestTableOptions.resetToFirst) {
      this.dataTableOptions.page = 1;
    }

    const { sort, direction } = this.actualSortAndDirection;
    const tableConfigParams:
      ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement> = {
        filter: this.filtersToConsider,
        sort: DataTableHelper.formatTableOrdenationColumn(direction, sort),
        page: this.dataTableOptions.page,
        items_per_page: this.dataTableOptions.itemsPerPage,
      };

    if (!this.isBillingPendencyOrigin) {
      this.setFilterParameters(tableConfigParams);
    }

    return tableConfigParams;
  }

  @Emit('open-pre-negotiation-screen-from-selected-accounts')
  public openPreNegotiationScreenFromSelectedAccounts(): ISelectedSecuritiesParams {
    return {
      isSelectingAllSecurities: this.isSelectingAllSecurities,
      securitiesIdsCustomer: this.selectedSecuritiesIdsCustomer,
    };
  }

  @Prop({
    type: String,
    required: true,
  }) readonly origin!: FinancialStatementModuleOriginType;

  @Prop({
    type: [Array, Object],
    required: true,
  }) readonly accountsReceivableList!: DataWithPaginationInfo<AccountReceivable[]>|null;

  @Prop({
    type: Object,
    required: true,
  }) readonly accountsReceivableChartValues!: AccountReceivableChart|null;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly isLoading!: boolean;

  @Prop({
    type: Number,
    required: true,
  }) readonly companyId!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly shouldResetAccountsSelection!: boolean;

  public async created(): Promise<void> {
    if (!this.isBillingPendencyOrigin) {
      await this.getGroupFilterParameters();
    } else {
      this.getFinancialValues();
    }
  }

  public async getGroupFilterParameters(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const filterParameters = await this.filterParametersRepository.getFilterByGroup(
        this.filterParameterEnumBasedOnOrigin,
      );
      const formattedFilters = FilterParameterAccountReceivableClient.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
      this.groupFiltersHasBeenSet = true;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getAverageTime(): boolean | string {
    return pluralize(this.averageTime, 'dia', 'dias');
  }

  public getAverageDelay(): boolean | string {
    return pluralize(this.averageDelay, 'dia', 'dias');
  }

  public getChipColor(status: AccountReceivableStatusEnum): string {
    switch (status) {
      case AccountReceivableStatusEnum.expire: return 'purple';
      case AccountReceivableStatusEnum.due: return 'red';
      case AccountReceivableStatusEnum.paid: return 'success';
      default: return 'green';
    }
  }

  public getStatusText(status: AccountReceivableStatusEnum): string {
    switch (status) {
      case AccountReceivableStatusEnum.expire: return 'À vencer';
      case AccountReceivableStatusEnum.due: return 'Vencido';
      case AccountReceivableStatusEnum.paid: return 'Pago';
      default: return 'Nenhum';
    }
  }
  public getMoneyFormat(
    num: number,
    dashIfIsZero: boolean = true,
  ): string | number {
    if (num > 0) {
      return toCurrency(num, {}, true);
    }

    return dashIfIsZero ? '-' : 0;
  }

  public applyFiltersOnActualPage(
    filters: FilterParameterAccountReceivableClient,
  ): void {
    this.dataTableOptions.sortBy = filters.tableSort;
    this.dataTableOptions.sortDesc = filters.tableDirection;
    this.dataTableOptions.itemsPerPage = filters.rowsPerPage;
    this.dataTableOptions.page = filters.actualPage;

    let definedOption = FilterParameterHelper.getFormattedColumnObjectOfOptionsButton(
        this.dateFilterOptions,
        filters.dateTypeToSearch,
      );

    if (definedOption === null) {
      [definedOption] = this.dateFilterOptions;
    }

    this.defaultDateFilterOption = definedOption;
    this.definedDateFilterOption = definedOption.value;
    this.rangeDate = filters.dateToSearch;

    this.availableHeaders = FilterParameterHelper.defineColumnsToShowOnSpecificHeader(
        this.availableHeaders,
        filters.columnsToShow,
      );

    this.defineFiltersToFinancialTable();
  }

  public setFilterParameters(
    filters: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): void {
    try {
      this.filterParametersRepository.setFilter(
        this.filterParameterEnumBasedOnOrigin,
        [
          {
            key: 'date_type_to_search_account_receivable_list',
            value: this.definedDateFilterOption,
          },
          {
            key: 'date_to_search_account_receivable_list',
            value: JSON.stringify(this.rangeDate),
          },
          {
            key: 'actual_page_account_receivable_list',
            value: this.dataTableOptions.page,
          },
          {
            key: 'rows_per_page_account_receivable_list',
            value: this.dataTableOptions.itemsPerPage,
          },
          {
            key: 'sort_table_account_receivable_list',
            value: filters.sort,
          },
        ],
      );
    } catch (error) {
      this.$notification.error('Houve um problema ao salvar as configurações de filtro.');
    }
  }

  public setDateToBrazilianFormat(date: string | null): string {
    if (date === null) {
      return '//';
    }

    return formatToSpecificFormat(date, 'dd/MM/yyyy');
  }

  public async defineFiltersToFinancialTable(): Promise<void> {
    await this.$nextTick();

    const definedFilters: LooseObjectType = {};
    const formattedRangeDate = DateInputHelper.formatRangeDate(this.rangeDate);

    if (formattedRangeDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    if (this.search) {
      definedFilters.number = this.search;
    }

    if (formattedRangeDate.initialDate) {
      definedFilters[`start_${this.definedDateFilterOption}`] = formattedRangeDate.initialDate;
    }

    if (formattedRangeDate.finalDate) {
      definedFilters[`end_${this.definedDateFilterOption}`] = formattedRangeDate.finalDate;
    }

    if (this.labels) {
      definedFilters.consider_only = {
        to_expired: Number(this.labels.toExpired),
        expired: Number(this.labels.expired),
        paid_out: Number(this.labels.paidOut),
      };
    }
    this.filtersToConsider = definedFilters;

    this.getFinancialValues({ resetToFirst: true });
  }

  public showSecurityInterationDialog(
    selectedOption: 'increase_decrease' | 'change_due_date',
  ): void {
    if (selectedOption === 'increase_decrease') {
      this.isInclusionSecurityDialogOpen = true;

      return;
    }

    this.isChangeDueDateDialogOpen = true;
  }

  public unselectSecurities(): void {
    this.selectedSecurities = [];
    this.isSelectingAllSecurities = false;
  }

  public handleSearch(search: string): void {
    this.search = search;

    this.defineFiltersToFinancialTable();
  }

  public async handleSelectDateRange(): Promise<void> {
    this.defineFiltersToFinancialTable();
  }

  public handleSelectDateFilterOption(
    selectedDateFilter: VMenuOptions | null,
  ): void {
    let definedDateOption = '';

    if (selectedDateFilter !== null) {
      definedDateOption = selectedDateFilter.value;
    }

    this.definedDateFilterOption = definedDateOption;
    this.defineFiltersToFinancialTable();
  }

  public handleDialogClose(reloadScreen: boolean): void {
    if (reloadScreen) {
      this.getFinancialValues({ resetToFirst: true });
      this.unselectSecurities();
    }

    this.isInclusionSecurityDialogOpen = false;
    this.isChangeDueDateDialogOpen = false;
  }

  public handleTitleActionOptions(
    action:
      | 'increase_decrease'
      | 'change_due_date'
      | 'open_pre_negotiations_screen',
  ): void {
    if (action === 'open_pre_negotiations_screen') {
      this.openPreNegotiationScreenFromSelectedAccounts();

      return;
    }

    this.showSecurityInterationDialog(action);
  }

  public handleSelectAll(): void {
    this.isSelectingAllSecurities = !this.isSelectingAllSecurities;
    this.selectedSecurities = [];
  }

  public handleWithExcludedLabelsFromChart(labels: IChartLabels) {
    this.labels = labels;

    this.defineFiltersToFinancialTable();
  }
}
