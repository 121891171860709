







































































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import { DataOptions } from 'vuetify';

import StatusType from '@/domain/enums/StatementConciliationStatusType';
import ActionType from '@/domain/enums/StatementConciliationActionType';
import MovementType from '@/domain/enums/StatementConciliationMovementType';
import MovementOriginType from '@/domain/enums/StatementConciliationMovementOriginType';

import IVDataTableHeader from '@/types/IVDataTableHeader';
import IConciliationERPs from '@/domain/interfaces/IConciliationERPs';
import IConciliationDivergents from '@/domain/interfaces/IConciliationDivergents';

import StatementConciliation from '@/domain/models/StatementConciliation';
import StatementConciliationMovement from '@/domain/models/StatementConciliationMovement';
import StatementConciliationFilterData from '@/domain/interfaces/IStatementConciliationFilter';

import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';

import { formateDate } from '@/utils/date';

import StatementConciliationGenerateAdvanceDialog from './StatementConciliationGenerateAdvanceDialog.vue';
import StatementConciliationListStatus from './ListStatus.vue';
import StatementConciliationListAction from './ListAction.vue';
import StatementConciliationCreateMovement from './CreateMovement.vue';
import StatementConciliationSearchMovement from './SearchMovement.vue';
import StatementConciliationWriteOffMovement from './WriteOffMovement.vue';
import StatementConciliationDeconciliation from './Deconciliation.vue';

import { ActionData, CreateMovementData, DeconciliationData } from '../utils/interfaces';

import {
  formatCurrency,
  formatErrorForNotification,
  formatOrigin,
  formatType,
} from '../utils';

@Component({
  components: {
    StatementConciliationListStatus,
    StatementConciliationListAction,
    StatementConciliationCreateMovement,
    StatementConciliationSearchMovement,
    StatementConciliationWriteOffMovement,
    StatementConciliationDeconciliation,
    StatementConciliationGenerateAdvanceDialog,
  },
})
export default class StatementConciliationList extends Vue {
  @Prop({
    type: Object as () => StatementConciliationFilterData,
  }) readonly data!: StatementConciliationFilterData;

  @Prop({
    type: Array as () => Array<StatementConciliation>,
  }) readonly list!: Array<StatementConciliation>;

  @Prop(String) readonly sortByValue!: string;
  @Prop(Boolean) readonly sortDescValue!: boolean;

  @Watch('options')
  changedOptions(newValue: DataOptions, oldValue: DataOptions) {
    const { sortBy: sortByNew, sortDesc: sortDescNew } = newValue;
    const { sortBy: sortByOld, sortDesc: sortDescOld } = oldValue;
    if (!(sortByNew === sortByOld && sortDescNew === sortDescOld)) this.updateSort();
  }

  @Emit()
  updateSort(): DataOptions {
    return this.options;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  @Emit()
  selectedValue(): number {
    return this.selectedItemsValue;
  }

  readonly formatOrigin = formatOrigin;
  readonly formateDate = formateDate;
  readonly formatType = formatType;
  readonly formatCurrency = formatCurrency;

  readonly statementConciliationRepository
    : StatementConciliationRepository = new StatementConciliationRepository();

  writeOffPermission: number = Number(this.$session.get('user_access-write_off_receivables'));
  reconcilePermission: number = Number(this.$session.get('user_access-reconcile'));
  generateAdvancePermission: number = Number(this.$session.get('user_access-generate_advance'));

  bank: string = '';
  company: number = 0;
  selectedItemsValue: number = 0;
  openDeconciliation: boolean = false;
  openCreateMovement: boolean = false;
  openSearchMovement: boolean = false;
  openWriteOffMovement: boolean = false;
  isStatementConciliationGenerateAdvanceDialogOpen: boolean = false;
  createMovementData: CreateMovementData = {} as CreateMovementData;
  searchMovementData: StatementConciliation = {} as StatementConciliation;
  deconciliationData: DeconciliationData = {} as DeconciliationData
  writeOffMovementData: Array<StatementConciliation> = [];
  selectedItems: Array<StatementConciliation> = [];

  options: DataOptions = {
    ...{} as DataOptions,
    sortBy: [this.sortByValue],
    sortDesc: [this.sortDescValue],
  };

  headers: Array<IVDataTableHeader> = [
    {
      text: 'Status',
      value: 'status',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Origem',
      value: 'origin',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Data do Movimento',
      value: 'date',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Documento',
      value: 'document',
      align: 'center',
      sortable: true,
    },
    {
      text: 'CNPJ/CPF',
      value: 'customer_document',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Título',
      value: 'title_number',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Tipo de Lançamento',
      value: 'type',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Natureza do Lançamento',
      value: 'financial_nature',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Histórico',
      value: 'history',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Valor',
      value: 'value',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Ação',
      value: 'action',
      align: 'center',
      sortable: false,
    },
  ];

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  isDivergent(status: StatusType): boolean {
    return status === StatusType.DIVERGENCE;
  }

  checkOtherMovementsRadio(
    movements: Array<StatementConciliationMovement>, indexMovement: number,
  ) {
    movements.forEach((movement, index) => {
      if (movement.origin === MovementOriginType.ERP && index != indexMovement) {
        movement.selected = false;
      }
    });
  }

  totalValueOfSelectedItems(): number {
    this.selectedItemsValue = 0;

    if (this.selectedItems.length <= 0) return this.selectedValue();

    this.selectedItems.forEach((record) => {
      if (record.status !== StatusType.DIVERGENCE
       && record.status !== StatusType.NOT_CONCILIATED_ERP) return;

      if (record.status === StatusType.DIVERGENCE) {
        const movementsERP = record.movements
          .filter((movement) => movement.origin === MovementOriginType.ERP);

        if (movementsERP.length <= 0) return;

        movementsERP.forEach((movement) => {
          if (movement.type === MovementType.RECEIPT
            || movement.type === MovementType.BANKING_RECEIVABLE) {
            this.selectedItemsValue += parseFloat(movement.value);
          }
        });
      }

      if (record.status === StatusType.NOT_CONCILIATED_ERP) {
        record.movements.forEach((movement) => {
          if (movement.type === MovementType.RECEIPT
            || movement.type === MovementType.BANKING_RECEIVABLE) {
            this.selectedItemsValue += parseFloat(movement.value);
          }
        });
      }
    });

    return this.selectedValue();
  }

  getBankType(item: StatementConciliation): string {
    const bankMovements = item.movements
      .filter((movement) => movement.origin == MovementOriginType.BANK);
    const isAllCredit = bankMovements.every((movement) => movement.type == MovementType.CREDIT);
    return isAllCredit ? MovementType.CREDIT : '';
  }

  check(item: StatementConciliation): void {
    const index = this.selectedItems
      .findIndex((it: StatementConciliation) => it.uid === item.uid);

    if (index != -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }

    this.totalValueOfSelectedItems();
  }

  someWriteOffSelected(): boolean {
    return this.list
    .some((item: StatementConciliation) => (
      item.selected === true && item.status === StatusType.NOT_FOUND_ERP
    ));
  }

  someReconcileSelected(): boolean {
    return this.list
    .some((item: StatementConciliation) => (
      item.selected === true
      && item.status === StatusType.NOT_CONCILIATED_ERP
    ));
  }

  someDivergentSelected(): boolean {
    return this.list
    .some((item) => (
      item.status === StatusType.DIVERGENCE
      && item.movements.some((mov) => mov.selected)
    ));
  }

  actionNotification(): void {
    if (this.someReconcileSelected() && this.someWriteOffSelected()) {
      this.$notification.error('Registro bancário e de ERP selecionados.');
    }
    if (!this.someReconcileSelected()
      && !this.someWriteOffSelected()
      && !this.someDivergentSelected()) {
      this.$notification.warn('Nenhum registro selecionado.');
    }
  }

  action(data: ActionData): void {
    const { option, item } = data;

    switch (option) {
      case ActionType.CREATE: {
        this.prepareMovementDialogValues(ActionType.CREATE, item, this.data);
        break;
      }
      case ActionType.GENERATE_ADVANCE: {
        this.prepareMovementDialogValues(ActionType.GENERATE_ADVANCE, item, this.data);
        break;
      }
      case ActionType.SEARCH: {
        const { bank_id: bank, company_id: company } = this.data;

        if (company && bank) {
          this.bank = bank;
          this.company = company;
          this.searchMovementData = item;
          this.openSearchMovement = true;
        } else {
          this.$notification.warn(company ? 'Nenhum banco selecionado!' : 'Nenhuma empresa selecionada!');
          this.openSearchMovement = false;
        }

        break;
      }
      case ActionType.WRITE_OFF: {
        const { company_id: company, bank_id: bank } = this.data;

        const hasBankCreditMovement = item.movements.find(
          (movement) => (
            movement.origin == MovementOriginType.BANK && movement.type == MovementType.CREDIT
          ),
        );

        if (company && hasBankCreditMovement) {
          this.company = company;
          this.bank = bank;
          this.writeOffMovementData = Array.of(item);
          this.openWriteOffMovement = true;
        } else {
          this.$notification.warn(company ? 'Nenhum registro válido!' : 'Nenhuma empresa selecionada!');
          this.openWriteOffMovement = false;
        }
        break;
      }
      case ActionType.UNDO: {
        const { id, status, date: dispositionDate } = item;
        const { company_id: companyId, bank_id: bankId } = this.data;

        this.deconciliationData = {
          id, status, dispositionDate, companyId, bankId,
        };

        this.openDeconciliation = true;
        break;
      }
      default:
        console.error('Ação inválida: ', option);
        this.$notification.warn('Ops, ação inválida!');
    }
  }

  close(dialog: ActionType): void {
    switch (dialog) {
      case ActionType.CREATE:
        this.openCreateMovement = false;
        break;
      case ActionType.GENERATE_ADVANCE:
        this.isStatementConciliationGenerateAdvanceDialogOpen = false;
        break;
      case ActionType.SEARCH:
        this.openSearchMovement = false;
        break;
      case ActionType.WRITE_OFF:
        this.openWriteOffMovement = false;
        break;
      case ActionType.UNDO:
        this.openDeconciliation = false;
        break;
      default:
        this.openDeconciliation = false;
        this.openCreateMovement = false;
        this.isStatementConciliationGenerateAdvanceDialogOpen = false;
        this.openSearchMovement = false;
        this.openWriteOffMovement = false;
    }
  }

  public prepareMovementDialogValues(
    movementAction: ActionType.CREATE | ActionType.GENERATE_ADVANCE,
    selectedItem: StatementConciliation,
    filterData: StatementConciliationFilterData,
  ): void {
    const correlationBetweenMovementActionAndDialogTrigger = {
      create: 'openCreateMovement',
      'generate-advance': 'isStatementConciliationGenerateAdvanceDialogOpen',
    } as const;

    if (filterData.bank_id && filterData.company_id) {
      const bankMovement: StatementConciliationMovement = selectedItem.movements?.find(
        (movement) => movement.origin == MovementOriginType.BANK,
      ) || {} as StatementConciliationMovement;

      const {
        date,
        type,
        value,
        history,
        document,
      } = bankMovement;

      this.createMovementData = {
        id: selectedItem.id,
        bank: filterData.bank_id,
        date,
        type,
        value,
        history,
        company: filterData.company_id,
        document,
        company_id: null,
        financial_nature: '',
        cost_center: '',
        value_class: '',
        account_item: '',
        prefix: '',
        do_bank_transfer: false,
        bank_transfer_to: '',
        currency: '',
      };

      this[correlationBetweenMovementActionAndDialogTrigger[movementAction]] = true;
    } else {
      this.$notification.warn(filterData.company_id ? 'Nenhum banco selecionado!' : 'Nenhuma empresa selecionada!');
      this[correlationBetweenMovementActionAndDialogTrigger[movementAction]] = false;
    }
  }

  writeOffAllSelected() {
    const { company_id: company, bank_id: bankId } = this.data;

    const onlyWriteOff = this.selectedItems.every((item) => (
      item.status === StatusType.NOT_FOUND_ERP
    ));

    if (company && onlyWriteOff) {
      const comparisonDate = this.selectedItems[0].date;
      const sameDate = this.selectedItems.every((item) => item.date === comparisonDate);

      if (sameDate) {
        this.company = company;
        this.bank = bankId;
        this.writeOffMovementData = this.selectedItems.slice();
        this.openWriteOffMovement = true;
      } else {
        this.$notification.error('Não é possível baixar registro com datas diferentes.');
      }
    } else {
      this.$notification.error(company ? 'Não é possível baixar os registros selecionados!' : 'Nenhuma empresa selecionada!');
    }
  }

  reconcileAllSelected() {
    const onlyConciliation = this.selectedItems.every((item: StatementConciliation) => (
      item.status === StatusType.NOT_CONCILIATED_ERP
    ));

    if (onlyConciliation) {
      this.conciliation();
    } else {
      this.$notification.error('Não é possível conciliar os registros selecionados!');
    }
  }

  divergentAllSelected() {
    const divergents = this.list.filter((item) => item.status === 2);

    const allConciliations = divergents.map((divergent) => {
      const { id, movements } = divergent;

      const erpMovement = movements
        .find((mov) => mov.selected === true) || {} as StatementConciliationMovement;
      const erpMovementId = erpMovement.id;

      return {
        conciliation_id: +id,
        erp_movement_id: erpMovementId ? `${erpMovementId}` : '',
      };
    });

    const conciliations = allConciliations.filter((conciliation) => conciliation.erp_movement_id !== '');

    if (conciliations.length > 0) {
      const conciliationDivergents: IConciliationDivergents = { conciliations };

      this.conciliationDivergents(conciliationDivergents);
    } else {
      this.$notification.error('Nenhum registro divergente encontrado.');
    }
  }

  async conciliation(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const companyGroupId = this.groupId;

      const { company_id: companyId, bank_id: bankId } = this.data;

      const erpMovements = this.selectedItems.map((erp) => {
        const { id, date } = erp;
        return { id, disposition_date: date };
      });

      const conciliationData: IConciliationERPs = {
        erp_movements: erpMovements,
        bank_id: bankId,
      };

      const success = await this.statementConciliationRepository
        .conciliationERPs(companyGroupId, companyId, conciliationData);

      if (success) {
        this.$notification.success('Conciliação de ERPs concluída com sucesso.');
        this.selectedItems = [];
        this.reload();
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  async conciliationDivergents(conciliationDivergentsData: IConciliationDivergents)
  : Promise<void> {
    try {
      this.$dialog.startLoading();

      const companyGroupId = this.groupId;
      const { company_id: companyId } = this.data;

      const success = await this.statementConciliationRepository
        .conciliationDivergents(companyGroupId, companyId, conciliationDivergentsData);

      if (success) {
        this.$notification.success('Conciliação dos divergentes concluída com sucesso.');
        this.reload();
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
